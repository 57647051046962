import iterator from '../../assests/check.png';
import delay from '../../assests/check.png';
import email from '../../assests/check.png';
import advance from '../../assests/check.png';
import api from '../../assests/check.png';
import calendar from '../../assests/check.png';
import powerhand from '../../assests/powerhand.svg';

import LikeIcon from '../../assests/Like.svg';
import correctIcon from '../../assests/correct.svg';
import { Link } from 'react-router-dom';

function FeaturesYouNeed({scrollToPriceSection}) {
  const data = [
    {
      sr: 1,
      icon: calendar,
      title: 'Unparalleled Performance',
      text: 'Elevate your BSC applications with FastlyNode superior performance. Our cutting-edge infrastructure ensures lightning-fast transaction processing, reduced latency, and increased reliability, making your projects more responsive and efficient than ever before.',
    },
    {
      sr: 2,
      icon: api,
      title: 'Robust Security',
      text: 'Safeguard your blockchain operations with FastlyNode robust security measures. Our advanced protection mechanisms, including secure encryption and strict access controls, shield your sensitive data and transactions from potential threats, ensuring a secure and trustworthy environment.',
    },
    {
      sr: 1,
      icon: advance,
      title: 'Seamless Integration',
      text: 'FastlyNode private node services offer seamless integration with your existing BSC applications, streamlining your operations and enhancing overall efficiency. With our user-friendly platform, you can quickly and easily incorporate FastlyNode powerful features into your blockchain projects.',
    },
    {
      sr: 1,
      icon: email,
      title: 'Cost-effective Solution',
      text: 'Optimize your budget with FastlyNode cost-effective pricing plans. Our flexible options, combined with the unlimited requests and crypto payment acceptance, provide exceptional value for your investment, empowering you to focus on scaling and growing your BSC applications.',
    },
    {
      sr: 1,
      icon: delay,
      title: 'Expert Support',
      text: 'With FastlyNode, you gain access to our team of dedicated blockchain experts, ready to assist you with any questions or issues that may arise. Our top-notch support ensures you have a smooth and hassle-free experience, allowing you to focus on what matters most – your BSC projects.',
    },
    {
      sr: 1,
      icon: iterator,
      title: 'Future-proof Innovation',
      text: 'Stay ahead of the curve with FastlyNode commitment to continuous innovation. Our platform is designed to adapt and evolve with the ever-changing blockchain landscape, ensuring that you always have access to the latest tools, features, and technologies for your BSC applications.',
    },
  ];

  const data2 = [
    'Trusted by 5,000+ Users',
    'Payments in BNB',
    '100MD Requests Validated',
  ];
  return (
    <section className="md:pb-[100px] bg-white pb-[50px] pt-[70px]">
      <div className="container mx-auto max-w-[1100px]">
        <div>
          <div className="">
            <h3 className="text-[#030025] mb-[20px] text-left md:text-center text-[22px] md:text-[36px] font-extrabold leading-[33px] md:leading-[1.4em]">
              <img src={powerhand} className="w-[36px] inline " alt="" /> Unlock Your BSC Potential <u> with FastlyNode.</u>
            </h3>
            <p className="text-[#1D1B21]  mb-[20px]  md:px-[150px] text-[15px] md:text-[18px] text-left md:text-center leading-[1.7em]">
              Easy-to-use, Revolutionize Your Blockchain Experience with Our Essential Private Node Services
            </p>
          </div>
          <div className="pb-[30px]">
            <div className="flex flex-wrap flex-col sm:flex-row ">
              {data.map((el, i) => (
                <div
                  key={i}
                  className="w-full md:w-[50%] pt-[20px] md:pr-[50px] pb-[0px] md:pb-[30px]  md:pl-[20px] FeaturesYouNeed-row"
                >
                  <div className="flex">
                    <div className="w-[12%] mr-[18px] hidden md:block">
                      <img src={el.icon} alt="" />
                    </div>
                    <div className="w-full md:w-[88%]">
                      <h3 className="text-[#111111] text-[16px] md:text-[18px] font-extrabold leading-[0.8em] mb-[8px] md:mb-[14px]">
                        {el.title}
                      </h3>
                      <p className="text-[#242729ED] text-[16px] leading-[1.6em]">
                        {el.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-[5px] md:mt-[25px]">
          <div onClick={()=> scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
                <img src={LikeIcon} alt="" />
                <span>START NOW!</span>
              </div>
          </div>
          <div className="mt-[15px]">
            <div className="max-w-[670px] mx-auto ">
              <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-1">
                {data2.map((el, i) => (
                  <div key={i} className="flex gap-[6px] ">
                    <img src={correctIcon} className="w-[13px]" alt="" />{' '}
                    <span className="text-[#54595F] font-semibold text-[12px]">
                      {el}
                    </span>{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesYouNeed;
