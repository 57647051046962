import React from "react";

function ProtectedFooter() {
  return (
    <footer className="border-t text-center  border-[#dee2e6] bg-white py-[10px]">
      <p className="text-[15px] text-[#212529] font-medium">FastlyNode © 2023 All rights reserved.</p>
    </footer>
  );
}

export default ProtectedFooter;
