import "../../styles/top_header.css";
import headerLogo from "../../assests/header-logo.webp";
import headerLogo1 from "../../assests/header-logo.webp";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import MobileMenu from "./MobileMenu";
import { FaAngleDown } from "react-icons/fa";
import HoverProduct from './HoverProduct';
import HeaderMenuProfileOption from './HeaderMenuProfileOption';
import avatarIng from "../../assests/allApp/avatar.png"
import { useSelector } from "react-redux";

function Header() {
  const appProps = useSelector(state => state);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const {token,userInfo} = appProps

 

  const handleMenu = () => {
    setMainMenuOpen(!mainMenuOpen);
  };

  const handleMenuOps = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <header className="header bg-white relative">
      <div className="container mx-auto max-w-[1120px] ">
        <div className="flex flex-row items-center py-[10px]">
          <div className="w-[46%] block md:hidden">
            <Link to="/">
              <img src={headerLogo1} alt="" className="80%" />
            </Link>
          </div>
          <div className="w-[20%] hidden md:block">
            <Link to="/">
              <img src={headerLogo} alt="" className="w-[66%]" />
            </Link>
          </div>
          <div className="w-[80%] items-center gap-x-4 hidden md:flex justify-end">
            {/*<div>
              <input
                type="text"
                placeholder="Search by App"
                className="header-input"
              />
            </div>*/}

            <div className="relative pro-hove-btn ">
              <button
                className=" text-[#222] py-2  rounded"
              >
                Products <FaAngleDown className="inline" />
              </button>
             <div className="hover-pro-wrapper">
             <HoverProduct />
             </div>
            </div>
            {!userInfo && <Link to="/login" className="text-[#222222] text-[14px] mr-[10px]">
              Sign In
            </Link>}
            <div className="pl-[6px]">
              {userInfo ? <div className="relative">

                <div>
                <img
                  className="w-[35px] cursor-pointer rounded-full inline-block"
                  onClick={handleMenuOps}
                  src={avatarIng}
                  alt=""
                />
              </div>
              {menuOpen && <HeaderMenuProfileOption handleMenuOps={handleMenuOps}  />}

              </div> :<Link
                to="/signup"
                className="rounded-[9px] leading-[1em] text-white indivne-block bg-[#006DF9] py-[8px] px-[18px]"
              >
                {" "}
                Sign Up Free
              </Link>}

            </div>
          </div>
          <div className="w-[54%] flex md:hidden justify-end">
            <div className="inline-block text-[30px]" onClick={handleMenu}>
              {mainMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
            </div>
          </div>
        </div>
      </div>

      {mainMenuOpen && <MobileMenu openOpen={setMainMenuOpen} />}
    </header>
  );
}

export default Header;
