import React from "react";
import { useState } from "react";
import YouTube from "react-youtube";

import connectMultipleAppImg from "../../assests/fastly-bot-sniper-bot.jpg";
import correctIcon from "../../assests/correct.svg";
import LikeIcon from "../../assests/Like.svg";
import playIcon from "../../assests/play-button.webp";

function SniperConnectMultipleApplications({ scrollToPriceSection }) {
  const data3 = [
    "Trusted by 5,000+ Users",
    "Payments in BNB",
    "+10K successful launch",
  ];

  const [playVideo, setPlayVideo] = useState(false);

  const opts = {
    playerVars: {
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  return (
    <>
      <section>
        <div className="container max-w-[1140px] mx-auto ">
          <div className="mb-[20px] md:px-[60px]">
            <h2 className="text-[#030025] text-[25px] md:text-[48px] font-extrabold text-left md:text-center leading-[1.3em]">
            Fastly Bot <u>The Ultimate</u> Sniper Bot for Binance Smart Chain
            </h2>
          </div>
          <p className="text-[16px] md:text-[18px] text-[#1D1B21] leading-[1.5em] md:leading-[1.7em] font-medium text-left md:text-center">
          Our bot is the ultimate tool for traders who want to maximize their profits and stay ahead of the competition.
          </p>

          <div className="md:px-[180px] pt-[30px] pb-[30px] relative ">
            <div className="anam-play aspect-video">
              {playVideo ? (
                <YouTube videoId="Wi5xj4Ec5lQ" opts={opts} />
              ) : (
                <span
                  onClick={() => setPlayVideo(true)}
                  className="cursor-pointer"
                >
                  <img
                    src={connectMultipleAppImg}
                    alt=""
                    className="shadow-a-1 w-full rounded-[10px]"
                  />{" "}
                  <div className="absolute block w-full h-full top-0 left-0 ">
                    <div className="flex justify-center items-center w-full h-full">
                      <img
                        className="w-[70px] h-[70px] rounded-full shadowPlushAnimation"
                        src={playIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </span>
              )}
            </div>
          </div>
          {/* <div className="mt-[30px] max-w-[780px] mx-auto ">
          <div className="flex flex-col md:flex-row justify-between ">
            <div className="w-full md:w-[48%] md:p-5">
              {data1.map((el, i) => (
                <div key={i} className="flex gap-4 mb-[13px]">
                  <img src={correctIcon} className="w-[14px]" alt="" />{" "}
                  <span className="text-[#030025] font-semibold text-[16px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
            <div className="w-full md:w-[51%]">
              {data2.map((el, i) => (
                <div key={i} className="flex gap-4 mb-[13px]">
                  <img src={correctIcon} className="w-[14px]" alt="" />{" "}
                  <span className="text-[#030025] font-semibold text-[16px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div> */}
          <div className="text-center mt-[20px]">
            <div
              onClick={() => scrollToPriceSection()}
              class="btn-1 cursor-pointer w-full md:w-auto justify-center"
            >
              <img src={LikeIcon} alt="" />
              <span>START NOW!</span>
            </div>
          </div>
          <div className="mt-[15px]">
            <div className="max-w-[670px] mx-auto ">
              <div className="flex flex-row justify-center gap-x-5 gap-y-2 flex-wrap">
                {data3.map((el, i) => (
                  <div key={i} className="flex gap-[6px] ">
                    <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                    <span className="text-[#54595F] font-semibold text-[12px]">
                      {el}
                    </span>{" "}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SniperConnectMultipleApplications;
