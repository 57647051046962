import React from "react";

function ShareGoodWord() {
  return (
    <section className="bg-[#002683] py-[40px] md:py-[80px]">
      <div className="container mx-auto max-w-[900px]">
        <h3 className="text-white font-extrabold leading-[30px] md:leading-[1.5] text-[22px] md:text-[36px] text-left md:text-center">
          Share a good word about FastlyNode <br />
          with your audience 💸💰
        </h3>
        <p className="leading-[1.7em] text-[16px] md:text-[18px] font-medium text-center mt-[30px] text-white">
          "Become a part of the FastlyNode Affiliate Program and capitalize on our exceptional SaaS 
          service while helping businesses revolutionize their content creation process. 
          Start earning today with our attractive commission structure and easy withdrawal process. <br />
          <br />
          Once the leads you refer purchase any FastlyNode plan, you will
          automatically receive your commission in your affiliate account. The
          more you promote, the more you earn!"
        </p>
      </div>
    </section>
  );
}

export default ShareGoodWord;
