import iterator from '../../assests/check.png';
import delay from '../../assests/check.png';
import email from '../../assests/check.png';
import advance from '../../assests/check.png';
import api from '../../assests/check.png';
import calendar from '../../assests/check.png';
import powerhand from '../../assests/powerhand.svg';

import LikeIcon from '../../assests/Like.svg';
import correctIcon from '../../assests/correct.svg';

function SniperFeaturesYouNeed({scrollToPriceSection}) {
    const data = [
        {
          sr: 1,
          icon: calendar,
          title: 'Get in on the ground floor',
          text: 'FastlyBot is designed to help you get in on the ground floor of new token launches, so you can take advantage of every opportunity that comes your way.',
        },
        {
          sr: 1,
          icon: delay,
          title: 'Expert Support',
          text: 'Our top-notch support ensures you have a smooth and hassle-free experience, allowing you to focus on what matters most – your BSC projects.',
        },
        {
          sr: 2,
          icon: api,
          title: 'Set specific parameters',
          text: 'You can set specific parameters for buying and selling, such as price targets and stop losses, and the bot will automatically execute trades when those parameters are met.',
        },
        {
          sr: 1,
          icon: email,
          title: 'Constantly improving',
          text: 'Our team of experts is dedicated to helping you succeed in your trading endeavors, and we re constantly updating and improving our bot to make sure it remains the best tool on the market.',
        },
        {
          sr: 1,
          icon: advance,
          title: 'User-friendly interface',
          text: 'Our bot is incredibly easy to use, with a user-friendly interface that makes trading a breeze.',
        },
        {
          sr: 1,
          icon: iterator,
          title: 'Safe and secure',
          text: 'With our safe and secure platform, you can trade with confidence, knowing that your investments are protected.',
        },
      ];
    
      const data2 = [
        'Trusted by 5,000+ Users',
        'Payments in BNB',
        '+10K successful launch',
      ];
  return (
    <>

<section className="md:pb-[100px] bg-white pb-[50px] pt-[70px]">
      <div className="container mx-auto max-w-[1100px]">
        <div>
          <div className="">
            <h3 className="text-[#030025] mb-[20px] text-left md:text-center text-[22px] md:text-[36px] font-extrabold leading-[33px] md:leading-[1.4em]">
              <img src={powerhand} className="w-[36px] inline " alt="" /> Sign up today and start trading <u>  like a pro.</u>
            </h3>
            <p className="text-[#1D1B21]  mb-[20px]  md:px-[150px] text-[15px] md:text-[18px] text-left md:text-center leading-[1.7em]">
            Don't miss out on this incredible opportunity to maximize your profits and take your trading to the next level with our Binance Smart Chain 
            sniper bot service!
            </p>
          </div>
          <div className="pb-[30px]">
            <div className="flex flex-wrap flex-col sm:flex-row ">
              {data.map((el, i) => (
                <div
                  key={i}
                  className="w-full md:w-[50%] pt-[20px] md:pr-[50px] pb-[0px] md:pb-[30px]  md:pl-[20px] FeaturesYouNeed-row"
                >
                  <div className="flex">
                    <div className="w-[12%] mr-[18px] hidden md:block">
                      <img src={el.icon} alt="" />
                    </div>
                    <div className="w-full md:w-[88%]">
                      <h3 className="text-[#111111] text-[16px] md:text-[18px] font-extrabold leading-[0.8em] mb-[8px] md:mb-[14px]">
                        {el.title}
                      </h3>
                      <p className="text-[#242729ED] text-[16px] leading-[1.6em]">
                        {el.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-[5px] md:mt-[25px]">
          <div onClick={()=> scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
                <img src={LikeIcon} alt="" />
                <span>START NOW!</span>
              </div>
          </div>
          <div className="mt-[15px]">
            <div className="max-w-[670px] mx-auto ">
              <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-1">
                {data2.map((el, i) => (
                  <div key={i} className="flex gap-[6px] ">
                    <img src={correctIcon} className="w-[13px]" alt="" />{' '}
                    <span className="text-[#54595F] font-semibold text-[12px]">
                      {el}
                    </span>{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SniperFeaturesYouNeed