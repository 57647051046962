import Tippy from "@tippyjs/react";

import roketIcon from "../../assests/roket.svg";
import correctIcon from "../../assests/correct.svg";
import starIcon from "../../assests/5star.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function PriceCardNode({
  free,
  img,
  data,
  unlimited,
  plan,
  task,
  price,
  normallyPrice,
  id
}) {
  const appProps = useSelector(state => state);
  const {userInfo} = appProps
  return (
    <>
      <div
        className={`bg-white relative  px-[20px] rounded-[10px] pricing-card pt-[30px] pb-[25px] ${
          unlimited ? "border-[2px] border-[#1877F2] mt-[30px] sm:mt-0" : ""
        }`}
      >
        {unlimited && (
          <div className="bg-[#1877F2] left-0 rounded-tl-[10px] w-full top-[-45px] rounded-tr-[10px] py-[15px] px-[25px] text-[#fff] font-semibold uppercase absolute">
            <img src={starIcon} alt="" className="w-[84px] inline" /> most
            popular
          </div>
        )}

        <h2 className="text-[#54595F] text-center text-[18px] font-semibold tracking-[3px] uppeercase mb-[20px]">
          {plan}
        </h2>
        <div className="text-center">
          <img className="w-[50%] mx-auto rounded-[100px]" src={img} alt="" />
        </div>
        <div className="mt-[24px]">
          <p className="text-[#222222] text-center  mb-[12px] text-[14px] font-semibold">
            <img src={roketIcon} alt="" className="inline w-[13px]" /> Get the best prices
          </p>
        </div>
        <h2 className="text-[#222222] text-center mb-[15px] font-black text-[36px]">
          <span className="text-[18px] text-[#424242] font-medium line-through decoration-[red]">
            normally {normallyPrice}
          </span>{" "}
          <br />
          {price}
         {/* <span className="text-[#4d567f] font-semibold text-[14px]">
            /year
          </span> */}
        </h2>
        <div>
          {free ? (
            <Link
              to="/fastlybot"
              className="text-[16px] leading-[0.9em] text-center  block bg-[#1877F2] font-semibold py-[22px] px-[40px] text-white rounded-[10px] uppercase"
            >
              Sign up free
            </Link>
          ) : (
            <a
              href={"/offres/"+id}
              className="text-[16px] leading-[0.9em] text-center  block bg-[#1877F2] font-semibold py-[22px] px-[40px] text-white rounded-[10px] uppercase"
            >
              Subscribe Now
            </a>
          )}
        </div>
        <a
          href=""
          className="text-[#1877F2] mt-[10px] text-center block text-[16px] font-bold"
        >
          Offer Limited
        </a>
        <h2 className="text-[#FF4A00] mt-[35px] mb-[30px] text-[14px] leadimg-[1.5em] font-semibold text-center">
          Instant Access <br />{" "}
          <span className="text-[#222222]">FastlyBot - Sniper BOT For BSC</span>
        </h2>
        <hr className="bg-[#EFEFEF]" />

        <div className="space-y-[6px] mt-[28px]">
          {data.map((el, i) => (
            <Tippy
              content={<span className="text-[14px]">{el.hoverText}</span>}
            >
              <div
                className="text-[#222222] leading-[1.8em] price-li text-[14px] font-semibold tracking-[.1px]"
                key={i}
              >
                <img
                  src={correctIcon}
                  className="w-[12px] inline mr-[7px]"
                  alt=""
                />{" "}
                {el.text}
              </div>
            </Tippy>
          ))}
        </div>
      </div>
    </>
  );
}

export default PriceCardNode;
