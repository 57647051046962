import dollar from "../../assests/affiliate/dollar.webp";
import paymentMethod from "../../assests/affiliate/payment-method.webp";
import analytics from "../../assests/affiliate/analytics.webp";
import cookie from "../../assests/affiliate/cookie.webp";

function WhyJoin() {
  const data = [
    {
      img: dollar,
      title: "High Commission Rates",
      text: "FastlyNode offers one of the most lucrative affiliate programs in the blockchain industry. With a commission rate of 30%, you can earn significant amounts of money simply by referring users to FastlyNode private nodes.",
    },
    {
      img: paymentMethod,
      title: "Low Withdrawal Threshold",
      text: "Unlike many other affiliate programs, FastlyNode has a low minimum withdrawal amount of only $250. This means that you can quickly withdraw your commissions and start enjoying the fruits of your referral efforts.",
    },
    {
      img: analytics,
      title: "Real-Time Tracking and Reporting",
      text: "FastlyNode provides affiliates with a comprehensive dashboard where they can track their referrals and commissions in real-time. This means that you'll always be up-to-date on how much you've earned and how many users you've referred.",
    },
    {
      img: cookie,
      title: "Dedicated Affiliate Support",
      text: "Whether you have questions about our services, need help setting up your account, or want advice on how to promote FastlyNode to your network, our affiliate support team is always here to help.",
    },
  ];
  return (
    <section className="bg-white py-[60px] md:py-[100px] rounded-[15px]">
      <div className="container mx-auto max-w-[1200px]">
        <div>
          <h3 className="text-[#030025] mb-[10px] text-center md:text-center text-[22px]  md:text-[36px] font-extrabold leading-[1.5em]">
            Why join the FastlyNode Affiliate Program?
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-[20px]">
            {data.map((el, i) => (
              <div className="p-[25px] shadow-a-11 affiliate-card">
                <div className="bg-white">
                  <div className="flex items-center gap-[10px] mb-[20px]">
                    <div className="w-[17%]">
                      <img src={el.img} alt="" />
                    </div>
                    <div className="w-[80%]">
                      <h2 className="text-[#2B2A35] text-[18px] font-bold leading-[1.4em]">
                        {el.title}
                      </h2>
                    </div>
                  </div>
                  <hr />
                  <div className="pt-[13px]">
                    <p className="text-[#1D1B21] text-[16px] leading-[1.7em] tracking-[0.4px]">
                      {el.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyJoin;
