import React from "react";
import { NavLink } from "react-router-dom";
import { FaGlobe, FaUserCircle } from "react-icons/fa";

function ProtectedRouteSideBar() {
  const data = [
    {
      icon: <FaGlobe className="text-white mx-auto text-[20px]" />,
      text: "Subscribe",
      link: "/all-apps",
    },
    {
      icon: <FaUserCircle className="text-white mx-auto text-[20px]" />,
      text: "Your Nodes",
      link: "/your-nodes",
    },
    {
      icon: <FaUserCircle className="text-white mx-auto text-[20px]" />,
      text: "Fastly Bot",
      link: "/fastlybot",
    },
  ];
  return (
    <>
      <div className="w-[10%] bg-[#212529] lg:block">
        <div className=" h-100 sticky top-0">
          {data.map((el, i) => (
            <NavLink
              to={el.link}
              className={(props) => {
                return `flex center column ${
                  props.isActive ? "bg-[#007bff] " : ""
                } p-[15px] block`;
              }}
            >
              <div className="text-center">{el.icon}</div>{" "}
              <p className="text-white text-[12px] mt-[5px] text-center">
                {el.text}
              </p>
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
}

export default ProtectedRouteSideBar;
