import React from "react";
import img from "../../assests/office-photo.webp";
import LikeIcon from "../../assests/Like.svg";
import correctIcon from "../../assests/correct.svg";

function AmazingTeam({scrollToPriceSection}) {
  const data = [
    "Trusted by 5,000+ Users",
    "Payments in BNB",
    "100MD Requests Validated",
  ];
  return (
    <>
      <section className="relative pt-[30px] md:pt-[90px]">
        <div className="container mx-auto max-w-[1140px]">
          <div>
            <h3 className="text-[#030025] mb-[10px] md:text-center text-[22px] md:text-[36px] font-extrabold leading-[34px] md:leading-[1.5em]">
              We Are Proud of Our Amazing Team 😍
            </h3>
            <p className="text-[#1D1B21] lg:px-[200px] mb-[20px] md:mb-[50px] text-[15px] md:text-[18px] lg:text-center leading-[1.5em] md:leading-[1.7em]">
            We began modestly, with a mere two team members. Gradually, FastlyNode expanded into a group of over ten remarkable individuals.
            </p>
          </div>
          <div className="">
            <div>
              <img
                src={img}
                className="mx-auto shadow-a-2 border-[7px] border-white rounded-[6px]"
                alt=""
              />
            </div>
          </div>
        </div>{" "}
      </section>

      <section>
        <div className="bg-[#F6FCFF] mt-[-150px] pt-[170px] pb-[50px] md:pb-[90px]">
          <div className="container mx-auto max-w-[1140px] relative ">
            <div>
              <h3 className="text-[#222] mb-[10px] md:text-center text-[28px] md:text-[36px] font-extrabold leading-[38px] md:leading-[1.5em]">
              Launch your BNB Smart Chain endpoint in mere moments, rather than waiting for days.
              </h3>
              <p className="text-[#1D1B21] lg:px-[200px] mb-[20px] md:mb-[50px] text-[15px] md:text-[18px] lg:text-center leading-[1.5em] md:leading-[1.7em]">
              With adaptable APIs, potent tools, and valuable analytics at your fingertips through a simple control panel, FastlyNode is your all-encompassing solution for harnessing the power of the BNB Smart Chain!
              </p>
            </div>

            <div className="text-center mt-[25px]">
              <div onClick={()=>scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
                <img src={LikeIcon} alt="" />
                <span>START NOW!</span>
              </div>
            </div>
            <div className="mt-[15px]">
              <div className="max-w-[670px] mx-auto ">
                <div className="flex flex-row justify-center gap-x-5 gap-y-2 flex-wrap">
                  {data.map((el, i) => (
                    <div key={i} className="flex gap-[6px] ">
                      <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                      <span className="text-[#54595F] font-semibold text-[12px]">
                        {el}
                      </span>{" "}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AmazingTeam;
