import React from 'react'
import checkIcon from "../../assests/checked1.webp";
import chatSupport from "../../assests/Chat-Support.webp";
import playButton from "../../assests/play-button.webp";

function SnipperTrustedBy() {
    const data = [
        {
          icon: checkIcon,
          text: "User-friendly trading interface",
        },
        {
          icon: checkIcon,
          text: "Profit-maximizing trading platform",
        },
        {
          icon: checkIcon,
          text: "Customizable trading strategies",
        },
      ];
  return (
    <>
     <section className="mt-[70px]">
      <div className="container max-w-[840px] mx-auto">
        <div className="pb-[50px] ">
          <div className="flex flex-col gap-5 md:gap-0 md:flex-row mb-[80px] md:mb-0">
            {data.map((el, i) => (
              <div key={i} className="trustedby-card">
                <div className="flex flex-row items-center gap-[15px]">
                  <img className="w-[38px]" src={el.icon} alt="" />
                  <p className="text-[#030025] text-[16px] font-semibold leading-[1.4em]">
                    {el.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#053099] pt-[30px] md:pt-[110px] pb-[50px] mt-[-90px]">
        <div className="container mx-auto max-w-[800px]">
          <div>
            <p className="text-[14px]  md:text-[20px] md:mb-[20px] p-2 md:p-5 font-semibold leading-[1.5em] md:leading-[1.7em] text-[#fff] l-s">
            "I've tried several sniper bots in the past, but FastlyBot is by far the best. The secure and reliable platform, give me confidence in my trades and peace of mind." 
            </p>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SnipperTrustedBy
