import React from "react";
import img from "../../assests/about/brand1.png";

function SingleBrandMultipleServices() {
  return (
    <section className="bg-[#f9f9f9] pt-[50px] md:pt-[80px] pb-[40px] md:pb-[80px]">
      <div className="container max-w-[1250px] mx-auto">
        <div>
          <h2 className="text-[#17212b] text-center mb-[40px] text-[30px] md:text-[36px] tracking-[0px] font-black leading-[38px] md:leading-[1.6em]">
            Single Brand. Multiple Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[40px] place-items-center">
            <div className="space-y-[20px]">
              <p className="text-[#282828] leading-[1.8em] font-bold tracking-[0.3px]">
                Common Signups and Logins for all applications.
              </p>
              <p className="text-[#282828] leading-[1.8em] font-bold tracking-[0.3px]">
              Soon access to private nodes for all blockchains.
              </p>
              <p className="text-[#282828] leading-[1.8em] font-bold tracking-[0.3px]">
              Most effective sniper bot on the market.
              </p>
              <p className="text-[#282828] leading-[1.8em] font-bold tracking-[0.3px]">
              High performance private node.
              </p>
            </div>
            <div>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleBrandMultipleServices;
