import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

import HeaderAuthPage from "./../components/common/HeaderAuthPage";
import { baseApi } from "./../data/APIDATA";
import Header from "../components/common/Header";

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors }, reset 
  } = useForm();

  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountEmail, setAccountEmail] = useState("")

  

  const navigate = useNavigate();

  const forgetPass = async (data) => {
  
    setLoading(true);
    try {
      const res = await axios.post(baseApi + "/v1/forget-password", data);
      setShowOtp(res?.data?.success);
      setAccountEmail(res?.data?.email)
      reset()
      setLoading(false);
    } catch (e) {
      reset()
      setShowOtp(false);
      setLoading(false);
      toast(e?.response?.data?.error || "Account not found!");
    }
  };

  const updatePass = async (data) => {
    data.email=accountEmail
    setLoading(true);
    try {
      const res = await axios.post(baseApi + "/v1/reset-password", data);
      reset()
      setLoading(false);
      toast("Password updated. Please login.");
      navigate("/login");
    } catch (e) {
      reset()
      setShowOtp(false);
      setLoading(false);
      toast(e?.response?.data?.error || "Something wrong!");
    }
  };

  const emailPartten =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  const inputClass =
    "border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] shadow-a-8 mb-[20px]";
  const errTextClass = "text-[11px] mt-[-13px] mb-[15px] text-[#D16969]";

  return (
    <>
      {/* <HeaderAuthPage /> */}
      <Header />
      <section className="pb-[100px]">
        <div className="container mx-auto max-w-[1140px]">
          <div className="max-w-[400px] mx-auto">
            <h3 className="text-[#1f1f1f] pt-[50px] text-center text-[27px] mb-[20px] font-medium leading-[1.1]">
              {showOtp ? "Reset Password" : "Forgot Password "}
            </h3>
            {showOtp ? (
              <form onSubmit={handleSubmit(updatePass)}>
                <div>
                  <input
                    type="text"
                    placeholder="Otp"
                    className={inputClass}
                    {...register("otp", {
                      required: true,
                      minLength: 6,
                    })}
                  />
                  {errors.otp?.type === "required" && (
                    <p className={errTextClass}>Otp is required</p>
                  )}
                  {errors.otp?.type === "minLength" && (
                    <p className={errTextClass}>Otp must be more 6 digit</p>
                  )}
                </div>

                <div>
                  <input
                    type="password"
                    placeholder="Password"
                    className={inputClass}
                    {...register("password", {
                      required: true,
                      minLength: 8,
                    })}
                  />
                  {errors.password?.type === "required" && (
                    <p className={errTextClass}>Password is required</p>
                  )}
                  {errors.password?.type === "minLength" && (
                    <p className={errTextClass}>
                      Password must be more the 7 digit
                    </p>
                  )}
                </div>

                <div className="text-center py-[20px]">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-[#5e55e2] inline-block border-[#4338e0] border p-[8px] text-[15px] leading-[1.4] rounded-[3px] text-white"
                  >
                    {loading ? "Loading..." : "Update Password"}
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit(forgetPass)}>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    className={inputClass}
                    {...register("email", {
                      required: true,
                      pattern: emailPartten,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <p className={errTextClass}>Email is required</p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p className={errTextClass}>Invalid Email</p>
                  )}
                </div>

                <div className="text-center py-[20px]">
                  <button
                    disabled={loading}
                    type="submit"
                    className="bg-[#5e55e2] inline-block border-[#4338e0] border p-[8px] text-[15px] leading-[1.4] rounded-[3px] text-white"
                  >
                    {loading ? "Loading..." : "Reset Password"}
                  </button>
                </div>
              </form>
            )}
            <hr />
            <p className="text-[#73879c] text-[13px] text-center">
              Remember your password? <Link to="/"> Sign in </Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
