import React, { useState } from "react";

import finger from "../../assests/finger.svg";
import LikeIcon from "../../assests/Like.svg";

import correctIcon from "../../assests/correct.svg";
import automotionBg from "../../assests/automotion.jpg";
import automotionBg1 from "../../assests/automotion.jpg";
import automotionBg2 from "../../assests/automotion.jpg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AutomatingTasksSingleCard from './../connect/AutomatingTasksSingleCard';



function SniperAutomatingTasks({scrollToPriceSection}) {
    const list = [
        {
          banner: automotionBg,
          video: "qlrl_tF_Tls",
        },
      
    
      ];
    
      const data = [
        "Trusted by 5,000+ Users",
        "Payments in BNB",
        "+10K successful launch",
      ];
    
  return (
    <>
     <section className="bg-white py-[100px]">
      <div className="container mx-auto max-w-[1200px]">
        <h3 className="text-[#030025] mb-[20px] text-center text-[22px] mt-[-50px] md:mt-0 md:text-[36px] font-extrabold leading-[30px] md:leading-[1.5em]">
          {" "}
          <img src={finger} className="md:w-[36px] w-[22px] inline " alt="" />
          With our sniper bot, you can buy tokens at launch in the first position, <u>giving you the best chance of making a profit.</u>
        </h3>
        <p className="text-[#1D1B21] mb-[20px] text-[15px] md:text-[18px] text-center leading-[1.4em] md:leading-[1.7em]">
        Our platform is constantly fine-tuned and updated to stay ahead of industry demands, 
        providing you with a reliable and future-proof solution.
        </p>
        <div className="text-center mb-[35px]">
          <div className="py-[12px] px-[15px] inline-block bg-[#E7F2FF] rounded-[50px]">
            <p className="text-[#38373C] text-[15px] md:text-[16px] leading-[1.4em] md:leading-[1.7em]">
              <b>Note</b> At FastlyNode, we're committed to delivering peak performance across all aspects of our blockchain services.
            </p>
          </div>
        </div>
        <div className="automotion-slider py-5 md:p-0">
          <Swiper
            slidesPerView={1}
            slidesPerGroupSkip={2}
            spaceBetween={20}
            className="mySwiper"
            loop={true}           
            breakpoints={{
              100: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
          >
            {list.map((el, i) => (
              <SwiperSlide key={i}>
                <AutomatingTasksSingleCard data={el}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="text-center mt-[25px]">
          <div
            onClick={() => scrollToPriceSection()}
            class="btn-1 cursor-pointer"
          >
            <img src={LikeIcon} alt="" />
            <span>START NOW!</span>
          </div>
        </div>
        <div className="mt-[15px]">
          <div className="max-w-[670px] mx-auto ">
            <div className="flex flex-row p-8 sm:p-0 justify-between gap-1">
              {data.map((el, i) => (
                <div key={i} className="flex gap-[6px] ">
                  <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                  <span className="text-[#54595F] font-semibold text-[12px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SniperAutomatingTasks