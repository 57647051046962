import userIcon from "../../assests/user1.webp";
import starIcon from "../../assests/5star.svg";

function CustomarComentContent({ bg, comment }) {
  return (
    <section className={`${bg}`}>
      <div className="container mx-auto max-w-[1100px]">
        <div>
          <div className="bg-white rounded-[14px] shadow-a-2 mt-[-100px] md:mt-[-84px] relative z-[300]">
            <div className="pt-[30px] px-[10px] md:px-[40px] pb-[20px]">
              <div className="flex">
                <div className="w-[22%] md:w-[12%]">
                  <img src={comment.img} className="rounded-[100px]" alt="" />
                </div>
                <div className="w-[78%]">
                  <div className="pl-[10px] md:pl-[26px]">
                    <div className="mb-[20px]">
                      <img
                        src={starIcon}
                        className="w-[78%] md:w-[12%]"
                        alt=""
                      />
                    </div>
                    <p className="text-[#1D1B21] mb-[15px] text-[15px] md:text-[16px] leading-[1.5em] md:leading-[1.8em] font-medium">
                      "{comment.text}"
                    </p>
                    <h3 className="text-[#1D1B21] text-[14px] md:text-[16px] leading-[1.5em] font-bold">
                      {comment.name}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomarComentContent;
