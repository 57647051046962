import React from "react";

function PasswordAndSecurity() {
  const inputClass =
    "text-[#495057] block w-full shadow-a-8 py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da] rounded-[.25rem]";

  const labelClass = "text-[15px] font-medium mb-[5px] text-[#495057] block";

  return (
    <>
      <div className="px-[20px] mb-[40px] mt-[20px]  md:px-[20px]">
        <div className="  rounded-[5px] shadow-a-13 bg-white">
          <div className="px-[25px] py-[25px] border-b border-[#eef7ff]">
            <p className="text-[18px] text-[#212529] font-semibold">
              Password and Security
            </p>
          </div>
          <div className="px-[25px] flex gap-x-[30px] justify-between items-center border-b-[2px] border-[#eef7ff] border-t-[2px] py-[25px]">
            <p className="text-[#212529] text-[15px]">Two-Step Verification</p>
            <div>
              <button className="text-[15px] px-[.5rem] text-[#007bff] rounded-[0.2rem] border border-[#007bff] py-[.25rem]">
                Enable{" "}
              </button>
            </div>
          </div>

          <div className="px-[25px] py-[25px]">
            <div className="grid gap-x-[25px] gap-y-[20px] grid-cols-1 md:grid-cols-2">
              <div>
                <label className={labelClass} htmlFor="currentPass">
                  Current Password
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="Password"
                  id="currentPass"
                />
              </div>

              <div></div>

              <div>
                <label className={labelClass} htmlFor="new_pass">
                  New Password
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="Password"
                  id="new_pass"
                />
              </div>

              <div>
                <label className={labelClass} htmlFor="confirm_new_pass">
                  Confirm New Password
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="Password"
                  id="confirm_new_pass"
                />
              </div>

              <div>
                <button className="bg-[#0069d9] rounded-[0.25rem] border inline-block text-[15px] py-[0.375rem] px-[0.75rem] border-[#0062cc] text-white">
                  Save Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordAndSecurity;
