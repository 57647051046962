import React from "react";

import ContactForm from "../components/contact/ContactForm";
import ContactRightSection from "../components/contact/ContactRightSection";
import ContactBottom from "./../components/contact/ContactBottom";
import Footer from "./../components/common/Footer";
import TopHeader from "../components/common/TopHeader";
import Header from "../components/common/Header";

function ContactUs() {
  return (
    <>
    <TopHeader />
    <Header />
      <section className="bg-[#FCFDFF] pt-[60px] mt-[4px] pb-[80px]">
        <div className="container mx-auto max-w-[940px]">
          <div>
            <h2 className="text-[#282828] text-[28px] md:text-[46px] font-black text-center">
              Let us know how we can help you
            </h2>
            <p className="text-[#504F52] text-[18px] font-semibold text-center">
            Reach out to us for custom solutions, feature requests, feedback, and any questions you may have regarding FastlyNode pricing and infrastructure.
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] justify-between mt-[70px]">
            <ContactForm />
            <ContactRightSection />
          </div>
        </div>
      </section>
      <ContactBottom />
      <Footer />
    </>
  );
}

export default ContactUs;
