import React from "react";
import { Link } from "react-router-dom";

function AccountManagementFilter({ routeName }) {
  const data = [
    {
      text: "Profile",
      link: "/profile",
      value: null,
    },
    {
      text: "Password and Security",
      link: "/profile?t=password-tab",
      value: "password-tab",
    },
    {
      text: "Delete Account",
      link: "/profile?t=account-delete-tab",
      value: "account-delete-tab",
    },
  ];
  return (
    <>
      <div className="   bg-white md:pl-[20px]">
        <div className="flex flex-wrap gap-y-[14px] gap-x-[20px]">
          {data.map((el, i) => (
            <Link
              key={i}
              className={`block pb-[15px] text-[15px] px-[20px] font-semibold border-b  ${
                el.value == routeName
                  ? "text-[#007bff] border-[#007bff]"
                  : "text-[#6c757d] border-transparent"
              }`}
              to={el.link}
            >
              {el.text}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default AccountManagementFilter;
