import React, { useState } from "react";
import YouTube from "react-youtube";

function HelpYouMasterAutomationSingleCard({ data }) {
  const [playVideo, setPlayVideo] = useState(false);

  console.log("data", data);

  const opts = {
    playerVars: {
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  return (
    <div className={`aspect-video master-wrapper  ${playVideo? "anam-play":""}`}>
      {playVideo ? (
        <YouTube videoId={data.videoLink} opts={opts} />
      ) : (
        <div className=" cursor-pointer " onClick={() =>setPlayVideo(true) }> 
          <div className="relative rounded-[10px] overflow-hidden master-img border border-[#c1c1c1]">
            <img src={data.img} alt="" className="w-full " />
            <div className="play-ani"></div>
          </div>          
        </div>
      )}
      <h3 className="text-[14px] leading-[1.6em] font-semibold text-[#222] mt-[20px]">
            {data.text}
          </h3>
    </div>
  );
}

export default HelpYouMasterAutomationSingleCard;
