import React, { useState } from "react";
import { Link } from "react-router-dom";
import { menuProducList } from "./../../data/MenuData";

function MobileMenu({ openOpen }) {
  const [productOpen, setProductOpen] = useState(false);

  const closeMenu = () => {
    setProductOpen(false);
    openOpen(false);
  };

  return (
    <>
      <div className="absolute w-full block md:hidden left-0 top-[90%] py-[10px] bg-white z-[100]">
        <div className="px-[20px]">
          <div>
            <p
              onClick={() => setProductOpen(!productOpen)}
              className="px-[23px] py-[10px] text-[#000] text-[17px] block"
            >
              Products
            </p>
            {productOpen &&
              menuProducList.map((el, i) => (
                <Link
                  onClick={closeMenu}
                  className="block text-[#222] text-[15px] py-[10px] px-[33px]"
                  key={i}
                  to={el.link}
                >
                  {el.title}
                </Link>
              ))}
            <Link
              onClick={closeMenu}
              to="/signup"
              className="px-[23px] py-[10px] text-[#222] text-[17px] block"
            >
              SignUp
            </Link>
            <Link
              to="/login"
              className="px-[23px] py-[10px] text-[#222] text-[17px] block"
              onClick={closeMenu}
            >
              SignIn
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
