import React from "react";
import starIcon from "../../assests/5star.svg";

function StarRating() {
  const data = [
    "Never going back to Quick Node",
    "FastlyNode have exceeded expectations",
    "Affordable, efficient, and scalable",
  ];

  return (
    <section className="py-[1.5em] md:mb-[0.75em]">
      <div className="container max-w-[1040px] mx-auto">
        <div className=" justify-between hidden md:flex">
          {data.map((el, i) => (
            <div
              key={i}
              className="w-[32.5%] flex justify-center flex-col gap-[8px] items-center"
            >
              <div className="w-[30%]">
                <img src={starIcon} alt="" />
              </div>
              <p className="text-[#606077] text-[14px] leading-[1.5em]">
                "{el}"
              </p>
            </div>
          ))}
        </div>
        <div className=" justify-between flex md:hidden">
          <div className="w-full flex justify-start flex-col gap-[8px] items-start">
            <div className="w-[34%]">
              <img src={starIcon} alt="" />
            </div>
            <p className="text-[#606077] text-[14px] leading-[1.5em]">
              "Affordable, efficient, and scalable"
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StarRating;
