import React from "react";
import logo from "./../../assests/logo.webp";
import star from "../../assests/star.svg";
import hand from "../../assests/hand.svg";
import "./../../styles/top_header.css";
import { Link } from "react-router-dom";

function TopHeader({ scrollToPriceSection }) {
  return (
    <section className="top-header block md:sticky z-[9999999999999]">
      <div className="container mx-auto max-w-[1120px]">
        <div className="flex flex-wrap md:flex-nowrap flex-row ">
          <div className="w-[26%] pr-[15px] md:pr-0 md:w-[12%]">
            <img className="w-full sm:w-[80%]" src={logo} alt="" />
          </div>
          <div className="w-[72%] md:w-[53%] md:mr-[10px]">
            <div>
              <h2 className="text-[#FFEF00] font-bold text-[21px] md:text-[26px] mb-[15px] sm:mb-[30px] text-center">
                <span> 2 BNB</span> FOR ANNUAL ACCESS.
              </h2>

              <div className="flex items-center justify-center gap-x-1 mt-1">
                <img className="w-[12px]" src={star} alt="" />
                <span className="text-[#FFFFFFAB] font-medium leading-[1.5em] text-center text-[12px] poppins">
                  Limited Time Offer | Unlimited Requests | Pay in BNB{" "}
                </span>
                <img className="w-[12px]" src={star} alt="" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-[34%]">
            <div className="text-center">
              <h2 className="text-white font-medium leading-[1.5em] text-[14px] mt-[15px] md:mt-[0px] md:text-[16px] poppins mb-[20px]">
              Seize the moment and capitalize on this exclusive, time-sensitive offer that may not be accessible later on.
              </h2>
              <div>
              <Link
              to="/signup"
              className="bg-[#FFEF00] shak-btn text-[18px] rounded-[5px] poppins font-semibold py-[6px] px-[30px] uppercase"
            >
              ACCESS TO THIS OFFER
            </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopHeader;
