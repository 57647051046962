import React from "react";

import app1 from "../../assests/allApp/email-marketing.png";
import app2 from "../../assests/allApp/email-marketing.png";
import app3 from "../../assests/allApp/email-marketing.png";
import app4 from "../../assests/allApp/email-marketing.png";
import app5 from "../../assests/allApp/email-marketing.png";
import { Link } from "react-router-dom";

function HeaderMenuAllApp({handleAppOpen}) {
  const data = [
    {
      icon: app1,
      link: "/all-apps",
      text: (
        <>
          Email <br /> Marketing
        </>
      ),
    },
    {
      icon: app2,
      link: "/all-apps",
      text: (
        <>
          Email <br /> Marketing
        </>
      ),
    },
    {
      icon: app3,
      link: "/",
      text: (
        <>
          Email <br /> Marketing
        </>
      ),
    },
    {
      icon: app4,
      link: "/",
      text: (
        <>
          Email <br /> Marketing
        </>
      ),
    },
    {
      icon: app5,
      link: "/",
      text: (
        <>
          Email <br /> Marketing
        </>
      ),
    },
  ];

  return (
    <div className="absolute top-[100%] w-[275px] right-0 p-[1.5rem] shadow-a-13 mt-[15px] bg-white rounded-[0.25rem]">
      <div className="grid gap-x-[20px] gap-y-[20px] grid-cols-2">
        {data.map((el, i) => (
          <Link onClick={handleAppOpen} className="py-[5px] px-[20px] block hover:bg-[#eef7ff] duration-200" to={el.link} key={i}>
            <img src={el.icon} className="mx-auto" alt="" />
            <p className="text-center">{el.text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HeaderMenuAllApp;
