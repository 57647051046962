import icon1 from "../assests/PEM.svg"


export  const menuProducList = [
    {
        title:"Private Node",
        link:"/",
        text:"Subscribe to a private node that guarantees you: speed, security, and anonymity"
    },
    {
        title:"Sniper Bot",
        link:"/sniper-bot",
        text:"Subscribe to a powerful sniper bot to be among the first buyers at a new token launch"
    },

  
]