import React from "react";
import { useSelector } from "react-redux";
import { countryData, timeZoneData } from './../../data/ProfileSettingData';

function ProfileSettings() {
  const appProps = useSelector(state => state);
  const {userInfo} = appProps

  const inputClass =
    "text-[#495057] block w-full shadow-a-8 py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da] rounded-[.25rem]";

  const labelClass = "text-[15px] font-medium mb-[5px] text-[#495057] block";
  
  return (
    <>
      <div className="px-[20px] mb-[40px] mt-[20px]  md:px-[20px]">
        <div className="  rounded-[5px] shadow-a-13 bg-white">
          <div className="px-[25px] py-[25px] border-b border-[#eef7ff]">
            <p className="text-[18px] text-[#212529] font-semibold">
              Profile Settings
            </p>
          </div>
          <div className="px-[25px] py-[25px]">
            <div className="grid gap-x-[25px] gap-y-[20px] grid-cols-1 md:grid-cols-2">
              <div>
                <label className={labelClass} htmlFor="fName">
                  User Name
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="User Name"
                  id="userName"
                  defaultValue={userInfo?.info?.userName || ""}
                />
              </div>

              <div>
                <label className={labelClass} htmlFor="url">
                  Website URL
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="Website URL"
                  id="url"
                  defaultValue={userInfo?.info?.webUrl || ""}
                />
              </div>

              <div>
                <label className={labelClass} htmlFor="address">
                  Address
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="Address"
                  id="address"
                  defaultValue={userInfo?.info?.address || ""}
                />
              </div>

              <div>
                <label className={labelClass} htmlFor="city">
                  City
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="City"
                  id="city"
                  defaultValue={userInfo?.info?.city || ""}
                />
              </div>

              <div>
                <label className={labelClass}>Country</label>
                <select className={inputClass}>
                  {countryData.map((el, i) => (
                    <option key={i} value={el} selected={el == userInfo?.info?.country}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className={labelClass} htmlFor="state">
                  State
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="State"
                  id="state"
                  defaultValue={userInfo?.info?.state || ""}
                />
              </div>

              <div>
                <label className={labelClass}>Time Zone</label>
                <select className={inputClass}>
                  {timeZoneData.map((el, i) => (
                    <option key={i} value={el} selected={el == userInfo?.info?.timeZone}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <button className="bg-[#0069d9] rounded-[0.25rem] border inline-block text-[15px] py-[0.375rem] px-[0.75rem] border-[#0062cc] text-white">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSettings;
