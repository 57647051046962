import React,{useState,useEffect} from 'react';
import { ethers } from "ethers";
import { checkPriceRules, getMe, offres, userCreate, userLogin,subscriptionBotUpdate, transactionCreate,transactionBotCreate } from '../Api/api';
import { useDispatch,useSelector } from "react-redux";
import { getClient, validateEmail } from '../Utils/utils';
import Header from '../components/common/Header';
import { toast } from "react-toastify";
import { useLocation, useNavigate, useNavigation } from 'react-router';
import {useParams} from "react-router-dom";
import Footer from '../components/common/Footer';
import Pricing from '../components/connect/Pricing';
import { useForm } from "react-hook-form";
import { errTextClass } from '../data/regexData';
import { Link } from 'react-router-dom';
import TrustedBy from "./../components/connect/TrustedBy";
import img from "../assests/signup/signup-img.png";
import { CHAIN_ID, recipientAddr } from '../Config/Constants';
import AutomatingAnything from '../components/connect/AutomatingAnything';
import AutomationsWithNoCode from '../components/connect/AutomationsWithNoCode';
const inputClass =
"border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] shadow-a-8 mb-[20px]";

const list = [
    {
      text: "Private Node Binance Smart Chain",
    },
  ];
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Offre = (props) => {
    const appProps = useSelector(state => state);
    const {userInfo} = appProps
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
    let query = useQuery()
    const dispatch = useDispatch()    
    const {offreId} = useParams();
    const [offreData,setOffreData] = useState({})
    const [isLoading,setLoading] = useState(false)
    const [isBuying,setBuying] = useState(false)
    
    const [appliedDiscount,setAppliedDiscount]= useState({
        code:"",
        amount:0,
        value:0,
        valueType:"PERCENTAGE"
    })
    const [isApplied,setIsApplied]=useState(false)
    const [code,setCode]=useState("")
    const [errorMessage,setErrorMessage]=useState("")
    const [isApplying,setApplying]=useState(false)
    const [transactionHash,setTransactionHash] = useState("")
    const getOffres = async(id)=>{
        const client = await getClient()
        let variables= {
            id
        }
        const response = await offres(client,variables)
        console.log("response",response);
        if(response.success){
            setOffreData(response.data[0].node)
        }
    }
    const applyCouponCode=async(discountCode="")=>{
        setErrorMessage("")
        setApplying(true)
        try {
            let discount = ""
            if(discountCode){
                discount = discountCode
            } else {
                discount=code
            }
            const token = localStorage.getItem("token")
            const newClient = await getClient(token)
            const response = await checkPriceRules(newClient,{code:discount})
            console.log("applyCouponCode",response);
            
            if(response.success) {
                console.log("offreData.price",offreData);
            console.log("typeof.price",typeof offreData.price);
            console.log("response.data.value.value",response.data.value.value);
                setApplying(false)
                
                let amount = 0
                if(offreData){
                    
                    if(response.data.value.type==="PERCENTAGE"){
                        amount = parseFloat(offreData.price)*response.data.value.value/100
                    } else if(response.data.value.type==="FIXED"){
                        amount = parseFloat(offreData.price)-response.data.value.value
                    } 
                } else {
                    setErrorMessage("please check your internet connexion and refresh the page")
                }
                console.log("amount",amount);
                if(amount>0) {
                    setAppliedDiscount({
                        code:response.data.code,
                        amount,
                        value:response.data.value,
                        valueType:response.data.type
                    })
                    setIsApplied(true)
                }
                
            } else {
                setApplying(false)
                setErrorMessage(response.error.message)
            }
        } catch (error) {
            console.log({error});
            setErrorMessage("something went wrong")
            setApplying(false)
        }
    }
    const calculateTotal=()=>{
        if(offreData){
            if(isApplied){
                return (offreData.price - appliedDiscount.amount).toFixed(2)
            } else {
                return offreData.price
            }
        } else {
            return 0
        }
    }
    async function setDiscountCode(){
        const discountCode = query.get("discount_code")
        if (discountCode) {
            setCode(discountCode)
            applyCouponCode(discountCode)
        }
    }
    async function connect() {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((res)=>console.log(res))
          .catch((err) => {
            if (err.code === 4001) {
              setErrorMessage('Please connect to MetaMask.');
            } else {
              console.error(err);
            }
          });
    }
    const onSubmit = async (data) => {
    };
    
    useEffect(()=>{
        if(offreId) {
            getOffres(offreId)
        }
        if(!window.ethereum){
            setErrorMessage("your browser doesn't support web3, please install a secure wallet like metamask")
        } 
    },[])
    useEffect(()=>{
        setDiscountCode()
    },[offreData])
    const buyNode=async()=>{
        setErrorMessage("")
        if(!userInfo){
            toast("You need to login or create a free account in order to make a purchase")
            navigate(`/login?redirect=/offres/${offreData?.id}`)
            return
        }
        if(offreData.offre==="BOT"){
            await buyBotPlan() 
            return
        }
        setBuying(true)
        
        try {
            await connect()
            
            if (!window.ethereum){
                setErrorMessage("your browser doesn't support web3, please install a secure wallet like metamask")
                return
            }
            console.log("ethereum.isConnected()",window.ethereum.isConnected());
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const senderAddress = await signer.getAddress()
            ethers.utils.getAddress(recipientAddr);
            try {
                const network = await provider.getNetwork();
                const total = calculateTotal()
                if(network.chainId===CHAIN_ID){
                    const tx = await signer.sendTransaction({
                        to: recipientAddr,
                        value: ethers.utils.parseEther(total.toString())
                    });
                    const receipt = await tx.wait();
                    if(receipt.status===1){
                        setTransactionHash(receipt.transactionHash)
                        const token = localStorage.getItem("token")
                        const client = await getClient(token)
                        const input = {
                            node:"BSC",
                            sub_type:offreData.period,
                            sender:senderAddress,
                            receiver:recipientAddr,
                            transactionHash:receipt.transactionHash,
                            offreId:offreId,
                            code:appliedDiscount.code,
                            codeValue:appliedDiscount.amount
                        }
                        const response = await transactionCreate(client,{input})
                        dispatch({ type: "USER_INFORMATION", value: response.data })
                        navigate("/your-nodes")
                        
                    } else {
                        setErrorMessage(`transaction failed , ${receipt.transactionHash}`)
                    }
                } else {
                    setErrorMessage("Please make sure you're in the bsc mainnet, and try again")
                }
                
            } catch (error) {
                if(error.data){
                    if(error.data.message){
                        setErrorMessage(error.data.message);
                    } else {
                        setErrorMessage(error.message);
                    }
                } else {
                    setErrorMessage(error.message);
                }
            }
        } catch (err) {
            console.log(err)
            if(err.data){
                if(err.data.message){
                    setErrorMessage(err.data.message);
                } else {
                    setErrorMessage(err.message);
                }
            } else {
                setErrorMessage(err.message);
            }
        }
        setBuying(false)
    }
    const buyBotPlan=async()=>{
        setErrorMessage("")
        if(!userInfo){
            setErrorMessage("You need to login or create a free account")
            return
        }
        setBuying(true)
        try {
            await connect()
            if (!window.ethereum)
                setErrorMessage("your browser doesn't support web3, please install a secure wallet like metamask")           
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const senderAddress = await signer.getAddress()
            ethers.utils.getAddress(recipientAddr);
            const total = calculateTotal()
            try {
                const network = await provider.getNetwork();
                if(network.chainId===CHAIN_ID){
                    const tx = await signer.sendTransaction({
                        to: recipientAddr,
                        value: ethers.utils.parseEther(total.toString())
                    });
                    const receipt = await tx.wait();
                    if(receipt.status===1){
                        setTransactionHash(receipt.transactionHash)
                        const token = localStorage.getItem("token")
                        const client = await getClient(token)
                        let input = {
                            sub_type:offreData.period,
                            sender:senderAddress,
                            receiver:recipientAddr,
                            transactionHash:receipt.transactionHash,
                            offreId:offreId,
                            code:appliedDiscount.code,
                            codeValue:appliedDiscount.amount
                        }
                        if(userInfo && userInfo.user_bot_subscription.length>0) {
                          input.id = userInfo.user_bot_subscription[0].node.id
                          const response = await subscriptionBotUpdate(client,{input})
                          if(response.success) {
                            dispatch({ type: "USER_INFORMATION", value: response.data })
                            alert("Updated successfully")
                            navigate("/fastlybot")
                          } else {
                            alert(response.error.message)
                          }
                        } else {
                          const response = await transactionBotCreate(client,{input})
                          if(response.success) {
                            dispatch({ type: "USER_INFORMATION", value: response.data })
                            navigate("/fastlybot")
                          } else {
                            alert(response.error.message)
                          }
                        }   
                    } else {
                        setErrorMessage(`transaction failed , ${receipt.transactionHash}`)
                    }
                } else {
                    setErrorMessage("Please make sure you're in the bsc mainnet, and try again")
                }
                
            } catch (error) {
                if(error.data){
                    if(error.data.message){
                        setErrorMessage(error.data.message);
                    } else {
                        setErrorMessage(error.message);
                    }
                } else {
                    setErrorMessage(error.message);
                }
            }
            
        } catch (err) {
            if(err.data){
                if(err.data.message){
                    setErrorMessage(err.data.message);
                } else {
                    setErrorMessage(err.message);
                }
            } else {
                setErrorMessage(err.message);
            }
        }
        setBuying(false)
    }
    return (
        <>
            <Header />
            <section className="pb-[100px]">
                <div className="container mx-auto max-w-[1140px]">
                <div className="grid pt-[90px] md:grid-cols-2">
                    <AutomationsWithNoCode />

                    <div>
                    <div style={{height: 100+"%"}} className="bg-white px-[30px] md:px-[50px] rounded-[8px] shadow-a-9 pb-[40px] ml-auto sm:max-w-[460px] mx-auto pt-[35px]">
                        <h2 className="text-[#17212b] mb-[20px] text-[25px] text-center font-bold">
                        {offreData?.pack}
                        </h2>
                        <p className={errTextClass}>
                            {errorMessage}
                        </p> 
                        <div>
                        <div>
                            <div className="text-center mb-[20px]">
                                <select className="text-[#1E1E1E] text-[15px] inline-block mx-auto cursor-pointer outline-none font-semibold bg-[#C4C4C4] px-[8px] py-[3px] rounded-[9px]">
                                    {list.map((el, i) => (
                                    <option
                                        className="text-[#1E1E1E] font-semibold"
                                        key={i}
                                        value={el.text}
                                    >
                                        {el.text}
                                    </option>
                                    ))}
                                </select>
                                </div>
                            <div className='pricing-price text-center mb-[20px]'>
                                <h3 className='h1 font-bold'>
                                {offreData?.price} BNB <span>/{offreData?.period==="TWOWEEK"?"2 WEEK":"1 "+offreData?.period}</span>
                                </h3>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <input
                                    type="text"
                                    placeholder="Discount..."
                                    className={inputClass}
                                    onChange={(e)=>setCode(e.target.value)}
                                    value={code}
                                    />
                                </div>
                                <div>
                                {isApplied?
                                <button
                                className="text-center mb-[20px] w-full bg-[#fc4b6c] h-[45px] p-[8px] border text-white border-[#fc4b6c] leading-[1.5] rounded-[0.26rem]"
                                onClick={()=>setIsApplied(false)}
                                >
                                X
                                </button>:
                                <button
                                className="text-center mb-[20px] w-full bg-[#fc4b6c] h-[45px] p-[8px] border text-white border-[#fc4b6c] leading-[1.5] rounded-[0.26rem]"
                                disabled={isApplying}
                                onClick={()=>applyCouponCode()}
                                >
                                {isApplying ? "Applying..." : `Apply`}
                                </button>}
                                </div>
                            </div>
                            <button
                            className="flex center text-center mb-[20px] w-full bg-[#fc4b6c] h-[55px] p-[8px] border text-white border-[#fc4b6c] leading-[1.5] rounded-[0.26rem]"
                            disabled={isBuying}
                            onClick={()=>buyNode()}
                            >
                                <img
                                    src='../assets/img/metamask.svg'
                                    width='30'
                                    className="mr-[10px]"
                                    alt='metamask icon'
                                />
                            {isBuying ? "Buying..." : `Buy ${calculateTotal()} BNB`}
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                
            </section>
            <Footer />
        </>
    );
};

export default Offre;