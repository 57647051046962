import React from "react";
import img from "../../assests/about/OriginOfFastlyNode.jpg";

function OriginOfPabbly() {
  return (
    <section className="bg-[#f9f9f9] pt-[50px] md:pt-[80px] pb-[70px]">
      <div className="container mx-auto max-w-[1250px]">
        <div>
          <h2 className="text-[#17212b] text-center mb-[30px] md:mb-[60px] text-[30px] md:text-[36px] tracking-[0px] font-black leading-[1.6em]">
            Origin of FastlyNode
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-[40px]">
            <div>
              <p className="text-[16px] text-justify leading-[1.5em] md:leading-[1.6em] text-[#29262bed]">
              In a recent interview, FastlyNode's co-founders,<b>Ahmed and Nilton</b>, shared the inspiring story behind their groundbreaking private eco node system.
                <br />
                <br /> As experienced blockchain developers, they deeply understood the challenges that others in the community faced when communicating with blockchains 
                through public networks. Fueled by their passion for innovation, Ahmed and Nilton decided to combine their expertise to create a solution that would not 
                only facilitate, but also significantly improve, the way developers interacted with blockchains.<br />
                <br />
                Working tirelessly on their project, the duo focused on delivering a platform that emphasized privacy, speed, and scalability. They invested countless hours 
                into research and development, refining their concept until they finally launched FastlyNode. The platform has since gained recognition as a game-changer in 
                the blockchain industry, providing developers with the tools and resources needed to fully harness the potential of their blockchain projects.{" "}
                <br />
                <br />
                Reflecting on their journey, Ahmed and Nilton expressed their gratitude for the support and enthusiasm of the blockchain community. <br />
                <br />
                They remain dedicated to 
                continuous improvement and innovation, ensuring that FastlyNode stays at the forefront of cutting-edge blockchain technology.
              </p>
            </div>

            <div>
              <img className="w-full" src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OriginOfPabbly;
