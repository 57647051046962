import collectionImg from "../../assests/about/collage.jpg";

function RightStepsAtRightTime() {
  return (
    <section className="bg-[white] py-[50px] md:py-[80px]">
      <div className="container mx-auto max-w-[1250px]">
        <div>
          <h2 className="text-[#17212b] text-center mb-[60px] text-[30px] md:text-[36px] tracking-[0px] font-black leading-[1.6em]">
            Taking Right Steps At Right Time
          </h2>
          <div className="flex flex-col md:flex-row gap-[40px]">
            <div className="w-full md:w-[48%]">
              <div className="mb-[40px]">
              <iframe 
              width="560" height="315" 
              src="https://www.youtube-nocookie.com/embed/1I8hjLqhwRk" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen>
              </iframe>
              </div>
              <div>
                <p className="text-[16px] text-justify leading-[1.6em] text-[#29262bed]">
                FastlyNode's co-founders, Ahmed and Nilton, shed light on their ambitious 
                short-term goal to expand the platform's private node services to encompass all blockchains. <br />
                  <br />
                  Recognizing the growing demand for secure, high-performance, and reliable private node solutions, 
                  they are determined to make FastlyNode the go-to platform for developers working with various blockchain networks.
                </p>
              </div>
            </div>

            <div className="w-full md:w-[52%]">
              <div>
                <p className="text-[16px] text-justify leading-[1.6em] text-[#29262bed]">
                As experienced blockchain developers themselves, understand the immense potential of private nodes in facilitating seamless 
                interactions with multiple blockchains. By offering private node services for all blockchains, FastlyNode aims to provide a 
                unified and user-friendly platform that caters to the diverse needs of the blockchain community.
                  <br />
                  <br />
                  Along with their dedicated team, have been working relentlessly to research, develop, and integrate private nodes for various 
                  blockchain networks. They are committed to ensuring that FastlyNode maintains its high standards of quality, security, and 
                  performance while expanding its services. <br />
                  <br />
                  In their quest to make FastlyNode the ultimate private node solution for all blockchains, are driven by a steadfast belief 
                  in the transformative power of blockchain technology. <br />
                  <br />
                  They are excited to continue empowering developers worldwide, helping them unlock new possibilities and 
                  achieve greater success in their blockchain projects.{" "}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RightStepsAtRightTime;
