import starIcon from "../../assests/5star.svg";

function AffiliateStar() {
  const data = [
    "Keep promoting and you'll love the results.",
    "No limits on earnings!",
    "Awesome resources for promotion.",
  ];
  return (
    // <section className="pt-[60px] mb-[40px]">
    //   <div className="container max-w-[1040px] mx-auto">
    //     <div className="flex justify-between">
    //       {data.map((el, i) => (
    //         <div
    //           key={i}
    //           className="w-[32.5%] flex justify-center flex-col gap-[8px] items-center"
    //         >
    //           <div className="w-[30%]">
    //             <img src={starIcon} alt="" />
    //           </div>
    //           <p className="text-[#606077] text-[14px] leading-[1.5em]">
    //             {" "}
    //             "{el}"
    //           </p>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </section>

<section className="py-[1.5em] md:mb-[0.75em]">
<div className="container max-w-[1040px] mx-auto">
  <div className=" justify-between hidden md:flex">
    {data.map((el, i) => (
      <div
        key={i}
        className="w-[32.5%] flex justify-center flex-col gap-[8px] items-center"
      >
        <div className="w-[30%]">
          <img src={starIcon} alt="" />
        </div>
        <p className="text-[#606077] text-[14px] leading-[1.5em]">
          "{el}"
        </p>
      </div>
    ))}
  </div>
  <div className=" justify-between flex md:hidden">
    <div className="w-full flex justify-start flex-col gap-[8px] items-start">
      <div className="w-[34%]">
        <img src={starIcon} alt="" />
      </div>
      <p className="text-[#606077] text-[14px] leading-[1.5em]">
        "Affordable, efficient, and scalable"
      </p>
    </div>
  </div>
</div>
</section>
  );
}

export default AffiliateStar;
