import React from "react";

function AboutBanner() {
  return (
    <section className="py-[70px] bg-[#0D0C18] relative">
      <div className="about-hero-overlay"></div>
      <div className="container relative z-20 mx-auto">
        <div>
          <div className="max-w-[950px] mx-auto">
            <h1 className="text-[30px] md:text-[46px] font-black mb-[20px] leading-[1em] text-center text-white">
              A Global Hub For Privates Nodes!
            </h1>
            <p className="text-[#fff] text-center text-[19px] tracking-[0.3px] leading-[1.5em] md:leading-[1.7em]">
            Our vision is to become the leading provider of private node services for the blockchain ecosystem, driving innovation and ensuring our clients have access to cutting-edge tools and technologies.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutBanner;
