
export const contentPageQuestion1 = [
    {
      question: "What is a Sniper Bot?",
      ans: (
        <>
        A Sniper Bot is an automated trading tool designed to purchase new tokens on the Binance Smart Chain (BSC) 
        immediately after liquidity is added, securing early access and favorable prices.
        </>
      ),
    },
    {
      question: "How does the Sniper Bot improve my trading experience?",
      ans: (
        <>
          The Sniper Bot provides a competitive edge by automating token purchases, reducing manual errors, 
          and saving time, allowing you to focus on other aspects of your investment strategy.
        </>
      ),
    },
    {
      question: "Is the Sniper Bot easy to set up?",
      ans: (
        <>
       Yes, our Sniper Bot is user-friendly and comes with a comprehensive guide to help you get started with ease.
        </>
      ),
    },
    {
      question: "Can I customize the Sniper Bot to suit my trading preferences?",
      ans: (
        <>
         Absolutely! Our Sniper Bot offers various settings and filters, enabling you to tailor its functionality 
         to your specific trading goals and risk tolerance.
        </>
      ),
    },
    ];
  export const contentPageQuestion2 = [
      {
        question: "Is the Sniper Bot safe and secure?",
        ans: (
          <>
           We prioritize the security of our users. Our Sniper Bot employs advanced encryption and security 
           measures to protect your information and assets.
          </>
        ),
      },
      {
        question: "How much does the Sniper Bot cost?",
        ans: (
          <>
            Please visit our pricing page for detailed information on the various subscription plans available for the Sniper Bot.
          </>
        ),
      },
      {
        question: "Do you offer customer support for the Sniper Bot?",
        ans: (
          <>
           Yes, we provide dedicated customer support to assist you with any questions or issues related to the Sniper Bot.
          </>
        ),
      },
      {
        question: "Can the Sniper Bot be used on other blockchain networks?",
        ans: (
          <>
            Currently, our Sniper Bot is designed specifically for the Binance Smart Chain. However, we are constantly exploring 
            new possibilities and may expand to other networks in the future.
          </>
        ),
      },
    ];