import React from "react";
import Tippy from "@tippyjs/react";

import icon from "../../assests/pijja.svg";
import img1 from "../../assests/UnlikeCompetitors-img.webp";
import img2 from "../../assests/bitcoin.webp";
import img3 from "../../assests/eth.webp";
import img4 from "../../assests/algorand.webp";
import img5 from "../../assests/solana.webp";
import img6 from "../../assests/arbitrum.webp";
import img7 from "../../assests/ftm.webp";
import img8 from "../../assests/op.webp";
import img9 from "../../assests/celo.webp";
import img10 from "../../assests/xrp.webp";
import img11 from "../../assests/ada.webp";
import img12 from "../../assests/ltc.webp";
import img13 from "../../assests/monero.webp";
import img14 from "../../assests/trx.webp";
import img15 from "../../assests/dot.webp";
import img16 from "../../assests/cosmos.webp";
import img17 from "../../assests/zcash.webp";
import img18 from "../../assests/casper.webp";

import correctIcon from "../../assests/correct.svg";
import LikeIcon from "../../assests/Like.svg";

function UnlikeCompetitors({scrollToPriceSection}) {
  const data = [
    {
      img: img1,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Avalanche Network",
    },
    {
      img: img2,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Bitcoin Network",
    },
    {
      img: img3,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Ethereum Network",
    },
    {
      img: img4,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Algorand Network",
    },
    {
      img: img5,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Solana Network",
    },
    {
      img: img6,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Arbitrum Network",
    },
    {
      img: img7,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Fantom Network",
    },
    {
      img: img8,
      free: false,
      name: (
        <>
         {" "}
        </>
      ),
      hoverText: "Optimism Network",
    },
    {
      img: img9,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Celo Network",
    },
    {
      img: img10,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "XRP Network",
    },
    {
      img: img11,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Cardano Network",
    },
    {
      img: img12,
      free: false,
      name: (
        <>
         {" "}
        </>
      ),
      hoverText: "Litecoin Network",
    },
    {
      img: img13,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Monero Network",
    },
    {
      img: img14,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Tron Network",
    },
    {
      img: img15,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Polkadot Network",
    },
    {
      img: img16,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Atom Network",
    },
    {
      img: img17,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Zcash Network",
    },
    {
      img: img18,
      free: false,
      name: (
        <>
          {" "}
        </>
      ),
      hoverText: "Casper Network",
    },
  ];

  const data1 = [
    "Trusted by 5,000+ Users",
    "Payments in BNB",
    "100MD Requests Validated",
  ];
  return (
    <section className="bg-[#fff] pt-[120px] pb-[80px]">
      <div className="container mx-auto max-w-[1170px]">
        <div className=" md:px-[140px] mt-[-70px] sm:mt-0 ">
          <h3 className="text-[#030025] mb-[20px] text-[22px] md:text-[36px] font-extrabold leading-[33px] md:leading-[1.4em] text-left md:text-center">
          Beyond BSC: FastlyNode's Vision for an Inclusive {" "}
            <u>Blockchain Future </u>
            <img className="w-[36px] inline" src={icon} alt="" />
          </h3>
          <p className="text-[#1D1B21] text-[15px] md:text-[18px] leading-[1.5em] md:leading-[1.7em] text-left md:text-center">
          A Sneak Peek into Our Upcoming Private Node Offerings for Emerging Blockchains.
          </p>
        </div>
        <div className="pt-[30px] md:pt-[50px]">
          <div className="md:flex md:flex-wrap gap-x-[20px] md:gap-x-0 grid grid-cols-2 sm:justify-between">
            {data.map((el, i) => (
              <Tippy content={<span className="text-[14px]">{el.hoverText}</span>}>
                <div
                  key={i}
                  className="w-full md:w-[16.6%] pb-[20px] cursor-pointer "
                >
                  <div className="bg-white pt-[30px] pb-[30px] relative md:mx-[10px] px-0 md:px-[20px] shadow-a-3 rounded-[9px]">
                    {el.free && (
                      <div className="left-[0px] absolute top-0">
                        <div className="bg-[#66A801] py-[3px] px-[8px] rounded-tl-[6px] rounded-tr-0 rounded-bl-0 rounded-br-[6px]">
                          <p className="text-[10px] text-[#FFFFFF] uppercase font-semibold leading-[1.5em]">
                            Free
                          </p>
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="text-left sm:text-center mb-[20px]">
                        <img className="w-[26%] mx-auto hover:translate-y-[-5px] duration-300" src={el.img} alt="" />
                      </div>
                      <p className="text-[#131017] text-[10px] sm:text-[14px] leading-[1.5em] font-semibold l-s text-center">
                        {el.name}
                      </p>
                    </div>
                  </div>
                </div>
              </Tippy>
            ))}
          </div>
        </div>

        <div className="text-center mt-[35px]">
          <div onClick={()=>scrollToPriceSection()} class="btn-1 cursor-pointer">
            <img src={LikeIcon} alt="" />
            <span>START NOW!</span>
          </div>
        </div>
        <div className="mt-[15px]">
          <div className="max-w-[670px] mx-auto ">
            <div className="flex flex-col sm:flex-row items-center p-8 sm:p-0 justify-between gap-1">
              {data1.map((el, i) => (
                <div key={i} className="flex gap-[6px] ">
                  <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                  <span className="text-[#54595F] font-semibold text-[12px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UnlikeCompetitors;
