import React, { useState } from "react";
import playIcon from "../../assests/play-button.webp";
import YouTube from "react-youtube";

function AutomatingTasksSingleCard({ data }) {
  const [playVideo, setPlayVideo] = useState(false);

  const opts = {
    playerVars: {
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  return (
    <>
      <div>
        <div className="relative aspect-video anam-play   rounded-[10px] overflow-hidden border border-[#c1c1c1]">
          {playVideo ? (
            <YouTube videoId="qlrl_tF_Tls" opts={opts} />
          ) : (
            <span className="cursor-pointer" onClick={() => setPlayVideo(true)}>
              <img src={data.banner} alt="" className="w-full" />
              <div className="absolute block w-full h-full top-0 left-0 ">
                <div className="flex justify-center items-center w-full h-full">
                  <img
                    className="w-[70px] h-[70px] rounded-full shadowPlushAnimation"
                    src={playIcon}
                    alt=""
                  />
                </div>
              </div>
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default AutomatingTasksSingleCard;
