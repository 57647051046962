import React from "react";
import { Link } from "react-router-dom";
import youtube from "../../assests/YT-1.webp";
import twitter from "../../assests/twitter-1.png";
import aws from "../../assests/aws1.png";

function Footer() {
  const productsData = [
    { link: "/", text: "Private Node" },
    { link: "/sniper-bot", text: "Sniper Bot" },
  ];

  const resourcesData = [
    { link: "", text: "Affiliate - Coming Soon" },
  ];

  const companyData = [
    { link: "/terms-conditions", text: "Terms and Conditions" },
    { link: "/privacy-policy", text: "Privacy Policy" },
    { link: "/about-us", text: "Who We Are?" },
  ];

  const getInTouchData = [
    { link: "/signup", text: "Sign Up Free" },
    { link: "/login", text: "Sign In" },
    { link: "/contact-us", text: "Contact Us" },
  ];

  const socialData = [
   {/* {
      link: "/",
      icon: youtube,
    },
    {
      link: "/",
      icon: twitter,
    },
    {
      link: "/",
      icon: aws,
    },*/}
  ]; 

  return (
    <footer>
      <div className="max-w-[1120px] mx-auto container pt-[50px] pb-[25px] md:pb-[50px]">
        <div className="flex  flex-wrap md:flex-nowrap gap-x-[10px] gap-y-[40px]">
          <div className="basis-[48%] md:basis-[19%]">
            <h2 className="text-[#1a2235] text-[16px] font-semibold mb-[20px]">
              Products
            </h2>
            <div className="space-y-[15px]">
              {productsData.map((el, i) => (
                <Link
                  className="text-[14px] text-[#575757] hover:text-[#0ab06b] block font-semibold "
                  key={i}
                  to={el.link}
                >
                  {el.text}
                </Link>
              ))}
            </div>
          </div>
          <div className="basis-[48%] md:basis-[17%]">
            <h2 className="text-[#1a2235] text-[16px] font-semibold mb-[20px]">
              Resources
            </h2>
            <div className="space-y-[15px]">
              {resourcesData.map((el, i) => (
                <Link
                  className="text-[14px] text-[#575757] hover:text-[#0ab06b] block font-semibold "
                  key={i}
                  to={el.link}
                >
                  {el.text}
                </Link>
              ))}
            </div>
          </div>
          <div className="basis-[48%] md:basis-[18%]">
            <h2 className="text-[#1a2235] text-[16px] font-semibold mb-[20px]">
              Company Details
            </h2>
            <div className="space-y-[15px]">
              {companyData.map((el, i) => (
                <Link
                  className="text-[14px] text-[#575757] hover:text-[#0ab06b] block font-semibold "
                  key={i}
                  to={el.link}
                >
                  {el.text}
                </Link>
              ))}
            </div>
          </div>
          <div className="basis-[48%] md:basis-[20%]">
            <h2 className="text-[#1a2235] text-[16px] font-semibold mb-[20px]">
              Get In Touch
            </h2>
            <div className="space-y-[15px]">
              {getInTouchData.map((el, i) => (
                <Link
                  className="text-[14px] text-[#575757] hover:text-[#0ab06b] block font-semibold "
                  key={i}
                  to={el.link}
                >
                  {el.text}
                </Link>
              ))}
            </div>
          </div>

          <div className="w-[100%] md:w-[22%]">
            <h2 className="text-[#1a2235] text-[16px] font-semibold mb-[10px] md:mb-[20px]">
              Follow Us
            </h2>
            <p className="text-[#575757] text-[14px] font-semibold tracking-[0.5px] leading-[1.5em]">
            FastlyNode provides a high-quality infrastructure that is quick, cost-effective, and reliable. 
            </p>
            <div className="flex gap-2 items-center mt-[15px]">
              <svg
                className="fill-[#222222] w-[15px] "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"></path>
              </svg>
              <span className="text-[#575757] text-[14px] leading-[0.8em] tracking-[0.4px]">
                {" "}
                support@fastlynode.com
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1120px] mx-auto container">
        <div className="border-[#e8e8e8] border-t pt-[10px] pb-[20px]">
          <div>
            <div className="flex  space-x-2">
              <p className="text-[#252c35e6] text-[12px] ">
                {" "}
                FastlyNode © 2023. All Rights Reserved.
              </p>{" "}
              <a className="text-[#252c35e6] text-[12px] " href="">
                Sitemap
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
