import support from "../../assests/contact/Support1.png";
import supportNew from "../../assests/contact/Support-new.png";
import facebook from "../../assests/contact/Facebook.png";
import youtube from "../../assests/contact/youtube.png";
import time from "../../assests/contact/Time.png";

function ContactRightSection() {
  const data = [
    {
      icon: support,
      title: "Reach us at",
      text: "support@fastlynode.com",
    },
    {
      icon: supportNew,
      title: "Technical Support",
      text: " Get in touch with team here.",
      link: "",
    },
    {
      icon: time,
      title: "Business Hours",
      text: "10.00 AM to 6.00 PM IST (Except Sunday)",
    },
  ];
  return (
    <>
      <div className="w-full md:w-[37%]">
        <div className="space-y-[30px]">
          {data.map((el, i) => (
            <div
              className="text-[#fff] py-[25px] px-[20px] shadow-a-6 contact-card"
              key={i}
            >
              {el.link ? (
                <a href={el.link}>
                  <div className="flex gap-4">
                    <div className="w-[12%]">
                      <img src={el.icon} alt="" />
                    </div>
                    <div className="w-[88%]">
                      <h3 className="text-[#222222] text-[16px] font-bold">
                        {el.title}
                      </h3>
                      <p className={`text-[#656C73] text-[14px] font-bold`}>
                        {el.text}
                      </p>
                    </div>
                  </div>
                </a>
              ) : (
                <div className="flex gap-4">
                  <div className="w-[12%]">
                    <img src={el.icon} alt="" />
                  </div>
                  <div className="w-[88%]">
                    <h3 className="text-[#222222] text-[16px] font-bold">
                      {el.title}
                    </h3>
                    <p className={`text-[#656C73] text-[14px] font-bold`}>
                      {el.text}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ContactRightSection;
