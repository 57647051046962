import React, { useEffect, useState, useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import { baseApi } from "./../../data/APIDATA";
import ProtectedHeader from "./ProtectedHeader";
import ProtectedRouteSideBar from "./ProtectedRouteSideBar";
import ProtectedFooter from "./ProtectedFooter";
import { useSelector } from "react-redux";

function PrivetRoute({ children, ...rest }) {
  const appProps = useSelector(state => state);
  console.log({appProps});
  const [myauth, setMyAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  async function verifyToken() {
    if(appProps.token && appProps.userInfo){
      setLoading(false)
      setMyAuth(true)
    } else {
      setLoading(false)
      setMyAuth(false)
    }
  }

  useEffect(() => {
    verifyToken();
  }, []);

  if (loading) return <p>loading . . .</p>;

  return myauth ? (
    <>
      <ProtectedHeader />

      <div>
        <div className="flex">
          <ProtectedRouteSideBar />
          <div className="w-full lg:w-[94%] min-h-screen bg-[#f3f7fa] ">
            <Outlet />
          </div>
        </div>
      </div>
      <ProtectedFooter />
    </>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivetRoute;
