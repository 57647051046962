import like from "../../assests/Like.svg";

function ContactBottom() {
  return (
    <section className="pb-[60px] md:pb-[100px] bg-[#F7FBF9] pt-[60px] border-t border-b">
      <div className="container mx-auto max-w-[940px]">
        <div>
          <h2 className="text-[#222222] text-center md:px-[60px] mb-[20px] text-[28px] md:text-[36px] font-bold md:leading-[1.4em] leading-[38px]">
          Join the FastlyNode community!
          </h2>
          <p className="text-[#1D1B21] text-[18px] font-semibold text-center leading-[1.8em] tracking-[0.3px]">
          Effortlessly deploy and expand decentralized networks and applications with our expertly managed blockchain solutions.
          </p>

          <div className="text-center mt-[30px]">
                <a href="/signup">
                    <button className=" bg-[#000000] inline-block mx-auto rounded-[9px] hover:scale-110 duration-150 tracking-[0.5px] text-white px-[24px] py-[16px] uppercase font-bold">
                       <img src={like} className="w-[18px] inline mr-[5px]" alt="" /> Get Started Now
                    </button>
                </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactBottom;
