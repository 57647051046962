

export const optionData  = [
    {
      text: (
        <>
          <span> Unlimited</span> Requests
        </>
      ),
      hoverText: "Hover Text Here",
    },
    {
      text: (
        <>
          WebSocket connection
        </>
      ),
      hoverText: "Hover Text Here",
    },
    {
      text: (
        <>
        Support  <span> 24/24</span> 
        </>
      ),
      hoverText: "Hover Text Here",
    },
    {
      text: (
        <>
        Account Manager 
        </>
      ),
      hoverText: "Hover Text Here",
    },
    
  //   {
  //     text: "Multi-Step Calls",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Formatters",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Delaying",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Scheduling",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: " Re-execute workflows",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Email Parser",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Filter ",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: (
  //       <>
  //         <span> Unlimited</span> Operations
  //       </>
  //     ),
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: (
  //       <>
  //         <span> Unlimited</span> Operations
  //       </>
  //     ),
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: (
  //       <>
  //         <span> Unlimited</span> Operations
  //       </>
  //     ),
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: " Free Internal Tasks",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Two Factor Authentication",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Enhanced Security",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Folder Management",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "1000+ App Integrations",
  //     hoverText: "Hover Text Here",
  //   },
  //   {
  //     text: "Community (12K+ Members)",
  //     hoverText: "Hover Text Here",
  //   },
  // 

  ];
export const botOptionData  = [
    {
      text: (
        <>
          <span> Unlimited</span> Buy/Sell
        </>
      ),
      hoverText: "",
    },
    {
      text: (
        <>
          Get access to the private SniperBot
        </>
      ),
      hoverText: "",
    },
    {
      text: (
        <>
          Connected at FastlyNode for best results
        </>
      ),
      hoverText: "",
    },
  ];

