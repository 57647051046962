import { ApolloClient, InMemoryCache,HttpLink } from '@apollo/client'
import {GraphHost} from "../Config/Constants"
export function tokenizeDate(ts) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    var mm = ts.getMonth() + 1; // getMonth() is zero-based
    var dd = ts.getDate();
  
    return [(dd>9 ? '' : '0') + dd,
            monthNames[mm-1],
            ts.getFullYear()
           ].join(' ');
};
export async function getClient(token=""){
    const client = new ApolloClient({
        //Assign to your cache property a instance of a InMemoryCache
        cache: new InMemoryCache(),
        //Assign your link with a new instance of a HttpLink linking to your graphql server.
        link: new HttpLink({
          uri: GraphHost,
          headers: {
            Authorization: token,
          },
        })
    })
    return client
}
export const validateEmail = (email) =>{
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};