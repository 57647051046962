import starIcon from "../../assests/5star.svg";

function HighlyStarRatting() {
  const data = [
    "Affordable, efficient, and scalable",
    "FastlyNode have exceeded expectations",
    "Never going back to Quick Node",
  ];
  return (
    <section className="bg-[#F5F9FB] pb-[80px]">
      <div className="container mx-auto max-w-[1100px]">
        <div className="bg-white shadow-a-2 px-[20px] rounded-[14px] pt-[20px] pb-[30px] mt-[-50px] relative z-[30]">
          <div className="hidden md:flex justify-between">
            {data.map((el, i) => (
              <div
                key={i}
                className="w-[32.5%] flex justify-center flex-col gap-[8px] items-center"
              >
                <div className="w-[30%]">
                  <img src={starIcon} alt="" />
                </div>
                <p className="text-[#606077] text-[14px] leading-[1.5em]">
                  {" "}
                  "{el}"
                </p>
              </div>
            ))}
          </div>
          <div className="flex md:hidden justify-between">
            {data.slice(0,1).map((el, i) => (
              <div
                key={i}
                className="w-[100%] flex justify-center flex-col gap-[8px] items-center"
              >
                <div className="w-[36%]">
                  <img src={starIcon} alt="" />
                </div>
                <p className="text-[#606077] text-center text-[14px] leading-[1.5em]">
                  {" "}
                  "{el}"
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HighlyStarRatting;
