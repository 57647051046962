import React from "react";
import automation from "../../assests/automation.webp";
import updated from "../../assests/updated.webp";
import Googlesheets from "../../assests/Googlesheets.webp";
import automationVideo from "../../assests/aytomation-video.webp";

function TotalFreeCost() {
  const data = [
   { 
      img: automationVideo,
      title: "2,419+ Step by Step",
      underLineTitle: "Automation Videos",
      tag: "EXCLUSIVE BONUS 1",
      text: "Get instant access to our 2,419+ collection of step by step video tutorials explaining various business automation use cases.",
      value: "9,499",
    },
    {
      img: Googlesheets,
      title: "2,419+ Step by Step",
      underLineTitle: "Automation Videos",
      tag: "EXCLUSIVE BONUS 1",
      text: "Get instant access to our 2,419+ collection of step by step video tutorials explaining various business automation use cases.",
      value: "9,499",
    },
    {
      img: automation,
      title: "2,419+ Step by Step",
      underLineTitle: "Automation Videos",
      tag: "EXCLUSIVE BONUS 1",
      text: "Get instant access to our 2,419+ collection of step by step video tutorials explaining various business automation use cases.",
      value: "9,499",
    },
    {
      img: updated,
      title: "2,419+ Step by Step",
      underLineTitle: "Automation Videos",
      tag: "EXCLUSIVE BONUS 1",
      text: "Get instant access to our 2,419+ collection of step by step video tutorials explaining various business automation use cases.",
      value: "9,499",
    }, 
  ]; 
  return ( 
   <section className="bg-[#F5F9FB] z-20 relative md:pb-[160px]  ">
       <div className="container mx-auto max-w-[1200px] ">
        <div className="shadow-a-2">
          {data.map((el, i) => (
            <div
              key={i}
              className="flex flex-wrap mb-[3px] md:flex-nowrap px-[15px] md:px-[30px] md:gap-x-[20px] py-[32px] bg-white totalFreeCost-card"
            >
              <div className="w-[13%] md:w-[4%]">
                <img src={el.img} alt="" />
              </div>
              <div className="w-[83%] pl-[20px] md:pl-[0px] md:w-[79.5%]">
                <div className="text-white text-[12px] uppercase font-bold inline-block bg-[#2687FF] px-[8px] py-[4px] rounded-[5px] mb-[7px]">
                  {el.tag}
                </div>
                <h2 className="text-[#222222] text-[16px] mb-[10px] md:text-[18px] font-extrabold">
                  <u>{el.underLineTitle}</u> {el.title}
                </h2>
                <p className="text-[#000000cc] leading-[1.5em] text-[16px]">
                  {el.text}
                </p>
              </div>
              <div className="w-[100%] md:w-[16%]">
                <div className="flex justify-center">
                  <div>
                    <div className="flex gap-x-2 justify-end mb-[20px]">
                      <span>
                        <svg
                          className="w-[20px] fill-[#23B377]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                        </svg>
                      </span>
                      <span className="text-[#222222] text-[14px] font-bold">
                        Included for FREE
                      </span>
                    </div>
                    <h3 className="text-[#000000] text-[16px] md:text-[18px] font-extrabold text-right">
                      <u>Value: ${el.value}</u>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-[#EDF9FF] shadow-a-4 py-[20px] px-[10px] md:px-[20px] rounded-bl-[10px] rounded-br-[10px]">
          <h2 className="text-[20px] md:text-[26px] text-center text-[#222222] font-bold">
            Total Value:{" "}
            <span className="text-[32px] line-through decoration-[red] text-[red]">
              $28,794
            </span>{" "}
            FREE 😀
          </h2>
        </div>
      </div>
    </section> 

  );
}

export default TotalFreeCost;
