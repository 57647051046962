
export const contentPageQuestion1 = [
  {
    question: " What is FastlyNode?",
    ans: (
      <>
       FastlyNode is a Software-as-a-Service (SaaS) platform that offers secure, anonymous, and high-performance private node services for the 
       Binance Smart Chain (BSC) blockchain. Our platform provides a range of benefits, including control, scalability, privacy, customization, 
       compliance, and efficiency, enhancing your BSC experience.
      </>
    ),
  },
  {
    question: "  How do I get started with FastlyNode?",
    ans: (
      <>
        To get started with FastlyNode, simply choose a plan from our Weekly, Monthly, or Annual options, and complete the payment in 
        cryptocurrency. Once your payment is confirmed, you will have access to our state-of-the-art private node services for the BSC blockchain.
      </>
    ),
  },
  {
    question: "  What are the advantages of using private nodes over public networks?",
    ans: (
      <>
       Private nodes offer numerous advantages over public networks, including enhanced control, superior scalability, better privacy and security, 
       more customization options, improved compliance with industry regulations, and increased performance and reliability.
      </>
    ),
  },
  {
    question: "  What payment methods do you accept?",
    ans: (
      <>
       FastlyNode exclusively accepts payments in cryptocurrency. This ensures secure, anonymous transactions and aligns with our commitment to 
       providing advanced solutions for the BSC blockchain ecosystem.
      </>
    ),
  },
  {
    question: "  Is FastlyNode suitable for both small and large-scale BSC projects?",
    ans: (
      <>
        Absolutely! FastlyNode's private node services cater to BSC projects of all sizes, providing enhanced control, scalability, and customization options. 
        Our platform ensures that your applications run smoothly and efficiently, regardless of the project's size or complexity.
      </>
    ),
  },
  {
    question: "  How does FastlyNode ensure privacy and security?",
    ans: (
      <>
        FastlyNode prioritizes privacy and security by implementing advanced encryption technologies, strict access controls, and robust security measures. 
        Our private node services provide superior privacy and security compared to public networks, safeguarding your transactions and sensitive project information.
      </>
    ),
  },
  {
    question: "  Do you offer customer support?",
    ans: (
      <>
        Yes, FastlyNode provides expert customer support to address any questions or issues you may encounter. Our dedicated team of blockchain experts is 
        available to assist you, ensuring a smooth and hassle-free experience with our platform.
      </>
    ),
  },
  {
    question: "  Is there a trial period for FastlyNode services?",
    ans: (
      <>
        Currently, FastlyNode does not offer a trial period for our private node services. However, you can start with our Weekly Plan to experience the 
        benefits and performance of our platform before committing to a longer-term plan.
      </>
    ),
  },
  ];
export const contentPageQuestion2 = [
    {
      question: "Can I use FastlyNode with my custom BSC applications?",
      ans: (
        <>
         Yes, FastlyNode's private node services are designed to support custom BSC applications. 
         Our platform allows for seamless integration and configuration, enabling you to tailor your BSC experience for optimal results.
        </>
      ),
    },
    {
      question: "What makes FastlyNode's private nodes more efficient than public networks?",
      ans: (
        <>
          FastlyNode's private nodes offer improved performance and reduced latency compared to public networks. Our state-of-the-art 
          infrastructure ensures faster transaction processing, increased reliability, and a more seamless user experience for your BSC applications.
        </>
      ),
    },
    {
      question: "How does FastlyNode help with industry compliance?",
      ans: (
        <>
         FastlyNode's private nodes provide the necessary tools and features to meet strict regulatory requirements in various industries. 
         Our platform ensures that your BSC applications remain within legal boundaries and adhere to industry standards and regulations.
        </>
      ),
    },
    {
      question: "Can I cancel my FastlyNode subscription at any time?",
      ans: (
        <>
          Yes, you can cancel your FastlyNode subscription at any time. If you wish to cancel your subscription, please contact our 
          support team, and they will guide you through the cancellation process.
        </>
      ),
    },
  ];
  export const contentPageQuestion3 = [
    {
      question: "What is FastlyNode Affiliation?",
      ans: (
        <>
         FastlyNode Affiliation is a program that allows individuals or businesses to earn commissions by promoting FastlyNode 
         private nodes for blockchain infrastructure. Affiliates will receive a unique link to share with their network, and every 
         time someone purchases a paid plan through that link, the affiliate will earn a commission.
        </>
      ),
    },
    {
      question: "How much commission do I earn with FastlyNode Affiliation?",
      ans: (
        <>
          Affiliates can earn a 30% commission on every sale made through their unique link.
        </>
      ),
    },
    {
      question: "When can I request a withdrawal of commissions?",
      ans: (
        <>
         Affiliates can request a withdrawal of their commissions at any time by making a request on the affiliation dashboard.
        </>
      ),
    },
    {
      question: "What is the minimum withdrawal amount for commissions?",
      ans: (
        <>
          The minimum withdrawal amount for commissions is $250.
        </>
      ),
    },
  ];
  export const contentPageQuestion4 = [
    {
      question: "How do I get paid for my commissions?",
      ans: (
        <>
         Affiliates will be paid their commissions through their chosen payment method, which can be set up on the affiliation dashboard.
        </>
      ),
    },
    {
      question: "How often can I request a withdrawal of commissions?",
      ans: (
        <>
          Affiliates can request a withdrawal of their commissions at any time, as long as the minimum withdrawal amount has been reached.
        </>
      ),
    },
    {
      question: "How do I keep track of my commissions?",
      ans: (
        <>
         Affiliates can keep track of their commissions through the affiliation dashboard, which provides real-time updates on sales and commissions earned.
        </>
      ),
    },
    {
      question: "What currency is used to pay out commissions?",
      ans: (
        <>
          Commissions are paid out in USDT.
        </>
      ),
    },
  ];