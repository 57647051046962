import React from "react";
import TopHeader from "./../components/common/TopHeader";
import Header from "./../components/common/Header";
import StarRating from "./../components/connect/StarRating";
import { useRef } from "react";
import SniperConnectMultipleApplications from "../components/sniperBot/SniperConnectMultipleApplications";
import SnipperTrustedBy from "./../components/sniperBot/SnipperTrustedBy";
import SniperPabblyConnect from "./../components/sniperBot/SniperPabblyConnect";
import SniperAutomatingTasks from "./../components/sniperBot/SniperAutomatingTasks";
import SniperPabblyConnectWork from "./../components/sniperBot/SniperPabblyConnectWork";
import SniperAutomationsWithNoCode from "./../components/sniperBot/SniperAutomationsWithNoCode";
import SniperAutomatingAnything from "./../components/sniperBot/SniperAutomatingAnything";
import SniperHighlyStarRatting from "./../components/sniperBot/SniperHighlyStarRatting";
import SniperBoostProductivity from "./../components/sniperBot/SniperBoostProductivity";
import SniperUnlikeCompetitors from "./../components/sniperBot/SniperUnlikeCompetitors";
import SniperAutomationWorkflows from "./../components/sniperBot/SniperAutomationWorkflows";
import CustomarComentContent from "./../components/connect/CustomarComentContent";

import user1 from "../assests/user1.webp";
import SniperFeaturesYouNeed from "./../components/sniperBot/SniperFeaturesYouNeed";
import SniperHelpYouMasterAutomation from "./../components/sniperBot/SniperHelpYouMasterAutomation";
import SniperWorkBetterTogethe from "./../components/sniperBot/SniperWorkBetterTogethe";
import Pricing2 from "./../components/connect/Pricing2";
import TotalFreeCost from "./../components/connect/TotalFreeCost";
import DoubleGurantee from "./../components/connect/DoubleGurantee";
import WhoAreTrustingUs from "./../components/connect/WhoAreTrustingUs";
import FrequentlyAskedQuestions from "./../components/common/FrequentlyAskedQuestions";
import {
  contentPageQuestion1,
  contentPageQuestion2,
} from "./../data/FrequentlyAskedQuestionsDataBot";
import AmazingTeam from "./../components/common/AmazingTeam";
import Footer from "./../components/common/Footer";

function SniperBot() {
  const comment2 = {
    name: "Paul, Customer",
    img: user1,
    text: "Thanks to FastlyBot, I was able to turn my trading hobby into a profitable side hustle. The quick and easy setup process and accessible platform made it easy for me to get started, and I ve been seeing consistent profits ever since.",
  };

  const priceSection = useRef(null);

  const scrollToPriceSection = () => {
    window.scrollTo({
      top: priceSection.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <TopHeader scrollToPriceSection={scrollToPriceSection} />
      <Header />
      <StarRating />

      <SniperConnectMultipleApplications
        scrollToPriceSection={scrollToPriceSection}
      />
      <SnipperTrustedBy />
     <SniperPabblyConnect />
     {/* <SniperAutomatingTasks scrollToPriceSection={scrollToPriceSection} />*/}
      <SniperPabblyConnectWork scrollToPriceSection={scrollToPriceSection} />
      <SniperAutomationsWithNoCode />
      {/*<SniperAutomatingAnything />*/}
      {/*<SniperHighlyStarRatting /> */}
      <SniperBoostProductivity scrollToPriceSection={scrollToPriceSection} />
      {/*<SniperUnlikeCompetitors /> */}
      {/* <SniperAutomationWorkflows scrollToPriceSection={scrollToPriceSection} /> */}
      {/*<CustomarComentContent bg={"bg-[#fff]"} comment={comment2} /> */}
      <SniperFeaturesYouNeed scrollToPriceSection={scrollToPriceSection} />

     {/* <SniperHelpYouMasterAutomation
        scrollToPriceSection={scrollToPriceSection}
      /> */}
      {/*<SniperWorkBetterTogethe /> */}
      {/*<CustomarComentContent bg={"bg-[#F5F9FB]"} comment={comment2} /> */}
      <Pricing2 priceSection={priceSection} />

     {/* <TotalFreeCost /> */}
      {/*<DoubleGurantee /> */}

      {/* <WhoAreTrustingUs scrollToPriceSection={scrollToPriceSection} /> */}
      <div className="ques-bg pt-[40px] md:pt-[80px] pb-[50px] md:pb-[100px]">
        <FrequentlyAskedQuestions
          data={contentPageQuestion1}
          data2={contentPageQuestion2}
        />
      </div>
      {/*<AmazingTeam scrollToPriceSection={scrollToPriceSection} /> */}
      <Footer />
    </>
  );
}

export default SniperBot;
