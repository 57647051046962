import React, { useState } from "react";

import logo from "../../assests/signup/logo-login-black.png";
import avatarIng from "../../assests/allApp/avatar.png";
import { Link } from "react-router-dom";

import { CgMenuGridR } from "react-icons/cg";
import HeaderMenuProfileOption from "./HeaderMenuProfileOption";
import HeaderMenuAllApp from "./HeaderMenuAllApp";

function ProtectedHeader() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [appOpen, setAppOppen] = useState(false);

  const handleMenuOps = () => {
    setMenuOpen(!menuOpen);
    setAppOppen(false)
  };

  const handleAppOpen = () => {
    setAppOppen(!appOpen);
    setMenuOpen(false)
  };

  return (
    <header className="p-[15px] h-[87px] border-b border-[#d4e2ff] shadow-a-10">
      <div className=" mx-auto px-[15px]">
        <div className="flex  items-center gap-y-[10px] py-[10px] flex-row ">
          <div className=" flex md:block  w-[50%]">
            <Link to="/"><img src={logo} alt="" /></Link>
          </div>
          <div className="relative w-[50%] ">
            <div className="flex justify-end items-center space-x-4">
              <div className="border relative border-[#dee2e6] cursor-pointer inline-block">
                <CgMenuGridR
                  onClick={handleAppOpen}
                  className="text-[#6c757d] text-[27px]"
                />

                {appOpen && <HeaderMenuAllApp handleAppOpen={handleAppOpen}/>}
              </div>
              <div>
                <img
                  className="w-[35px] cursor-pointer rounded-full inline-block"
                  onClick={handleMenuOps}
                  src={avatarIng}
                  alt=""
                />
              </div>
              {menuOpen && <HeaderMenuProfileOption handleMenuOps={handleMenuOps}  />}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default ProtectedHeader;
