import {GET_ME,GET_OFFRES} from "../Graphql/Queries"
import {BOT_SUBSCRIPTION, BOT_SUBSCRIPTION_UPDATE, CHECK_PRICE_RULE, CODE_CLICK, CREATE_SUBSCRIPTION, CREATE_USER,LOGIN_USER, UPDATE_SUBSCRIPTION} from "../Graphql/Mutations"
export function userCreate(apollo,variables) {
    return apollo.mutate({
        mutation: CREATE_USER,
        variables
    })
    .then((response) => {
        if (response.data.userCreate.success) {
            return({success:true,data:response.data.userCreate.token})
        } else {
            return({success:false,error:response.data.userCreate.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}
export function userLogin(apollo,variables) {
    return apollo.mutate({
        mutation: LOGIN_USER,
        variables
    })
    .then((response) => {
        if (response.data.userLogin.success) {
            return({success:true,data:response.data.userLogin.token})
        } else {
            return({success:false,error:response.data.userLogin.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}

export function transactionCreate(apollo,variables) {
    return apollo.mutate({
        mutation: CREATE_SUBSCRIPTION,
        variables
    })
    .then((response) => {
        if (response.data.transactionCreate.success) {
            return({success:true,data:response.data.transactionCreate.data})
        } else {
            return({success:false,error:response.data.transactionCreate.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}
export function subscriptionUpdate(apollo,variables) {
    return apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION,
        variables
    })
    .then((response) => {
        if (response.data.subscriptionUpdate.success) {
            return({success:true,data:response.data.subscriptionUpdate.data})
        } else {
            return({success:false,error:response.data.subscriptionUpdate.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}
export async function getMe(apollo) {
    return apollo.query({
        query: GET_ME,
        fetchPolicy:'network-only'
    })
    .then((response) => {
        if (response.data.me.success) {
            return({success:true,data:response.data.me.data})
        }
        return({success:false,error:response.data.me.error})
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({success:false,error:{code:"SYS_ERROR",message:"Something went wront, please try again later"}})
    })
}

export function transactionBotCreate(apollo,variables) {
    return apollo.mutate({
        mutation: BOT_SUBSCRIPTION,
        variables
    })
    .then((response) => {
        if (response.data.transactionBotCreate.success) {
            return({success:true,data:response.data.transactionBotCreate.data})
        } else {
            return({success:false,error:response.data.transactionBotCreate.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}
export function subscriptionBotUpdate(apollo,variables) {
    return apollo.mutate({
        mutation: BOT_SUBSCRIPTION_UPDATE,
        variables
    })
    .then((response) => {
        if (response.data.subscriptionBotUpdate.success) {
            return({success:true,data:response.data.subscriptionBotUpdate.data})
        } else {
            return({success:false,error:response.data.subscriptionBotUpdate.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}
export async function offres(apollo,variables) {
    return apollo.query({
        query: GET_OFFRES,
        variables
    })
    .then((response) => {
        if (response.data.offres.success) {
            return({success:true,data:response.data.offres.edges})
        }
        return({success:false,error:response.data.offres.error})
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({success:false,error:{code:"SYS_ERROR",message:"Something went wront, please try again later"}})
    })
}

export function checkPriceRules(apollo,variables={}) {
    return apollo.query({
        query: CHECK_PRICE_RULE,
        variables,
    })
    .then((response) => {
        if (response.data.priceRuleCheck.success) {
            return({success:true,data:response.data.priceRuleCheck.priceRule})
        } else {
            return({success:false,error:response.data.priceRuleCheck.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please contact your admin"
            }
        })
    })
}

export function codeClick(apollo,variables) {
    return apollo.mutate({
        mutation: CODE_CLICK,
        variables
    })
    .then((response) => {
        if (response.data.codeClick.success) {
            return({success:true})
        } else {
            return({success:false,error:response.data.codeClick.error})
        }
    })
    .catch((error)=>{
        console.log(JSON.stringify(error));
        return({
            success:false,
            error:{
                code:"SYS_ERR",
                message:"Something went wrong please check your internet connexion and try again"
            }
        })
    })
}