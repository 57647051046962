import React from "react";
import pPlus from "../../assests/Plus-1.webp";
import bundle from "../../assests/Bundle.webp";
import { menuProducList } from "./../../data/MenuData";
import { Link } from "react-router-dom";

function HoverProduct() {
  return (
    <div className="absolute z-[900] right-[-70px]  p-[30px]  border rounded-[8px] border-[#ececec] bg-white  w-[720px] md:block hidden">
      <div>
        <div>
          <div className="flex border  items-center border-[#F6E8FF] rounded-[8px] bg-white relative p-[20px]">
            <div className="absolute top-[-13px] left-[40px]">
              <img className="w-[80px]" src={bundle} alt="" />
            </div>
            <div className="w-[12%]">
              <img className="w-[50px]" src={pPlus} alt="" />
            </div>
            <div className="w-[77%]">
              <p className="text-[#22272d] text-[16px] font-semibold">
                FastlyNode Plus
              </p>
              <p className="text-[#5B5B5B] text-[14px] leading-[1.5em]">
              Soon available, FastlyNode +, which will give you access to our entire library of services.
              </p>
            </div>
          </div>

          <div>
            <div className="grid mt-[40px] gap-[40px] grid-cols-2">
              {menuProducList.map((el, i) => (
                <Link to={el.link} key={i}>
                  <div className="flex">
                    <div className="w-[16%]">
                      <img src={el.icon} alt="" />
                    </div>
                    <div className="w-[83%]">
                      <div>
                        <h3 className="text-[#22272d] font-semibold text-[16px] mb-[8px] leading-[1.3em]">
                          {el.title}
                        </h3>
                        <p className="text-[#5B5B5B] text-[14px] leading-[1.5em]">
                          {el.text}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoverProduct;
