
import Tippy from "@tippyjs/react";
import dollarIcon from "../../assests/dollar.webp";
import img1 from "../../assests/AutomationWorkflows-1.webp";
import img2 from "../../assests/AutomationWorkflows-2.webp";
import img3 from "../../assests/AutomationWorkflows-3.webp";
import img4 from "../../assests/AutomationWorkflows-4.webp";

import LikeIcon from "../../assests/Like.svg";
import correctIcon from "../../assests/correct.svg";
import { Link } from "react-router-dom";

function AutomationWorkflows({scrollToPriceSection}) {
  const list = [
    {
      img: img1,  hoverText:"NOWNodes  limits you in the number of requests"
    },
    {
      img: img2, hoverText:"QuickNodes limits you in the number of requests"
    },
    {
      img: img3,
      big: true,  hoverText:"FastlyNode is unlimited requests for all"
    },
  ];

  const data = [
    "Trusted by 5,000+ Users",
    "Payments in BNB",
    "100MD Requests Validated",
  ];
  return (
    <section className="bg-[#F5F9FB] pt-[100px] overflow-hidden pb-[180px] relative z-50">
      <div className="container mx-auto max-w-[1150px] relative">
        <div className="">
          <h3 className="text-[#030025] mb-[20px] text-left md:text-center text-[22px] md:text-[36px] mt-[-65px] md:mt-0 font-extrabold leading-[33px] md:leading-[1.5em]">
          FastlyNode vs. Competitors: <u> Discover the Winning Edge</u>{" "}
            <img src={dollarIcon} className="w-[36px] inline " alt="" />
          </h3>
          <p className="text-[#1D1B21] px-0 md:px-[130px] mb-[20px] text-[15px] md:text-[18px] text-left md:text-center leading-[1.5em] md:leading-[1.7em]">
          Experience Unmatched Performance, Security, and Customization with Our Superior Blockchain Solutions
          </p>
        </div>
        <div className="space-y-[10px] ">
          {list.map((el, i) => (
            <Tippy content={<span className="text-[14px]">{el.hoverText}</span>}>
             <div key={i} className="cursor-pointer">
              <img
                src={el.img}
                className={`${
                  el.big ? "w-full md:w-[86%]" : "w-full md:w-[80%]"
                } mx-auto`}
                alt=""
              />
            </div>
          </Tippy>
          
          ))}
          <div className="absolute top-[330px] left-[842px] w-[438px]">
            <div className="hidden xl:block">
              <img className="ml-auto" src={img4} alt="" />
            </div>
          </div>
        </div>
        <div className="text-center mt-[25px]">
          <div onClick={()=>scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
            <img src={LikeIcon} alt="" />
            <span>START NOW!</span>
          </div>
        </div>
        <div className="mt-[15px] pt-[30px] md:pt-[0px]">
          <div className="max-w-[670px] mx-auto ">
            <div className="flex flex-col  sm:flex-row items-center justify-between gap-1">
              {data.map((el, i) => (
                <div key={i} className="flex gap-[6px] ">
                  <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                  <span className="text-[#54595F] font-semibold text-[12px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AutomationWorkflows;
