import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import SingleFaq from "./SingleFaq";

function FrequentlyAskedQuestions({ data, data2 }) {
  const [openList, setOpenList] = useState([]);

  const handleClick = (q) => {
    const exist = openList.find((el) => el == q);

    if (exist) {
      const filtred = openList.filter((el) => el != q);
      setOpenList(filtred);
    } else {
      setOpenList([...openList, q]);
    }
  };

  return (
    <section className={``}>
      <div className="container mx-auto max-w-[1120px] ">
        <div>
          <h3 className="text-[#030025] mb-[30px] md:mb-[40px] text-left md:text-center text-[22px] md:text-[36px] font-extrabold leading-[34px] md:leading-[1.4em]">
            Frequently Asked Questions
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
            <div className="space-y-[15px]">
              {/* {data.map((el, i) => (
                <div
                  key={i}
                  className="border-[#DFE9FF]  cursor-pointer rounded-[6px] overflow-hidden border"
                >
                  <div
                    onClick={() => handleClick(el.question)}
                    className="p-[20px] text-[#111F62] flex justify-between items-center gap-x-[15px] text-[16px] leading-[1.5em] font-bold"
                  >
                    <span className="w-[90%]">{el.question}</span>{" "}
                    <span className="w-[10%]">
                      <FaAngleDown />
                    </span>
                  </div>
                  <div
                    className={`text-[16px] text-[#282828]  ${
                      openList.find((j) => j == el.question)
                        ? "h-auto leading-[1.6em] pb-[20px] px-[20px]"
                        : "h-0"
                    }`}
                  >
                    {el.ans}
                  </div>
                </div>
              ))} */}
              {data.map((el, i) => (
                <SingleFaq key={i} data={el} />
              ))}
            </div>
            <div className="space-y-[15px]">
              {/* {data2.map((el, i) => (
                <div
                  key={i}
                  className="border-[#DFE9FF] cursor-pointer rounded-[6px] overflow-hidden border"
                >
                  <div
                    onClick={() => handleClick(el.question)}
                    className="p-[20px] text-[#111F62] text-[16px] leading-[1.5em] font-bold"
                  >
                    {el.question}
                  </div>
                  <div
                    className={`text-[16px] text-[#282828]  ${
                      openList.find((j) => j == el.question)
                        ? "h-auto leading-[1.6em] pb-[20px] px-[20px]"
                        : "h-0"
                    }`}
                  >
                    {el.ans}
                  </div>
                </div>
              ))} */}
                {data2.map((el, i) => (
                <SingleFaq key={i} data={el} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FrequentlyAskedQuestions;
