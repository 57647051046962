import tkIcon from '../../assests/tk.svg';
import user from '../../assests/user-2.webp';
import star from '../../assests/5star.svg';
import gurantee from '../../assests/gurantee1.webp';
import line from '../../assests/line.webp';

function PricingHeader() {
  return (
    <>
      <div className="container mx-auto pt-[20px] md:mb-[50px]">
        <div>
          <h3
            className="text-[#030025] px-[10px] max-w-[900px] p-5 sm:p-0 mx-auto md:mb-[20px]  text-start sm:text-center text-[22px] md:text-[36px] mt-[-85px] sm:mt-0
           font-extrabold leading-[33px] md:leading-[1.5em]"
          >
            Discover Our Comprehensive Package:{' '}
            <img src={tkIcon} className="w-[36px] inline " alt="" />
            <u>All-Inclusive Features</u> Without the Hefty Price Tag
          </h3>
          <p className="text-[#1D1B21] px-[10px] md:px-[90px] md:mb-[20px] text-[15px] md:text-[18px] text-start md:text-center leading-[1.5em] md:leading-[1.7em]">
          All-Inclusive Features Without the Hefty Price TagAt FastlyNode, we believe in offering exceptional value to our clients without compromising on quality. 
          That's why we've designed our comprehensive package to provide you with all-inclusive features at a budget-friendly price. Our solutions cater to businesses 
          of all sizes and industries, ensuring seamless integration, robust security, and outstanding performance. With FastlyNode, you gain access to a full suite of 
          cutting-edge blockchain tools and services, empowering you to stay ahead of the competition and drive success. Choose our comprehensive package and experience 
          the perfect blend of affordability and unparalleled functionality, tailored to your unique needs.
          </p>
        </div>
      </div>
      <div className="container mx-auto mb-[100px]">
        <div className="max-w-[680px] mx-auto relative">
          <div className="absolute max-w-[241px] left-[-228px] top-[-15px]">
            <img src={gurantee} alt="" className="w-[48%] rounded-[100px]" />
          </div>

          <div className="absolute hidden sm:block max-w-[50px] left-[686px] w-full top-[90px]">
            <img src={line} alt="" />
          </div>

          <div className="flex mb-[20px] md:mb-[50px] items-center">
            <div className="flex flex-col w-[75%]  md:flex-row p-3 md:p-0  md:w-[91%] gap-[15px] items-start">
              <img className="w-[100px] inline" src={star} alt="" />
              <h3 className="text-[#030025] text-[14px] md:text-[16px] font-bold leading-[1.8em]">
                "I have tested dozens of nodes so far, but FastlyNode is the first to provide me with such good performance. 
                You can trust them, thanks fastlynode." <span> - Jon</span>
              </h3>
            </div>
            <div className=" w-[24%] md:w-[9%]">
              <img
                className="border border-[#E3E3E3] rounded-[100px]"
                src={user}
                alt=""
              />
            </div>
          </div>
          <div className="py-[15px] rounded-[10px] border border-[#FDB27D] border-dashed bg-[#FFFFE2] text-center md:px-10 p-[10px]">
            <h3 className="text-[#000000] text-[14px] md:text-[16px] font-semibold">
            Expanding Our Horizons: Private Nodes on Multiple Blockchains Coming Soon 🎉{' '}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingHeader;
