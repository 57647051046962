import logo from "../../assests/signup/logo-login-black.png";
import { Link } from "react-router-dom";

function HeaderAuthPage({ login }) {
  return (
    <header className="p-[15px] shadow-a-10">
      <div className="container mx-auto max-w-[1140px]">
        <div className="flex flex-col gap-y-[10px] py-[10px] md:flex-row ">
          <div className="w-full flex md:block justify-center md:w-[50%]">
            <img src={logo} alt="" />
          </div>
          <div className="w-full  justify-center md:w-[50%] flex-wrap flex items-center md:justify-end gap-4">
            <p className="text-[#73879c] text-[13px]">
              {!login ? "Don't have a FastlyNode Account yet?" : "Already a user? "}
            </p>
            {!login ? (
              <Link
                className="text-[#fff] bg-[#28a745] py-[.375rem] px-[0.75rem] rounded-[0.25rem]"
                to="/signup"
              >
                Create Account
              </Link>
            ) : (
              <Link
                className="text-[#fff] bg-[#28a745] py-[.375rem] px-[0.75rem] rounded-[0.25rem]"
                to="/login"
              >
                Sign In
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderAuthPage;
