import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import trofeeIcon from '../../assests/trofee.svg';
import img1 from '../../assests/f-s-1.webp';
import img2 from '../../assests/f-s-2.webp';
import img3 from '../../assests/f-s-3.webp';
import img4 from '../../assests/f-s-4.webp';
import img5 from '../../assests/f-s-5.webp';
import img6 from '../../assests/f-s-6.webp';
import img7 from '../../assests/f-s-7.webp';
import img8 from '../../assests/f-s-8.webp';
import img9 from '../../assests/f-s-9.webp';

import { Autoplay } from 'swiper';

function PabblyConnect() {
  const list = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  return (
    <section className="bg-[#F5F9FB] py-[60px]">
      <div className="container mx-auto max-w-[1100px]">
        <div className="md:px-[100px] mb-[35px]">
          <h3 className="text-[#030025] text-left md:text-center text-[18px] md:text-[36px] leading-[26px] md:leading-[1.2em] font-extrabold">
          Building Success Together: <font color="#006df9"> A Showcase of the Companies </font>{' '}
          and Industry Leaders<img className="w-[36px] inline" src={trofeeIcon} alt="" />{' '}
            <span>
              <a href="" className="underline text-[#006DF9]">
              Who Trust and Collaborate with Us{' '}
              </a>
            </span>{' '}
          </h3>
        </div>

        <Swiper
          slidesPerView={5}
          slidesPerGroupSkip={2}
          spaceBetween={20}
          className="mySwiper"    modules={[Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            100: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
        >
          {list.map((el, i) => (
            <SwiperSlide>
              <div
                key={i}
                className="border border-[#E5EDFF] bg-white rounded-[6px]"
              >
                <img className="w-[100%]" src={el} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default PabblyConnect;
