import React from "react";
import img from "../../assests/User-Review.webp";
import love from "../../assests/love.svg";
import LikeIcon from "../../assests/Like.svg";
import correctIcon from "../../assests/correct.svg";

import review1 from "../../assests/Review1.webp";
import review2 from "../../assests/Review1.webp";
import review3 from "../../assests/Review1.webp";
import review4 from "../../assests/Review1.webp";
import review5 from "../../assests/Review1.webp";
import review6 from "../../assests/Review1.webp";
import review7 from "../../assests/Review1.webp";
import review8 from "../../assests/Review1.webp";
import review9 from "../../assests/Review1.webp";
import review10 from "../../assests/Review1.webp";
import review11 from "../../assests/Review1.webp";
import review12 from "../../assests/Review1.webp";
import review13 from "../../assests/Review1.webp";
import { Link } from 'react-router-dom';

function WhoAreTrustingUs({scrollToPriceSection}) {
  const data = [
    "Trusted by 5,000+ Users",
    "Payments in BNB",
    "100MD Requests Validated",
  ];

  const smReviewData = [
    review1,
    review2,
    review3,
    review4,
    review5,
    review6,
    review7,
    review8,
    review9,
    review10,
    review11,
    review12,
    review13,
  ];
  return (
    <section className=" pb-[50px] pt-[30px] md:pt-[80px] md:pb-[100px]">
    *  <div className="container mx-auto max-w-[1400px]">
        <div>
          <h3 className="text-[#030025] mb-[5px] md:text-center text-[22px] md:text-[36px] font-extrabold leading-[34px] md:leading-[1.5em]">
            Customers Who Are <u>Trusting Us!</u>
            <img src={love} className="w-[36px] inline " alt="" />
          </h3>
          <p className="text-[#1D1B21] lg:px-[200px] mb-[20px] md:mb-[50px] text-[15px] md:text-[18px] lg:text-center leading-[1.5em] md:leading-[1.7em]">
            Non incentivized reviews from real users.
          </p>
        </div>
        <div className="px-[20px] hidden md:block">
          <img src={img} alt="" />
        </div>
        <div className="space-y-[10px] block md:hidden">
          {smReviewData.map((el, i) => (
            <div key={i}>
              <img className="w-full" src={el} alt="" />
            </div>
          ))}
        </div>
        <div className="text-center mt-[25px]">
              <div onClick={()=>scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
                <img src={LikeIcon} alt="" />
                <span>START NOW!</span>
              </div>
            </div>
            <div className="mt-[15px]">
              <div className="max-w-[670px] mx-auto ">
                <div className="flex flex-row justify-center gap-x-5 gap-y-2 flex-wrap">
                  {data.map((el, i) => (
                    <div key={i} className="flex gap-[6px] ">
                      <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                      <span className="text-[#54595F] font-semibold text-[12px]">
                        {el}
                      </span>{" "}
                    </div>
                  ))} 

                </div>
              </div>
            </div>
      </div>*
    </section>
  );
}

export default WhoAreTrustingUs;
