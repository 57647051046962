import React, { useEffect, useRef } from "react";
import PabblyConnect from "./../components/connect/PabblyConnect";
import AutomatingTasks from "./../components/connect/AutomatingTasks";
import PabblyConnectWork from "./../components/connect/PabblyConnectWork";
import AutomationsWithNoCode from "./../components/connect/AutomationsWithNoCode";
import AutomatingAnything from "./../components/connect/AutomatingAnything";
import BoostProductivity from "./../components/connect/BoostProductivity";
import HighlyStarRatting from "./../components/connect/HighlyStarRatting";
import UnlikeCompetitors from "./../components/connect/UnlikeCompetitors";
import AutomationWorkflows from "./../components/connect/AutomationWorkflows";
import CustomarComentContent from "./../components/connect/CustomarComentContent";
import FeaturesYouNeed from "./../components/connect/FeaturesYouNeed";
import Connect1000Applications from "./../components/connect/Connect1000Applications";
import NewIntegrationsLaunched from "./../components/connect/NewIntegrationsLaunched";
import HelpYouMasterAutomation from "./../components/connect/HelpYouMasterAutomation";

import user1 from "../assests/user1.webp";
import WorkBetterTogethe from "./../components/connect/WorkBetterTogethe";
import Pricing from "./../components/connect/Pricing";
import TotalFreeCost from "./../components/connect/TotalFreeCost";
import DoubleGurantee from "./../components/connect/DoubleGurantee";
import WhoAreTrustingUs from "./../components/connect/WhoAreTrustingUs";
import FrequentlyAskedQuestions from "./../components/common/FrequentlyAskedQuestions";
import AmazingTeam from "./../components/common/AmazingTeam";
import Footer from "../components/common/Footer";
import StarRating from "./../components/connect/StarRating";
import ConnectMultipleApplications from "./../components/connect/ConnectMultipleApplications";
import TrustedBy from "./../components/connect/TrustedBy";
import { contentPageQuestion2 } from "../data/FrequentlyAskedQuestionsData";
import Header from "../components/common/Header";
import TopHeader from "./../components/common/TopHeader";
import { contentPageQuestion1 } from "./../data/FrequentlyAskedQuestionsData";
import { getClient } from "../Utils/utils";
import { codeClick } from "../Api/api";
import { useLocation } from "react-router";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Content() {
  let query = useQuery()
  const comment2 = {
    name: "Steveen",
    img: user1,
    text: "With FastlyNode scalable and customizable solutions, we've been able to streamline our processes and significantly enhance our operational efficiency. We wholeheartedly recommend FastlyNode to anyone seeking a reliable and powerful blockchain service provider.",
  };

  const priceSection = useRef(null);

  const scrollToPriceSection = () => {
    window.scrollTo({
      top: priceSection.current.offsetTop,
      behavior: "smooth",
    });
  };
  const click_create =async()=>{
    
    const code = query.get("discount_code")
    if (code) {
      const client = await getClient()
      let variables= {
        code
      }
      const response = await codeClick(client,variables)
      console.log("response",response);
    }
  }
  useEffect(()=>{
    click_create()
  },[])
  return (
    <>
      <TopHeader scrollToPriceSection={scrollToPriceSection} />
      <Header />
      <StarRating />
      <ConnectMultipleApplications
        scrollToPriceSection={scrollToPriceSection}
      />
      <TrustedBy />
      <PabblyConnect />
      <AutomatingTasks scrollToPriceSection={scrollToPriceSection} />
      <PabblyConnectWork scrollToPriceSection={scrollToPriceSection} />
      <AutomationsWithNoCode />
      <AutomatingAnything />
      <HighlyStarRatting />
      <BoostProductivity scrollToPriceSection={scrollToPriceSection} />

      <UnlikeCompetitors scrollToPriceSection={scrollToPriceSection} />
      <AutomationWorkflows />
      <CustomarComentContent bg={"bg-[#fff]"} comment={comment2} />
      <FeaturesYouNeed scrollToPriceSection={scrollToPriceSection} />

      <HelpYouMasterAutomation scrollToPriceSection={scrollToPriceSection} />
      <WorkBetterTogethe />
      <CustomarComentContent bg={"bg-[#F5F9FB]"} comment={comment2} />
      <Pricing priceSection={priceSection} />
     {/* <TotalFreeCost /> */}
      <DoubleGurantee />
      {/* <WhoAreTrustingUs scrollToPriceSection={scrollToPriceSection} /> */}
      <div className="ques-bg pt-[40px] md:pt-[80px] pb-[50px] md:pb-[100px]">
        <FrequentlyAskedQuestions
          data={contentPageQuestion1}
          data2={contentPageQuestion2}
        />
      </div>
      <AmazingTeam scrollToPriceSection={scrollToPriceSection} />
      <Footer />
    </>
  );
}

export default Content;
