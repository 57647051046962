import React from "react";

function AccountDelete() {
  const inputClass =
    "text-[#495057] block w-full shadow-a-8 py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da] rounded-[.25rem]";

  const labelClass = "text-[15px] font-medium mb-[5px] text-[#495057] block";

  const data = [
    "If you delete your account, all the data associated with the account will be deleted permanently.",
    "Data from all the applications i.e FastlyNode Subscription will be deleted.",
    "Once the data is deleted, it cannot be recovered.",
  ];

  return (
    <>
      <div className="px-[20px]  mb-[40px] mt-[20px]  md:px-[20px]">
        <div className="  rounded-[5px] shadow-a-13 bg-white">
          <div className="px-[25px] py-[25px] border-b border-[#eef7ff]">
            <p className="text-[18px] text-[#212529] font-semibold">
              Delete Account
            </p>
          </div>
          <div className="px-[25px] pt-[20px]">
            <div className="px-[25px] bg-[#fff3cd] rounded-[8px]   py-[25px]">
              <ul className="space-y-[10px] px-[10px]">
                {data.map((el, i) => (
                  <li
                    key={i}
                    className="list-disc text-[15px] text-[#856404] font-medium"
                  >
                    {el}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="px-[25px] py-[25px]">
            <div className="grid gap-x-[25px] gap-y-[20px] grid-cols-1 md:grid-cols-2">
              <div>
                <label className={labelClass} htmlFor="currentPass">
                  Enter your current password to verify that's you
                </label>
                <input
                  className={inputClass}
                  type="text"
                  placeholder="Password"
                  id="currentPass"
                />
              </div>
            </div>
            <div>
              <button className="bg-[#dc3545] rounded-[0.25rem] mt-[30px] border inline-block text-[15px] py-[0.375rem] px-[0.75rem] border-[#dc3545] text-white">
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountDelete;
