import PricingHeader from "./PricingHeader";
import { useEffect, useState } from "react";
import PricingFilter from "./PricingFilter";

import freePlanImg from "../../assests/node-plan.png";
import { optionData } from "./../../data/pricingData";
import PriceCard from "./PriceCard";
import giftIcon from "../../assests/gift-box.svg";
import { getClient } from "../../Utils/utils";
import { offres } from "../../Api/api";
import { useDispatch, useSelector } from "react-redux";

function Pricing({priceSection}) {
  const dispatch = useDispatch()
  const appProps = useSelector(state => state);
    const {offresData} = appProps
  const getOffres = async(query)=>{
    if(offresData) return null
    const client = await getClient()
    let variables= {
        query
    }
    const response = await offres(client,variables)
    console.log("response",response);
    if(response.success){
      if(query==="NODE"){
        dispatch({ type: "SET_NODE_OFFRE", value: response.data })
      }
      if(query==="BOT"){
        dispatch({ type: "SET_BOT_OFFRE", value: response.data })
      }
    }
  }
  
  useEffect(()=>{
    getOffres("NODE")
    getOffres("BOT")
  },[])
  return (
    <section className="pb-[40px] bg-[#F5F9FB] pt-[100px] md:pt-[50px] " ref={priceSection}>
      <PricingHeader />
      <PricingFilter />

      <div className="container max-w-[1200px] pt-[30px] mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-[40px] md:grid-cols-3 gap-[20px]">
          {offresData && offresData.map((item,index)=><PriceCard
            id={item.node.id}
            data={optionData}
            img={freePlanImg}
            plan={item.node.period==="TWOWEEK"?"2 WEEK":"1 "+item.node.period}
            free={false}
            task={100}
            price={item.node.price+ " BNB"}
            normallyPrice={item.node.price*2}
          />)}
          {/*
          <PriceCard
            data={optionData}
            img={freePlanImg}
            plan="1 WEEK"
            free={true}
            task={100}
            price="0.25 BNB"
            normallyPrice="0.4 BNB"
          />
          <PriceCard
            data={optionData}
            img={freePlanImg}
            task={"12,000"}
            plan="1 MONTH"
            price="0.45 BNB"
            normallyPrice="0.6 BNB"
          />
          <PriceCard
            data={optionData}
            img={freePlanImg}
            task={"24,000"}
            plan="6 MONTHS"
            price="2 BNB"
            normallyPrice="3 BNB"
          />
          <PriceCard
            plan="1 YEAR"
            data={optionData}
            img={freePlanImg}
            unlimited={true}
            task={"32,000"}
            price="4 BNB"
            normallyPrice="6 BNB"
          />*/}
        </div>

        <div>
          <div className="bg-[#222222] py-[15px] px-[30px] text-center rounded-[10px] mt-[45px]">
            <h2 className="text-[#fff] text-[18px]  md:text-[22px] font-extrabold leading-[1.3em] md:leading-[1.5em] ">
              <img src={giftIcon} className="w-[21px] inline" alt="" />{" "}
              Upgrade to <span className="text-[#FFEC2A]">Annual Subscription </span>{" "}
              and <span className="text-[#FFEC2A]">Unlock Exclusive Perks!</span>
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
