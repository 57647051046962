import React from "react";
import { Link } from "react-router-dom";
import LikeIcon from "../../assests/Like.svg";
import commisionImg from "../../assests/affiliate/Affiliate-Commission.webp";

function EarnUpCommission() {
  return (
    <section className="bg-[#F5F9FB] py-[60px] md:py-[100px] ">
      <div className="container max-w-[1110px] mx-auto">
        <div>
          <div className="flex flex-col gap-y-[30px] md:flex-row items-center gap-x-[45px]">
            <div className="w-full md:w-[40%]">
              <img className="md:w-full w-[80%]" src={commisionImg} alt="" />
            </div>
            <div className= "w-full md:w-[60%]">
              <div>
                <h3 className="text-[#222222] mb-[20px] md:mb-0 text-[22px] md:text-[36px] leading-[28px] md:leading-[1.7em] font-bold">
                  Earn up to <span className="text-[#1877F2]"> 30%</span>{" "}
                  Commission
                </h3>
                <p className="text-[16px] mb-[20px] font-medium leading-[1.6em] tracking-[0px] text-[#1D1B21]">
                Become a part of the FastlyNode family by joining our Affiliate Program and seize the opportunity to promote 
                our state-of-the-art private node services to your network. As a FastlyNode affiliate, you'll play a vital role in 
                expanding our reach and helping others discover the unparalleled benefits of our platform.
                In return, you'll earn attractive commissions for every successful referral, fostering a mutually beneficial relationship.
                </p>
                <p className="text-[16px] font-medium leading-[1.6em] tracking-[0px] text-[#1D1B21]">
                Join our Affiliate Program today and take the first step towards a rewarding partnership with FastlyNode, while contributing to the growth 
                and success of the blockchain community. 🎉
                </p>

                <div className="text-center md:text-left mt-[40px] md:mt-[20px]">
                  <Link to="/" class="btn-1">
                    <img src={LikeIcon} alt="" />
                    <span>BECOME AN AFFILIATE</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EarnUpCommission;
