import img from "../../assests/affiliate/Affiliate-header3.png";
import correctIcon from "../../assests/correct.svg";
import LikeIcon from "../../assests/Like.svg";
import { Link } from "react-router-dom";

function GrowTogether() {
  const data1 = [
    "30% Recurring Commission Forever",
    "Easy Payout System",
    "Intuitive Affiliate Dashboards",
  ];
  const data2 = [
    "Maximum Earnings",
    "Professional Support",
    "Anyone can be an affiliate",
  ];
  return (
    <section className="pb-[30px] md:pb-[100px]">
      <div className="container mx-auto max-w-[1100px]">
        <div>
          <div className="md:px-[180px]">
            <h1 className="text-[#030025] text-[30px] md:text-[56px] mb-[10px] md:mb-[20px] font-extrabold text-left md:text-center leading-[1.4em]">
            Join the FastlyNode Affiliate Program{" "}
            </h1>
            <p className="text-[#1D1B21] mb-[20px] text-left md:text-center text-[16px] md:text-[18px] font-semibold leading-[1.5em] md:leading-[1.7em] tracking-[0px]">
              {" "}
              <b>
                <u>Earn up to 30% commission</u>
              </b>{" "}
              on each sale you bring to us. The most straight and simple
              affiliate program ever that helps you to monetize your content.
            </p>
          </div>
          <div>
            <img className="w-full md:w-[61%] mx-auto" src={img} alt="" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[15px] mt-[30px] md:mt-[70px] gap-x-[30px] max-w-[660px] mx-auto">
            <div className="space-y-[15px]">
              {data1.map((el, i) => (
                <p key={i} className="text-[030025] font-bold text-[16px]">
                  <img
                    className="w-[15px] inline mr-[8px]"
                    src={correctIcon}
                    alt=""
                  />{" "}
                  {el}
                </p>
              ))}
            </div>
            <div className="space-y-[15px]">
              {data2.map((el, i) => (
                <p key={i} className="text-[030025] font-bold text-[16px]">
                  <img
                    className="w-[15px] inline mr-[8px]"
                    src={correctIcon}
                    alt=""
                  />{" "}
                  {el}
                </p>
              ))}
            </div>
          </div>

          <div className="text-center mt-[30px] md:mt-[45px]">
            <Link to="/" class="btn-1 ">
              <img src={LikeIcon} alt="" />
              <span>BECOME AN AFFILIATE</span>
            </Link>
          </div>
          <Link className="text-[14px] block text-center mt-[15px] tracking-[0.3px] font-semibold">
          Earn While You Help Businesses Grow
          </Link>
        </div>
      </div>
    </section>
  );
}

export default GrowTogether;
