import './App.css';
import './styles/main.css';
import 'tippy.js/dist/tippy.css';
import Content from './pages/Content';
import { createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { useDispatch } from 'react-redux'

import "react-toastify/dist/ReactToastify.css";

import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import Login from './pages/Login';
import Signup from './pages/Signup';
import AboutUs from './pages/AboutUs';
import Affiliates from './pages/Affiliates';
import TermsCondition from './pages/TermsCondition';
import ForgotPassword from './pages/ForgotPassword';
import PrivetRoute from './components/common/PrivetRoute';
import AllApps from './pages/AllApps';
import Profile from './pages/Profile';
import { useEffect } from 'react';
import ScrollToTop from './components/common/ScrollToTop';
import SniperBot from './pages/SniperBot';
import YourNode from './pages/YourNode';
import Fastlybot from './pages/Fastlybot';
import { getClient } from './Utils/utils';
import { getMe } from './Api/api';
import Offre from './pages/Offre';



function App() {
  const dispatch = useDispatch()
  const getMyData=async()=>{
    try {
      const token = localStorage.getItem("token");
      if(token){
          const newClient = await getClient(token)
          const myData = await getMe(newClient)
          console.log("myData",myData);
          if(myData.success){
              dispatch({ type: "USER_CREDENTIAL", value: token })
              dispatch({ type: "USER_INFORMATION", value: myData.data })              
          } 
      }
    } catch (error) {
        
    }
    
}
useEffect(()=>{
    getMyData()
},[])
  return (
    <>
      
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/sniper-bot" element={<SniperBot />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/affiliates" element={<Affiliates />} />
          <Route exact path='/offres/:offreId' element={<Offre/>} />
          <Route element={<PrivetRoute />}>
            <Route path="/all-apps" element={<AllApps />}></Route>
            <Route path="/profile" element={<Profile />}> </Route>
            <Route path="/your-nodes" element={<YourNode />}> </Route>
            <Route path="/fastlybot" element={<Fastlybot />}> </Route>

          </Route>
        </Routes>

        <ToastContainer />
    </>
  );
}

export default App;
