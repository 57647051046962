import icon from "../../assests/togeder.svg";
import pLogo from "../../assests/p-logo.webp";
import greenCorrect from "../../assests/greenCorrect.svg";
import smail from "../../assests/smail.webp";
import LikeIcon from "../../assests/Like.svg";
import img1 from "../../assests/WorkBetterTogethe-1.webp";

function WorkBetterTogethe() {
  const list = [
    "Experience faster transaction processing and reduced latency.",
    "Grow your projects without limitations, adapting to evolving needs.",
    "Focus on development without worrying about additional costs.",
  ];
  return (
    <section className="pt-[100px] relative z-50 pb-[200px] bg-white">
      <div className="container max-w-[1140px] mx-auto">
        <div>
          <h3 className="text-[#030025] mb-[10px] text-[22px]  md:text-center md:text-[36px] mt-[-50px] sm:mt-0 font-extrabold leading-[33px] md:leading-[1.4em]">
            {" "}
            <img src={icon} className="w-[22px] md:w-[36px] inline " alt="" />
            <u>The FastlyNode Advantage</u>: A Step Above the Competition
          </h3>
          <p className="text-[#1D1B21] mb-[20px] text-[15px] md:text-[18px] md:text-center leading-[1.5em] md:leading-[1.7em]">
          Unlock the Power of Innovative Blockchain Solutions with Unrivaled Performance, Security, and Flexibility
          </p>
        </div>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 p-[0px] md:p-0 place-items-center">
            <div>
              <img src={img1} alt="" />
            </div>
            <div className="">
              <div className="border-[4px] border-[#2785FF] pt-[20px] px-[10px] md:px-[30px] rounded-[10px] pb-[25px]">
                <div>
                  <div className="flex ">
                    <div className="w-[16%] md:w-[12%]">
                      <img src={pLogo} alt="" />
                    </div>
                    <div className="w-[84%] md:w-[88%] pl-[10px] md:pl-[16px]">
                      <h2 className="text-[#222222] text-[14px] font-bold uppercase">
                        {" "}
                        FastlyNode
                      </h2>
                      <h2 className="text-[#23B377] text-[16px] font-extrabold">
                        <span className="text-[26px] md:text-[36px]">
                          4 BNB{" "}
                        </span>{" "}
                        <u>Every Year</u>{" "}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <p className="text-[15px] mt-[10px] md:text-[16px] font-medium leading-[1.5em] md:leading-[1.7em] text-[#1D1B21]">
                    At FastlyNode, we understand the dynamic nature of blockchain projects and the importance of seamless communication 
                    between your applications and the blockchain network. To provide our users with an exceptional experience, FastlyNode 
                    offers unlimited access to requests, ensuring uninterrupted and smooth interaction with the blockchain, regardless of 
                    the scale or complexity of your projects. <br />
                      <br />
                      Unlimited requests come with several advantages:
                    </p>
                  </div>
                  <div className="space-y-[8px] mt-[25px] mb-[25px]">
                    {list.map((el, i) => (
                      <div key={i}>
                        <span>
                          <img
                            className="w-[14px] inline mr-[12px]"
                            src={greenCorrect}
                            alt=""
                          />
                        </span>
                        <span className="text-[#040125] text-[12px] md:text-[16px] font-medium">
                          {el}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-[15px] items-center">
                    <div className="">
                      <a
                        href="/signup"
                        className="bg-[#2785FF] rounded-[9px] py-[16px] uppercase px-[24px] text-[16px] text-white font-semibold inline-block leading-[1em] hover:scale-[1.1] inline-block duration-150"
                      >
                        <img
                          className="w-[16px] inline mr-[10px]"
                          src={LikeIcon}
                          alt=""
                        />
                        <span>buy now</span>
                      </a>
                    </div>
                    <div className="">
                      <img src={smail} alt="" className="inline" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkBetterTogethe;
