import React from "react";
import img from "../../assests/affiliate/Affiliate.webp";
import correctIcon from "../../assests/correct.svg";

function PabblyReferral() {
  const data = [
    "Request commission withdrawals once your balance reaches $250",
    "Receive your unique referral link and promotional materials",
    "Earn a 30% commission on every sale made through your referral link",
  ];
  return (
    <section className="bg-[#F5F9FB] py-[40px] md:py-[100px]">
      <div className="container mx-auto max-w-[1100px]">
        <div className="flex flex-col md:flex-row gap-[30px] items-center">
          <div className=" w-full md:w-[55%]">
            <div>
              <h2 className="text-[22px] md:text-[36px] mb-[20px] font-extrabold leading-[38px] md:leading-[1.4em] text-[#222222]">
              Join the FastlyNode Affiliate Program
              </h2>
              <div className="space-y-[15px]">
                {data.map((el, i) => (
                  <div
                    key={i}
                    className="text-[15px] text-[#302E35] font-medium flex gap-[11px]"
                  >
                    <img src={correctIcon} alt="" className="w-[15px]" />{" "}
                    <span>{el}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:w-[45%]">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PabblyReferral;
