import LikeIcon from "../../assests/Like.svg";
import { Link } from "react-router-dom";

function WaitingFor() {
  return (
    <section className="bg-[#F5F9FB] py-[60px]">
      <div className="container mx-auto max-w-[1100px]">
        <div>
          <h3 className="text-[#222222] text-[28px] md:text-[36px] leading-[38px] md:leading-[1.7em] font-bold text-center">
            What are you waiting for?
          </h3>
          <p className="text-[#1D1B21] text-[18px] font-medium leading-[1.7em] tracking-[0.3px] text-center">
            Start earning recurring commission for your referrals
          </p>

          <div className="text-center mt-[25px]">
            <Link to="/" class="btn-1">
              <img src={LikeIcon} alt="" />
              <span>BECOME AN AFFILIATE</span>
            </Link>
          </div>
          <Link className="text-[14px] block text-center mt-[15px] tracking-[0.3px] font-semibold">
            Already an Affiliate?{" "}
            <span className="text-[#006DF9]">Login here!</span>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default WaitingFor;
