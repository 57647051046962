import rocketIcon from "../../assests/roket.svg";
import img1 from "../../assests/NewIntegrationsLaunched.webp";

function NewIntegrationsLaunched() {
  const data = [
    {
      img: img1,
      subTitle1: "OPENAI",
      subTitle2: "FASTLYNODE CONNECT",
      title:
        "OpenAI : The OpenAI can be applied to virtually any task that involves understanding or generating natural language or code.",
    },
    {
      img: img1,
      subTitle1: "OPENAI",
      subTitle2: "FASTLYNODE CONNECT",
      title:
        "OpenAI : The OpenAI can be applied to virtually any task that involves understanding or generating natural language or code.",
    },
    {
      img: img1,
      subTitle1: "OPENAI",
      subTitle2: "FASTLYNODE CONNECT",
      title:
        "OpenAI : The OpenAI can be applied to virtually any task that involves understanding or generating natural language or code.",
    },
  ];
  return (
    <section className="bg-[#FFFFFF] pt-[90px] pb-[200px] md:pb-[220px] relative z-30">
      <div className="container mx-auto max-w-[1200px]">
        <div className="mb-[30px] md:mb-[50px]">
          <h3 className="text-[#030025] mb-[10px] md:mb-[20px] text-[22px] mt-[-45px] md:mt-0 md:text-[36px] font-extrabold leading-[1em] text-center">
            New Integrations <u>Launched</u>
            <img className="w-[36px] inline" src={rocketIcon} alt="" />
          </h3>
          <p className="text-[#1D1B21] text-[15px] md:text-[18px] leading-[1.5em] md:leading-[1.7em] text-center">
            We keep on launching new integration every week that will help you
            connecting different platforms.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-[20px]">
          {data.map((el, i) => (
            <div
              key={i}
              className="w-full sm:w-[33.33%] NewIntegrationsLaunched-card"
            >
              <div className="px-[20px] pt-[20px] pb-[25px] shadow-a-4 rounded-[8px]">
                <div className="bg-[#EFF4F8] px-[50px] py-[40px] rounded-[6px] mb-[20px]">
                  <img src={el.img} alt="" />
                </div>
                <div>
                  <p className="text-[#868686] text-[12px] mb-[20px] leading-[1.7em] font-semibold">
                    <u>{el.subTitle1} </u> + <u>{el.subTitle2}</u>
                  </p>
                  <h3 className="text-[#030025] text-[15px] md:text-[18px] font-bold leading-[1.5em]">
                    {el.title}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default NewIntegrationsLaunched;
