import React, { useState } from "react";

function PricingFilter() {
  const [active, setActive] = useState("Binance Smart Chain");

  const pricingFilter = [
    {
      text: "Solana",
    },
    {
      text: "Ethereum",
    },
    {
      text: "Avalanche",
    },
    {
      text: "Binance Smart Chain"
    },
  ];
  return (
    <>
      <div className="container mx-auto max-w-[1140px] mb-[10px] md:mb-[30px]">
        <div className="grid grid-cols-1 md:grid-cols-4 justify-center gap-3 md:gap-[20px] mt-[-65px] sm:mt-0 ">
          {pricingFilter.map((el, i) => (
            <button disabled={true}
              key={i}
              className={` text-[16px] pricing-filter text-center cursor-pointer font-bold capitalize l-s  border border-[#ECECEC] px-[15px] rounded-[50px] md:rounded-none py-[7px] w-full sm:min-w-[201px] leading-[1.8em] ${
                el.text === active
                  ? "filter-active-btn "
                  : "bg-[#fff]  text-[#000000]"
              }`}
              onClick={() => setActive(el.text)}
            >
              <span>{el.text}</span>
              {el?.saving && (
                <span
                  className={` ${
                    el.text === active ? "text-[#FFF717] " : "text-[#007BFF]"
                  }`}
                >
                  - Save {el.saving}%{" "}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}

export default PricingFilter;
