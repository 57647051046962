import { gql } from '@apollo/client';
export const GET_ME = gql`
  query Me{
    me {
      data{
        info{
          id
          email
          userName
          created_at
        }
        user_transactions{
          id
          userId
          sender
          receiver
          created_at
          amount
        }
        user_subscription{
          cursor
          node{
            id
            userId
            node
            sub_type
            sub_date
            created_at
            ws
            rpc
            status
          }
        }
        user_bot_subscription {
          cursor
          node {
            id
            userId
            sub_type
            sub_date
            created_at
            status
            licenseKey
          }
        }
      }
      success
      error {
        code
        message
      }
    }
  }
`;
export const GET_OFFRES = gql`
  query offres($id:String,$query:OffreType){
    offres(id:$id,query:$query){
      edges{
        cursor
        node{
          offre
          id
          pack
          period
          price
          advantage
        }
      }
      success
      error{
        code
        message
      }
    }
  }
`;