import React from "react";
import Header from "../components/common/Header";
import AffiliateStar from "./../components/affiliates/AffiliateStar";
import GrowTogether from "./../components/affiliates/GrowTogether";
import JoinCommunity from "./../components/affiliates/JoinCommunity";
import ShareGoodWord from "./../components/affiliates/ShareGoodWord";
import HowYouCanBePart from "./../components/affiliates/HowYouCanBePart";
import HowSucceedPabblyAffiliate from "./../components/affiliates/HowSucceedPabblyAffiliate";
import PabblyReferral from "./../components/affiliates/PabblyReferral";
import WhyJoin from "./../components/affiliates/WhyJoin";
import EarnUpCommission from "./../components/affiliates/EarnUpCommission";
import WaitingFor from "./../components/affiliates/WaitingFor";
import FrequentlyAskedQuestions from "./../components/common/FrequentlyAskedQuestions";
import { contentPageQuestion3 } from "../data/FrequentlyAskedQuestionsData";
import { contentPageQuestion4 } from "../data/FrequentlyAskedQuestionsData";
import Footer from './../components/common/Footer';

function Affiliates() {
  return (
    <>
      <Header />
      <AffiliateStar />
      <GrowTogether />
      {/* <JoinCommunity /> */}
      <ShareGoodWord />
     {/* <HowYouCanBePart /> */}
      <HowSucceedPabblyAffiliate />
      <PabblyReferral />
      <WhyJoin />
      <EarnUpCommission />
      <div className="bg-white">
        <div className="bg-white pt-[40px] md:pt-[80px] pb-[50px] md:pb-[100px]">
          <FrequentlyAskedQuestions
            data={contentPageQuestion3}
            data2={contentPageQuestion4}
          />
        </div>
      </div>
      <WaitingFor />
      <Footer />
    </>
  );
}

export default Affiliates;
