import { gql } from '@apollo/client';
export const CREATE_USER = gql`
  mutation userCreate($email: String!,$userName:String!,$password:String!){
    userCreate(email: $email,userName:$userName,password:$password) {
      success
      error {
        code
        message
      }
      token
    }
  }
`;

export const LOGIN_USER = gql`
  mutation userLogin($email: String!,$password:String!){
    userLogin(email: $email,password:$password) {
      success
      error {
        code
        message
      }
      token
    }
  }
`;
export const CREATE_SUBSCRIPTION = gql`
  mutation transactionCreate($input:TransactionInput!){
    transactionCreate(input:$input){
      success
      error{
        code
        message
      }
      data{
        info{
          id
          email
          userName
          created_at
        }
        user_transactions{
          id
          userId
          sender
          receiver
          created_at
          amount
        }
        user_subscription{
          cursor
          node{
            id
            userId
            node
            sub_type
            sub_date
            created_at
            ws
            rpc
            status
          }
        }
        user_bot_subscription {
          cursor
          node {
            id
            userId
            sub_type
            sub_date
            created_at
            status
            licenseKey
          }
        }
      }
    }
  }
`;
export const UPDATE_SUBSCRIPTION = gql`
  mutation subscriptionUpdate($input:TransactionInput!){
    subscriptionUpdate(input:$input){
      success
      error{
        code
        message
      }
      data{
        info{
          id
          email
          userName
          created_at
        }
        user_transactions{
          id
          userId
          sender
          receiver
          created_at
          amount
        }
        user_subscription{
          cursor
          node{
            id
            userId
            node
            sub_type
            sub_date
            created_at
            ws
            rpc
            status
          }
        }
        user_bot_subscription {
          cursor
          node {
            id
            userId
            sub_type
            sub_date
            created_at
            status
            licenseKey
          }
        }
      }
    }
  }
`;

export const BOT_SUBSCRIPTION = gql`
mutation transactionBotCreate($input:TransactionBotInput!){
  transactionBotCreate(input: $input) {
    success
    error {
      code
      message
    }
    data {
      info {
        id
        email
        userName
        created_at
      }
      user_transactions {
        id
        userId
        sender
        receiver
        created_at
        amount
      }
      user_subscription {
        cursor
        node {
          id
          userId
          node
          sub_type
          sub_date
          created_at
          ws
          rpc
          status
        }
      }
      user_bot_subscription {
        cursor
        node {
          id
          userId
          sub_type
          sub_date
          created_at
          status
          licenseKey
        }
      }
    }
  }
}
`;
export const BOT_SUBSCRIPTION_UPDATE = gql`
mutation subscriptionBotUpdate($input:TransactionBotInput!){
  subscriptionBotUpdate(input: $input) {
    success
    error {
      code
      message
    }
    data {
      info {
        id
        email
        userName
        created_at
      }
      user_transactions {
        id
        userId
        sender
        receiver
        created_at
        amount
      }
      user_subscription {
        cursor
        node {
          id
          userId
          node
          sub_type
          sub_date
          created_at
          ws
          rpc
          status
        }
      }
      user_bot_subscription {
        cursor
        node {
          id
          userId
          sub_type
          sub_date
          created_at
          status
          licenseKey
        }
      }
    }
  }
}
`;

export const CHECK_PRICE_RULE = gql`
  query priceRuleCheck($code:String!){
    priceRuleCheck(code:$code){
      priceRule{
        id
        code
        startsAt
        endsAt
        hasEnd
        createdAt
        value{
          type
          value
        }
      }
      success
      error{
        code
        message
      }
    }
  }
`;
export const CODE_CLICK = gql`
  mutation codeClick($code:String!){
    codeClick(code:$code){
      success
      error{
        code
        message
      }  
    }
  }
`;