import networkIcon from "../../assests/affiliate/network-50x50.png";
import editIcon from "../../assests/affiliate/edit-50x50.png";
import gearIcon from "../../assests/affiliate/gear-50x50.png";
import educationIcon from "../../assests/affiliate/education-50x50.png";
import adIcon from "../../assests/affiliate/online-advertising-50x50.png";
import targetIcon from "../../assests/affiliate/target-50x50.png";
import { Link } from "react-router-dom";
import LikeIcon from "../../assests/Like.svg";

function HowSucceedPabblyAffiliate() {
  const data = [
    {
      sr: 1,
      icon: networkIcon,
      title: "Attractive Commission Rates",
      text: "Earn a generous 30% commission on every sale, making your partnership with FastlyNode financially rewarding.",
    },
    {
      sr: 2,
      icon: editIcon,
      title: "High-Quality Service",
      text: "Promote a cutting-edge private node platform that delivers exceptional value to its users.",
    },
    {
      sr: 1,
      icon: gearIcon,
      title: "Continuous Support",
      text: "Receive ongoing support and resources from FastlyNode to ensure your success as an affiliate partner.",
    },
    {
      sr: 1,
      icon: educationIcon,
      title: "Easy Withdrawal Process",
      text: "Enjoy a seamless withdrawal process with a minimum threshold of $250, providing convenient access to your earnings.",
    },
    {
      sr: 1,
      icon: adIcon,
      title: "Expanding Market",
      text: "Capitalize on the growing demand for private node solutions, tapping into a rapidly expanding market.",
    },
    {
      sr: 1,
      icon: targetIcon,
      title: "Flexible Promotion",
      text: "Leverage your unique referral link to share FastlyNode's, reaching diverse audiences.",
    },
  ];
  return (
    <section className="md:pb-[100px] bg-white pb-[50px] pt-[40px] md:pt-[70px]">
      <div className="container mx-auto max-w-[1100px]">
        <div>
          <div className="">
            <h3 className="text-[#030025] mb-[20px] text-left md:text-center text-[22px] md:text-[36px] font-extrabold leading-[33px] md:leading-[1.4em]">
            Generous Commissions 👍
            </h3>
            <p className="text-[#1D1B21]  mb-[20px]  md:px-[150px] text-[15px] md:text-[18px] text-left md:text-center leading-[1.7em]">
            Your Success Matters to Us
            </p>
          </div>
          <div className="pb-[30px]">
            <div className="flex gap-y-[20px] flex-wrap flex-col md:flex-row ">
              {data.map((el, i) => (
                <div
                  key={i}
                  className="w-full md:w-[50%] pt-[20px] md:pr-[50px] pb-[0px] md:pb-[30px]  md:pl-[20px] FeaturesYouNeed-row"
                >
                  <div className="flex flex-col gap-y-[20px] md:flex-row">
                    <div className="w-[12%] mr-[18px] block">
                      <img src={el.icon} alt="" />
                    </div>
                    <div className="w-full md:w-[88%]">
                      <h3 className="text-[#111111] text-[16px] md:text-[18px] font-extrabold leading-[0.8em] mb-[8px] md:mb-[14px]">
                        {el.title}
                      </h3>
                      <p className="text-[#242729ED] text-[16px] leading-[1.6em]">
                        {el.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-[25px]">
            <Link to="/" class="btn-1">
              <img src={LikeIcon} alt="" />
              <span>BECOME AN AFFILIATE</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowSucceedPabblyAffiliate;
