import AllAppsCard from "../components/allApp/AllAppsCard";
import ExclusiveTimeOffer from "../components/allApp/ExclusiveTimeOffer";

function AllApps() {
  return (
    <>
      <div>
        <div className="shadow-a-12 pt-[35px] pb-[15px] bg-white px-[30px]">
          <h1 className="text-[26px] text-[#212529] font-semibold">All Nodes</h1>
          <p className="text-[14px] text-[#6c757d]">
            View and manage all your account associated information.
          </p>
        </div>

        <AllAppsCard />

        <div className="px-[30px]">
          <div className="pt-[30px] border-t-[3px] border-dashed border-[#e1e4e7]">
            {" "}
          </div>
        </div>

        <ExclusiveTimeOffer />
      </div>
    </>
  );
}

export default AllApps;
