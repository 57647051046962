import roketIcon from "../../assests/roket.svg";
import img1 from "../../assests/fastly-bot-sniper-bot.jpg";

function SniperAutomationsWithNoCode() {
  return (
    <>
    <section className="py-[80px] bg-[white]">
      <div className="container mx-auto max-w-[1300px]">
        <h3 className="text-[#030025] mb-[10px] md:mb-[20px] text-base text-[22px] md:text-[36px] mt-[-60px] sm:mt-0 font-extrabold leading-[34px] md:leading-[1em] text-left md:text-center">
        Get ready to take your trading game to the next level with our <u>Binance Smart Chain sniper bot!</u>{" "}
          <img className="w-[22px] md:w-[36px] inline" src={roketIcon} alt="" />
        </h3>
        <p className="text-[#1D1B21] text-[15px] md:text-[18px] leading-[1.5em] md:leading-[1.7em] text-left md:text-center mb-[20px] ">
        Our bot is the ultimate tool for traders who want to stay ahead of the competition and make the most out of every opportunity. 
        </p>
        <div className="flex justify-center">
          <img src={img1} alt="" />
        </div>
      </div>
    </section>
    </>
  )
}

export default SniperAutomationsWithNoCode