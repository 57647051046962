import { FaCommentDollar, FaUserCircle, FaArrowRight } from "react-icons/fa";

import avatarIng from "../../assests/allApp/avatar.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function HeaderMenuProfileOption({ handleMenuOps }) {
  const dispatch = useDispatch()
  const appProps = useSelector(state => state);
  const {token,userInfo} = appProps
  const navigate = useNavigate();

  const data = [
    {
      icon: <FaUserCircle />,
      text: "Profile",
      link: "/profile",
    },
    {
      icon: <FaCommentDollar />,
      text: " My Subscription",
      link: "/",
    },
  ];



  const handleLogOut = () => {
    handleMenuOps();
    localStorage.removeItem("token")
    dispatch({ type: "USER_CREDENTIAL", value: null })
    dispatch({ type: "USER_INFORMATION", value: null })
    dispatch({ type: "SET_APOLLO", value: null })
    navigate("/");
  };

  return (
    <>
      <div className="absolute z-[800] top-[100%] w-[275px] right-0 shadow-a-13 mt-[15px] bg-white rounded-[0.25rem]">
        <div className="p-[20px] flex gap-x-2 items-center">
          <div>
            <img src={avatarIng} className="w-[45px] rounded-full" alt="" />
          </div>
          <div>
            <p className="text-[15px] text-[#212529] font-semibold">
              {userInfo?.info?.userName}
            </p>
            <p className="text-[12px] text-[#6c757d]">{userInfo?.info?.email}</p>
          </div>
        </div>

        {data.map((el, i) => (
          <Link
            onClick={() => handleMenuOps()}
            className="px-[25px] py-[15px] border-t border-[#eef7ff] flex text-[#6c757d] text-[14px] items-center gap-x-[8px]"
            to={el.link}
          >
            <span className="text-[#6c757d]">{el.icon}</span>{" "}
            <span>{el.text}</span>
          </Link>
        ))}
        <div
          onClick={handleLogOut}
          className="px-[25px] py-[15px] border-t border-[#eef7ff] flex text-[#6c757d] text-[14px] items-center gap-x-[8px] cursor-pointer"
        >
          <span className="text-[#6c757d]"></span>
          <FaArrowRight className="text-[14px]" /> <span>Log Out</span>
        </div>
      </div>
    </>
  );
}

export default HeaderMenuProfileOption;
