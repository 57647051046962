import img1 from "../../assests/bum.svg";
import LikeIcon from "../../assests/Like.svg";
import correctIcon from "../../assests/correct.svg";
import img2 from "../../assests/BoostProductivity.webp";
import { Link } from "react-router-dom";

function BoostProductivity({scrollToPriceSection}) {
  const data = [
    {
      sr: "1",
      title: "Control",
      text: "Harness complete control over your node infrastructure. With FastlyNode, manage your resources and optimize your BSC applications according to your unique requirements.",
    },
    {
      sr: "2",
      title: "Efficiency",
      text: "Experience improved performance and reduced latency. FastlyNode outperform public networks in terms of speed and reliability, providing a seamless user experience for your BSC applications.",
    },
    {
      sr: "3",
      title: "Customization",
      text: "Configure your node to suit your needs. FastlyNode support custom settings and applications, allowing you to tailor your BSC experience for optimal results.",
    },
  ];

  const data2 = [
    "Trusted by 5,000+ Users",
    "Payments in BNB",
    "100MD Requests Validated",
  ];
  return (
    <section className="bg-[#F5F9FB] pt-[30px] pb-[90px]">
      <div className="container mx-auto max-w-[1120px]">
        <div className="">
          <div className="flex flex-col lg:flex-row lg:gap-x-[20px] ">
            <div className="w-full lg:w-[45%]">
              <div>
                <h3 className="text-[#040125] mb-[20px] text-[22px] md:text-[33px] font-extrabold leading-[33px] md:leading-[1.4em]">
                  <img className="w-[32px] inline" src={img1} alt="" />
                  Why Choose <u>FastlyNode?</u>
                </h3>
                <div className="space-y-[20px]  ">
                  {data.map((el, i) => (
                    <div key={i}>
                      <div className="flex gap-x-[5px]">
                        <div className="w-[7%]">
                          <div className="bg-[#007BFF] text-white w-[25px] h-[25px] flex justify-center items-center rounded-full">
                            {el.sr}
                          </div>
                        </div>
                        <div className="">
                          <h3 className="text-[#2B2A35] font-extrabold text-[18px] leading-[1em] l-s ml-4 md:ml-0 mb-[10px]">
                            {el.title}
                          </h3>
                          <p className="text-[#514F55] font-medium text-[16px] leading-[1.6em] mb-[10px]">
                            {el.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="  mt-[25px]">
                  <div onClick={()=>scrollToPriceSection()} class="btn-1 w-full cursor-pointer lg:w-auto justify-center">
                    <img src={LikeIcon} alt="" />
                    <span>START NOW!</span>
                  </div>
                </div>

                <div className="mt-[15px] mb-[40px]">
                  <div className="max-w-[670px] mx-auto ">
                    <div className="flex flex-row justify-center gap-x-5 gap-y-2 flex-wrap">
                      {data2.map((el, i) => (
                        <div key={i} className="flex gap-[6px] ">
                          <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                          <span className="text-[#54595F] font-semibold text-[12px]">
                            {el}
                          </span>{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full  lg:w-[55%]">
              <div>
                <img src={img2} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BoostProductivity;
