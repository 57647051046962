import React, {useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { offres } from "../Api/api";
import Bot from "../components/bot/Bot";
import FastlybotLeft from "../components/fastlybot/FastlybotLeft";
import { getClient } from "../Utils/utils";
import FastlybotRight from "./../components/fastlybot/FastlybotRight";
import PriceCard from "../components/connect/PricecardNodeDash";
import freePlanImg from "../assests/sniper-bot-plan.png";
import { botOptionData } from "../data/pricingData";
function Fastlybot() {
  const dispatch = useDispatch()
  const appProps = useSelector(state => state);
  const {userInfo,botsData} = appProps
    const [updating,setUpdating] = useState(false)
    const [selectedOffer,setSelectedOffer] = useState("")
    const getOffres = async(query)=>{
      if(!botsData){
        const client = await getClient()
        let variables= {
            query
        }
        const response = await offres(client,variables)
        console.log({response});
        if(response.success){          
            if(query==="BOT"){
              dispatch({ type: "SET_BOT_OFFRE", value: response.data })
            }
        }
      }
    }
  useEffect(()=>{
    console.log(userInfo.user_subscription.length );
    getOffres("BOT")
  },[])
  return (
    <>
      <div className="px-[20px] mb-[40px] mt-[20px]  md:px-[20px]">
        <div className="  rounded-[5px] shadow-a-13 bg-white">
          <div className="px-[25px] pt-[35px] pb-[70px] border-b border-[#eef7ff]">
            <div className="">
              <p className="text-[22px] md:text-[35px] uppercase text-left text-[#212529] font-bold">
                Fastlybot
              </p>
              <div className="flex align">
              <p className="text-[#212529] mb-[30px] text-[15px] font-medium text-left">
              Get the best prices for upcoming token launches
              </p>
              <p className="dashboard-subtitle"><span style={{color:"green",fontWeight:"bold"}}>{userInfo && userInfo.user_bot_subscription.length>0  && userInfo.user_bot_subscription[0].node.status?"Active":"Inactive"}</span></p>
              </div>
            </div>
            <hr className="bg-[#EFEFEF]" />
            {userInfo && (userInfo.user_bot_subscription.length>0 && userInfo.user_bot_subscription[0].node.status?
            <Bot />
              :
            <div className="container max-w-[1200px] pt-[30px] mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-[40px] md:grid-cols-3 gap-[20px]">
                {botsData && botsData.map((item,index)=><PriceCard
                      id={item.node.id}
                      data={botOptionData}
                      img={freePlanImg}
                      plan={"1 "+item.node.period}
                      free={false}
                      task={100}
                      price={item.node.price}
                      normallyPrice={item.node.price*2}
                />)}
              </div>
            </div>  )                    
            }
            {/*<div className="py-[20px]">
              <div className="flex gap-y-[20px] flex-col md:flex-row gap-x-[20px]">
                <FastlybotLeft />
                <FastlybotRight />
              </div>

             
            </div>*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default Fastlybot;
