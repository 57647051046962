import tkIcon from '../../assests/tk.svg';
import user from '../../assests/user-2.webp';
import star from '../../assests/5star.svg';
import gurantee from '../../assests/gurantee1.webp';
import line from '../../assests/line.webp';

function PricingHeader2() {
  return (
    <>
      <div className="container mx-auto pt-[20px] md:mb-[50px]">
        <div>
          <h3
            className="text-[#030025] px-[10px] max-w-[900px] p-5 sm:p-0 mx-auto md:mb-[20px]  text-start sm:text-center text-[22px] md:text-[36px] mt-[-85px] sm:mt-0
           font-extrabold leading-[33px] md:leading-[1.5em]"
          >
            Maximizing Profits with a Binance Smart Chain Sniper Bot:{' '}
            <img src={tkIcon} className="w-[36px] inline " alt="" />
            <u>Efficient Token Acquisition</u>
          </h3>
          <p className="text-[#1D1B21] px-[10px] md:px-[90px] md:mb-[20px] text-[15px] md:text-[18px] text-start md:text-center leading-[1.5em] md:leading-[1.7em]">
          A Sniper Bot for Binance Smart Chain (BSC) offers benefits like automated token purchases at favorable prices in the first block after liquidity addition. 
          It provides a competitive edge, reduces manual errors, and saves time for traders to focus on other investment aspects.
          </p>
        </div>
      </div>
      <div className="container mx-auto mb-[100px]">
        <div className="max-w-[680px] mx-auto relative">
          <div className="py-[15px] rounded-[10px] border border-[#FDB27D] border-dashed bg-[#FFFFE2] text-center md:px-10 p-[10px]">
            <h3 className="text-[#000000] text-[14px] md:text-[16px] font-semibold">
            Unlock Your Trading Potential: Secure Early Access to High-Performing Tokens with Our Sniper Bot 🎉{' '}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingHeader2;
