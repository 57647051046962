import React from "react";
import AccountManagementFilter from "./../components/profile/AccountManagementFilter";
import ProfileSettings from "./../components/profile/ProfileSettings";
import PasswordAndSecurity from "./../components/profile/PasswordAndSecurity";
import AccountDelete from "./../components/profile/AccountDelete";
import { useSearchParams } from "react-router-dom";

function Profile() {
  let [searchParams, setSearchParams] = useSearchParams();
  const routeName = searchParams.get("t");

  return (
    <>
      <div>
        <div className="shadow-a-12 pt-[35px] pb-[15px] bg-white px-[20px] md:px-[30px]">
          <h1 className="text-[26px] text-[#212529] font-semibold">
            Account Management
          </h1>
          <p className="text-[14px] text-[#6c757d]">
            View and manage all your account associated information.
          </p>
        </div>
        <AccountManagementFilter  routeName={routeName}/>
        {routeName == null && <ProfileSettings />}
        {routeName == "password-tab" && <PasswordAndSecurity />}
        {routeName == "account-delete-tab" && <AccountDelete />}
      </div>
    </>
  );
}

export default Profile;
