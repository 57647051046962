import React from "react";
import Header from "../components/common/Header";
import TopHeader from "../components/common/TopHeader";
import AboutBanner from "./../components/aboutUs/AboutBanner";
import OriginOfPabbly from "./../components/aboutUs/OriginOfPabbly";
import RightStepsAtRightTime from "./../components/aboutUs/RightStepsAtRightTime";
import SingleBrandMultipleServices from "./../components/aboutUs/SingleBrandMultipleServices";
import Footer from "./../components/common/Footer";

function AboutUs() {
  return (
    <>
      <TopHeader />
      <Header />
      <AboutBanner />
      <OriginOfPabbly />
      <RightStepsAtRightTime />
      <SingleBrandMultipleServices />
      <Footer />
    </>
  );
}

export default AboutUs;
