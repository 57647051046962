import React from 'react'
import img1 from "../../assests/moneyback-gurantee1.webp"
import signeture from "../../assests/signeture.webp"
import payment from "../../assests/Payment-Seal1.webp"

function DoubleGurantee() {
    return (
        <section className='bg-[white] pb-[30px] md:pb-[0px] '>
            <div className='container mx-auto max-w-[1200px]'>
                <div className='pt-[30px] bg-[white] mt-[-110px]  pb-[25px] px-[10px] md:px-[40px] shadow-a-2 rounded-[15px]  z-[100] relative'>
                    <div className='flex'>
                        <div className='w-[22%] md:w-[11%]'>
                            <img src={img1} alt="" />
                        </div>
                        <div className='w-[78%] md:w-[89%] pl-[10px] md:pl-[30px]'>
                            <p className='text-[12px] md:text-[16px] mb-[10px] text-[#6D6D6D] font-bold leading-[19px] md:leading-[1.4em] tracking-[0px]'>A Personal Message from the CEO of FastlyNode: Your Trusted Blockchain Partner</p>
                            <h3 className='text-[#1D1B21] mb-[20px] text-[15px] md:text-[18px] font-extrabold leading-[1.5em] tracking-[0px]'>I hope this message finds you well. As the CEO of FastlyNode, I want to personally welcome you and express 
                            my gratitude for considering our platform for your blockchain needs. I understand that selecting a reliable and secure blockchain service provider is crucial to the success of your business, and I am confident that FastlyNode 
                            is the perfect partner to help you achieve your goals.</h3>

                            <p className='text-[#1D1B21] text-[15px] md:text-[16px] leading-[1.5em] font-medium'>
                            In addition to our robust and scalable platform, we continuously invest in research and development to stay ahead of the curve, offering innovative solutions that cater to your unique requirements. Our commitment to transparency and collaboration has been the cornerstone of our success, and we promise to uphold these values as we help you navigate the complex blockchain landscape.
                                <br /> <br /> Rest assured, choosing FastlyNode means placing your trust in a company that is dedicated to your success. We will work tirelessly to ensure that you receive the best possible solutions, customized to your specific needs. Our team is always available to answer any questions, address concerns, and provide guidance as you embark on this exciting journey with us. <br /> <br /> It won’t be long until you realise that a huge portion of your time and energy is spent on tasks that can be easily automated.  <br /> <br />
                                Thank you once again for considering FastlyNode as your blockchain service provider. We look forward to forging a strong and lasting partnership and supporting you every step of the way.
                            </p>
                            <br /> <br />

                            <div>
                                <img className='w-[74%] md:w-[22%]' src={signeture} alt="" />
                            </div>
                            <h3 className='text-[#1D1B21] leading-[1.8em] text-[14px] md:text-[16px] font-bold'>
                                 <br /> <span className='font-medium'>
                                CEO, FastlyNode
                                </span>
                            </h3>

                        </div>
                    </div>
                </div>
                <div className='text-center px-[0px] md:px-[40px] mt-[80px]'>
                    <img className='w-[50%] md:w-[22%] mx-auto' src={payment} alt="" />
                </div>
            </div>


        </section>
    )
}

export default DoubleGurantee