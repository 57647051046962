import bulbIcon from "../../assests/bulb.svg";
import img1 from "../../assests/Automating Anything.webp";

function AutomatingAnything() {
  return (
    <section className="AutomatingAnything">
      <div className="AutomatingAnything-overlay"> </div>
      <div className="container mx-auto max-w-[1140px]">
        <div>
          <h3 className="text-[#ffffff] mb-[20px] text-[22px] md:text-[36px] font-extrabold leading-[34px] md:leading-[1em] text-left md:text-center">
            <img className="w-[22px] md:w-[36px] inline" src={bulbIcon} alt="" />
            Nodes come with HTTP and WSS URLs <u>ready to use instantly!</u>
          </h3>
          <p className="text-[#fff] text-[15px] md:text-[18px] leading-[1.4em] md:leading-[1.7em] text-left md:text-center mb-[35px]">
            FastlyNode 
          </p>
          <div>
            <div className="flex justify-center">
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AutomatingAnything;
