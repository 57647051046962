import graduated from "./../../assests/graduated.webp";
import LikeIcon from "../../assests/Like.svg";
import correctIcon from "../../assests/correct.svg";
import automotionBg1 from "../../assests/automotion.jpg";
import automotionBg2 from "../../assests/automotion.jpg";
import automotionBg3 from "../../assests/automotion.jpg";
import automotionBg4 from "../../assests/automotion.jpg";
import automotionBg5 from "../../assests/automotion.jpg";
import automotionBg6 from "../../assests/automotion.jpg";
import { Link } from "react-router-dom";
import HelpYouMasterAutomationSingleCard from './HelpYouMasterAutomationSingleCard';

function HelpYouMasterAutomation({scrollToPriceSection}) {
  const list = [
    {
      text: "",
      img: automotionBg1,
      videoLink: "1_KbE7qgBHc",
    },
    {
      text: "",
      img: automotionBg2,
      videoLink: "WYMoO521AMo",
    },
    {
      text: "",
      img: automotionBg3,
      videoLink: "CVYdgek2EG8",
    },
  ];

  const data = [
    "Trusted by 5,000+ Users",
    "Payments accepted in cryptocurrency",
    "100MD Requests Validated",
  ];
  return (
    <section className="bg-[#F5F9FB] pt-[60px] md:pt-[90px] pb-[100px]">
      <div className="container mx-auto max-w-[1170px]">
        <div>
          <h3 className="text-[#030025] px-[10px] md:px-[200px] mb-[20px] text-start md:text-center text-[22px] md:text-[36px] font-extrabold leading-[33px] md:leading-[1.5em]">
            {" "}
            <img
              src={graduated}
              className="w-[36px] hidden md:inline "
              alt=""
            />
            Discover How Our Private Node Services Transform the BSC Experience
          </h3>
          <p className="text-[#1D1B21] px-[10px] md:px-[160px] mb-[20px] text-[15px] md:text-[18px] text-start sm:text-center leading-[1.5em] md:leading-[1.7em]">
          Unveiling the Benefits of Our Private Node Services Through Real Experiences
          </p>
        </div>
        <div className="mt-[50px]">
          <div className="grid gap-x-[20px] gap-y-[50px] grid-cols-1  md:grid-cols-3">
            {list.map((el, i) => (
              <HelpYouMasterAutomationSingleCard data={el} key={i}/>
             
            ))}
          </div>
        </div>
        <div className="text-center mt-[60px]">
          <div onClick={() => scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
            <img src={LikeIcon} alt="" />
            <span>START NOW!</span>
          </div>
        </div>
        <div className="mt-[15px]">
          <div className="max-w-[670px] mx-auto ">
            <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-1">
              {data.map((el, i) => (
                <div key={i} className="flex gap-[6px] ">
                  <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                  <span className="text-[#54595F] font-semibold text-[12px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HelpYouMasterAutomation;
