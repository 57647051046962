import React, { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from "react-redux";
import { getClient, tokenizeDate } from "../Utils/utils";
import { offres, subscriptionUpdate } from "../Api/api";
import { optionData } from "../data/pricingData";
import freePlanImg from "../assests/node-plan.png";
import PriceCard from "../components/connect/PricecardNodeDash";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { CHAIN_ID, recipientAddr } from "../Config/Constants";
function YourNode() {
  const dispatch = useDispatch()
  const appProps = useSelector(state => state);
    const {userInfo,offresData} = appProps
    const [updating,setUpdating] = useState(false)
    const [selectedOffer,setSelectedOffer] = useState("")
    const handleClickToCopt = (data) => {
      copy(data)
    }
    const getOffres = async(query)=>{
      if(!offresData){
        const client = await getClient()
        let variables= {
            query
        }
        const response = await offres(client,variables)
        console.log({response});
        if(response.success){          
            if(query==="NODE"){
              setSelectedOffer(response.data[0].node.id)
              dispatch({ type: "SET_NODE_OFFRE", value: response.data })
            }
        }
      } else {
        if(query==="NODE"){
          setSelectedOffer(offresData[0].node.id)
        }
      }
    }
  useEffect(()=>{
    console.log(userInfo.user_subscription.length );
    getOffres("NODE")
  },[])
  const updatePlane=async(data)=>{
    setUpdating(true)
    console.log({data});
    console.log(data.id);
    const offerPos = offresData.findIndex((offre) => offre.node.id === selectedOffer);
    console.log("offerPos",offerPos);
    if(offerPos>-1){
        const selectedOffreData = offresData[offerPos]
        console.log("selectedOffreData",selectedOffreData);
        try {
            if (!window.ethereum)
                toast("your browser doesn't support web3, please install a secure wallet like metamask")
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            try {
                const network = await provider.getNetwork();
                if(network.chainId===CHAIN_ID){
                    let amount = selectedOffreData.node.price
                    const signer = provider.getSigner();
                    const senderAddress = await signer.getAddress()
                    ethers.utils.getAddress(recipientAddr);
                    
                    const tx = await signer.sendTransaction({
                        to: recipientAddr,
                        value: ethers.utils.parseEther(amount)
                    });
                    const receipt = await tx.wait();
                    if(receipt.status===1){
                        setUpdating(true)
                        const token = localStorage.getItem("token")
                        const client = await getClient(token)
                        const input = {
                            node:"BSC",
                            sub_type:selectedOffreData.node.period,
                            sender:senderAddress,
                            receiver:recipientAddr,
                            transactionHash:receipt.transactionHash,
                            id:data.id
                        }
                        const response = await subscriptionUpdate(client,{input})
                        dispatch({ type: "USER_INFORMATION", value: response.data })
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    } else {
                        toast(`transaction failed , ${receipt.transactionHash}`)
                    }
                } else {
                    toast("Please make sure you're in the bsc mainnet, and try again")
                }
            }catch(error){
                if(error.data){
                    if(error.data.message){
                        toast(error.data.message);
                    } else {
                      toast(error.message);
                    }
                } else {
                  toast(error.message);
                }
            }
        } catch (err) {
            if(err.data){
                if(err.data.message){
                  toast(err.data.message);
                } else {
                  toast(err.message);
                }
            } else {
              toast(err.message);
            }
            
        }
    }
    setUpdating(false)
  }
  return (
    <>
    <div className="px-[20px] mb-[40px] mt-[20px]  md:px-[20px]">
        <div className="  rounded-[5px] shadow-a-13 bg-white">
          <div className="px-[25px] pt-[35px] pb-[70px] border-b border-[#eef7ff]">
            <p className="text-[25px] text-center text-[#212529] font-bold">
              How to go live on FastlyNode
            </p>
            <p className="text-[#212529] text-[15px] font-medium text-center">
            High-performance Private Node Services for Binance Smart Chain
            </p>

            <div className="text-center mt-[20px]">
              {/*<div>
                <select className="text-[#1E1E1E] text-[15px] inline-block mx-auto cursor-pointer outline-none font-semibold bg-[#C4C4C4] px-[8px] py-[3px] rounded-[9px]">
                  {chainList.map((el, i) => (
                    <option
                      className="text-[#1E1E1E] font-semibold"
                      key={i}
                      value={el.name}
                    >
                      {el.name}
                    </option>
                  ))}
                </select>
                  </div>*/}
               
            </div>
            {userInfo.user_subscription.length >0?
              userInfo.user_subscription.map((item,i)=>
              <div className="space-y-[25px] mb-[50px]" key={i}>
                {userInfo.user_subscription.length>0 ?<p className="text-center text-[17px] mt-[20px] text-[#212529] ">
                Status: <span className="text-[#49864A]"> {item.node.status?"Active":"Inactive"}</span> valid
                until {tokenizeDate(new Date(item.node.sub_date))}
              </p>:
              <p className="text-center text-[17px] mt-[20px] text-[#212529] ">
              Status: <span className="text-[#49864A]">Inactive</span> 
            </p>}
                  <div >
                    <h4 className="text-[17px] mb-[15px] uppercase font-medium  text-[#212529]">
                      {"RPC provider"}
                    </h4>
                    <div className="bg-[#C4C4C4] w-[98%] flex gap-x-5 items-center justify-between py-[4px] px-[8px] rounded-[10px] text-[#212529">
                      <div className="w-[95%]">
                        <p className="text-ellipsis overflow-hidden">{item.node.rpc}</p>
                      </div>
                      <div className="w-[2%] text-center">
                        <FaCopy className="text-[#212529] text-[18px] cursor-pointer" onClick={() => handleClickToCopt(item.node.rpc)} />
                      </div>
                    </div>
                  </div>

                  <div >
                    <h4 className="text-[17px] mb-[15px] uppercase font-medium  text-[#212529]">
                      {"WSS provider"}
                    </h4>
                    <div className="bg-[#C4C4C4] w-[98%] flex gap-x-5 items-center justify-between py-[4px] px-[8px] rounded-[10px] text-[#212529">
                      <div className="w-[95%]">
                        <p className="text-ellipsis overflow-hidden">{item.node.ws}</p>
                      </div>
                      <div className="w-[2%] text-center">
                        <FaCopy className="text-[#212529] text-[18px] cursor-pointer" onClick={() => handleClickToCopt(item.node.ws)} />
                      </div>
                    </div>
                  </div>
                  <div className="flex center row">
                    <select
                      onChange={(e)=>setSelectedOffer(e.target.value)}
                      className="h-[45px] text-[#1E1E1E] mr-[20px] text-[15px] inline-block cursor-pointer outline-none font-semibold bg-[#C4C4C4] px-[8px] py-[3px] rounded-[9px]"
                    >{offresData && offresData.map((offre,i)=>
                      <option 
                        className="text-[#1E1E1E] font-semibold"
                        key={i} value={offre.node.id}  >
                        {offre.node.price} BNB/{offre.node.period}
                      </option>
                    )}
                    </select>
                    <button
                      className="flex center text-center px-[20px] bg-[#fc4b6c] h-[45px] p-[8px] border text-white border-[#fc4b6c] leading-[1.5] rounded-[0.26rem]"
                      disabled={updating}
                      onClick={()=>updatePlane(item.node)}
                    >
                      <img
                            src='../assets/img/setting.png'
                            width='30'
                            className="mr-[10px]"
                            alt='metamask icon'
                        />
                    {updating ? "Updating..." : `Update`}
                    </button>
                  
                  </div>
              </div>
              ):
              <div className="container max-w-[1200px] pt-[30px] mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-[40px] md:grid-cols-3 gap-[20px]">
                  {offresData && offresData.map((item,index)=><PriceCard
                        id={item.node.id}
                        data={optionData}
                        img={freePlanImg}
                        plan={"1 "+item.node.period}
                        free={false}
                        task={100}
                        price={item.node.price}
                        normallyPrice={item.node.price*2}
                  />)}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    
    </>
  )
}

export default YourNode