import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react"
import { getClient, tokenizeDate } from "../../Utils/utils";
import { offres } from "../../Api/api";

import appImg from "../../assests/allApp/connectbsc.png";
import appImg2 from "../../assests/allApp/connectbtc.png";
import appImg3 from "../../assests/allApp/connecteth.png";
import appImg4 from "../../assests/allApp/connectavax.png";
function AllAppsCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const appProps = useSelector(state => state);
  const {userInfo,offresData} = appProps
  const [selectedOffer,setSelectedOffer] = useState("")
  const getOffres = async(query="NODE")=>{
    if(!offresData){
      const client = await getClient()
      let variables= {
          query
      }
      const response = await offres(client,variables)
      console.log({response});
      if(response.success){          
          if(query==="NODE"){
            dispatch({ type: "SET_NODE_OFFRE", value: response.data })
          }
      }
    }    
  }
  useEffect(()=>{
    if(offresData && offresData.length>0){
      setSelectedOffer(offresData[0]?.node.id)
    }
  },[offresData])
  useEffect(()=>{
    getOffres("NODE")
  })
  const allAppsData = [
    {
      icon: appImg,
      link: "/",
      title: "Binance Smart Chain",
      text: "Click here for start subscription",
      disabled:false,
      hasOption:true,
    },
    {
      icon: appImg2,
      link: "/",
      title: "Bitcoin",
      text: "Available Coming Soon",
      disabled:true,
      hasOption:false,
    },
    {
      icon: appImg3,
      link: "/",
      title: "Ethereum",
      text: "Available Coming Soon",
      disabled:true,
      hasOption:false,
    },
    {
      icon: appImg4,
      link: "/",
      title: "Avalanche",
      text: "Available Coming Soon",
      disabled:true,
      hasOption:false,
    },
  ];


  return (
    <>
      <div className="px-[20px] md:px-[30px] py-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-[30px] gap-y-[30px] ">
          {allAppsData.map((el, i) => (
            <div key={i}>
              <div className="bg-white p-[30px] shadow-a-13 rounded-[5px]">
                <div>
                  <img className="w-[35px] mx-auto" src={el.icon} alt="" />
                </div>
                <h3 className="text-[18px] font-semibold text-[#212529] text-center py-[20px]">
                  {el.title}
                </h3>
                {/* <p className="text-[#6c757d] text-[14px] text-center">
                  {el.text}
                </p> */}
                <div className="text-center">
                  {el.hasOption ? 
                  <select
                    onChange={(e)=>setSelectedOffer(e.target.value)}
                    className="text-[#1E1E1E] text-[15px] inline-block mx-auto cursor-pointer outline-none font-semibold bg-[#C4C4C4] px-[8px] py-[3px] rounded-[9px]"
                  >{offresData && offresData.map((offre,i)=>
                    <option 
                      className="text-[#1E1E1E] font-semibold"
                      key={i} value={offre.node.id}  >
                      {offre.node.price} BNB/{offre.node.period}
                    </option>
                  )}
                  </select>:
                  <select className="text-[#1E1E1E] text-[15px] inline-block mx-auto cursor-pointer outline-none font-semibold bg-[#C4C4C4] px-[8px] py-[3px] rounded-[9px]">
                    <option className="text-[#1E1E1E] font-semibold">
                      {el.text}
                                </option>
                  </select>
                }
                </div>
                <div className="text-center mt-[24px]">
                  <button
                      onClick={()=>navigate(`/offres/${selectedOffer}`)}
                      className="ext-[#6c757d] border-[#dee2e6] border rounded-[0.25rem] py-[.375rem] px-[.75rem] text-[14px] hover:bg-[#007bff] hover:border-[#007bff] hover:text-white duration-300"
                      disabled={el.disabled}
                    >
                      Access Now
                    </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AllAppsCard;
