import React from "react";

function ContactForm() {
  const inputClass =
    "w-full px-[20px] py-[15px] border border-[#e8e8e8] outline-none rounded-[7px] mb-[20px]";
  return (
    <>
      <div className="w-full md:w-[62%]">
        <div className="bg-[#fff] px-[20px] md:px-[50px] py-[30px] shadow-a-2 rounded-[10px]">
          <p className="text-[#242323] text-[18px] font-bold text-center mb-[30px]">
            Write to us and we'll get back to you as soon as possible.
          </p>
          <div>
            <input className={inputClass} type="text" placeholder="Name" />
            <input className={inputClass} type="text" placeholder="Email" />
            <p className="text-[14px] text-[#282828] font-bold">
              Choose Your Request
            </p>
            <select className={inputClass}>
              <option value="">Select</option>
              <option value="saab">FastlyNode : Private Node</option>
              <option value="saab">FastlyNode : Sniper Bot</option>
              <option value="saab">FastlyNode : Others</option>
            </select>
            <p className="text-[14px] text-[#282828] font-bold">Query Type</p>
            <select className={inputClass}>
              <option value="">Select</option>
              <option value="saab">Presales Query</option>
              <option value="saab">Technical Query</option>
              <option value="saab">Others</option>
            </select>
            <textarea
              cols="10"
              placeholder="Message"
              rows="4"
              className={inputClass}
            ></textarea>

            <div>
              <button className="block bg-[#22b276] hover:bg-[#0c9059] duration-200 px-[30px] py-[15px] text-[#fff] text-[22px] leading-[38px] w-full rounded-[8px]">
                {" "}
                Submit Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
