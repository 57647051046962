import React from "react";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

function SingleFaq({ data }) {
  const [open, setOpen] = useState(false);

  const handleClick = (q) => {
    setOpen(!open);
  };
  return (
    <>
      <div className="border-[#DFE9FF] cursor-pointer rounded-[6px] overflow-hidden border">
        <div
          onClick={() => handleClick(data.question)}
          className="p-[20px] text-[#111F62] flex justify-between items-center gap-x-[15px] text-[16px] leading-[1.5em] font-bold"
        >
          <span className="w-[90%]">{data.question}</span>{" "}
          <span className="w-[10%]">
            <FaAngleDown className={ `duration-300 ${open? "rotate-180" :"rotate-0"}`} />
          </span>
        </div>
        <div
          className={`text-[16px] text-[#282828]  ${
            open ? "h-auto leading-[1.6em] pb-[20px] px-[20px]" : "h-0"
          }`}
        >
          {data.ans}
        </div>
      </div>
    </>
  );
}

export default SingleFaq;
