import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

import HeaderAuthPage from "./../components/common/HeaderAuthPage";
import img from "../assests/signup/signup-img (1).png";
import googleIcon from "../assests/signup/google.png";
import { baseApi } from "./../data/APIDATA";
import Header from "../components/common/Header";
import { userCreate } from "../Api/api";
import { getClient, validateEmail } from "../Utils/utils";

function Signup() {
  const inputClass =
    "border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] shadow-a-8 mb-[20px]";
  const errTextClass = "text-[11px] mt-[-13px] mb-[15px] text-[#D16969]";

  const {
    register, 
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  
  const onSubmit = async (data) => {
    console.log({data});
    const {email, password,userName} = data
    setLoading(true);
    try {
      if(email===""|| password==="" || userName===""){
        toast("All fields are required")
        return
      }
      if(!validateEmail(email)){
        toast("please insert a valid email")
        return
      }
      const client = await getClient()
      let variables= {
          userName,password,email
      }
      const response = await userCreate(client,variables)
      console.log("response",response);
      if(response.success){
          localStorage.setItem("token", response.data);
          setLoading(false)
          navigate("/login")
      } else {
          setLoading(false)
          toast(response.error.message)
      }
    } catch (e) {
      setLoading(false);
      toast(e?.response?.data?.error || "Something wrong!");
    }
  };

  const emailPartten =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

  return (
    <>
      {/* <HeaderAuthPage login={true} /> */}
      <Header />
      <section className="pb-[100px]">
        <div className="container mx-auto max-w-[1140px]">
          <div className="grid pt-[90px] md:grid-cols-2">
            <div className="hidden md:block">
              <h1 className="text-[#17212] mb-[10px] text-[33px] leading-[1.1] font-extrabold">
                Create FastlyNode Account
              </h1>
              <p className="text-[#4d4d4d] text-[21px] mb-[40px]">
                Sign up in seconds. No credit card required.
              </p>
              <div>
                <img className="max-w-[420px] w-full" src={img} alt="" />
              </div>
            </div>

            <div>
              <div className="bg-white px-[30px] md:px-[50px] rounded-[8px] shadow-a-9 pb-[40px] ml-auto max-w-[460px] mx-auto pt-[35px]">
                <h2 className="text-[#17212b] mb-[35px] text-[25px] text-center font-bold">
                  Create Account
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div>
                        <input
                          type="text"
                          placeholder="UserName"
                          className={inputClass}
                          {...register("userName", { required: true })}
                        />
                        {errors.userName?.type === "required" && (
                          <p className={errTextClass}>USer Name is required</p>
                        )}
                      </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Email"
                        className="border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] shadow-a-8 mb-[20px]"
                        {...register("email", {
                          required: true,
                          pattern: emailPartten,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <p className={errTextClass}>Email is required</p>
                      )}
                      {errors.email?.type === "pattern" && (
                        <p className={errTextClass}>Invalid Email</p>
                      )}
                    </div>
                    <div>
                      <input
                        type="password"
                        placeholder="Password"
                        className="border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] mb-[20px] shadow-a-8"
                        {...register("password", {
                          required: true,
                          minLength: 8,
                        })}
                      />
                      {errors.password?.type === "required" && (
                        <p className={errTextClass}>Password is required</p>
                      )}
                      {errors.password?.type === "minLength" && (
                        <p className={errTextClass}>
                          Password must be more the 7 digit
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        className="border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] mb-[20px] shadow-a-8"
                        {...register("cPassword", {
                          validate: (val) => {
                            if (watch("password") != val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      {errors.cPassword && (
                        <p className={errTextClass}>
                          {errors.cPassword.message}
                        </p>
                      )}
                    </div>
                    <p className="text-[#777777] mb-[20px] text-[14px] ">
                      Use 8 or more characters for password.
                    </p>
                    <button
                      type="submit"
                      disabled={loading}
                      className="block text-center mb-[20px] w-full bg-[#fc4b6c] h-[55px] p-[8px] border text-white border-[#fc4b6c] leading-[1.5] rounded-[0.26rem]"
                    >
                      {loading ? "Loading..." : "Create Account"}
                    </button>

                    <p className="text-[#626262] text-center text-[15px]">
                      Already have an Account?
                      <Link to="/login" className="text-[#007bff] ">
                        Sign in
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
