import React, { useEffect } from "react";
import ExclusiveOfferImg from "../../assests/allApp/robot.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../Utils/utils";
import { offres } from "../../Api/api";

function ExclusiveTimeOffer() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const appProps = useSelector(state => state);
  const {userInfo,botsData} = appProps
  const getOffres = async(query="BOT")=>{
    if(!botsData){
      const client = await getClient()
      let variables= {
          query
      }
      const response = await offres(client,variables)
      console.log({response});
      if(response.success){          
          if(query==="BOT"){
            dispatch({ type: "SET_BOT_OFFRE", value: response.data })
          }
      }
    }    
  }
  useEffect(()=>{
    getOffres("BOT")
  })
  const exclusiveOffer = {
    img: ExclusiveOfferImg,
    link: "/",
    title: "Fastly Bot Exclusive One Time Offer",
    text: "Pay Once & get Lifetime Access to Fastly Bot without any Recurring Fee!",
    planOption: "One Time Payment Plan",
    price: "1699",
  };

  const exclusiveOfferData = [1, 1];
  return (
    <>
      <div className="px-[20px] md:px-[30px] pb-[50px]">
        <div>
          <h2 className="text-[#212529] mb-[30px] text-[22px]  font-semibold">
            FastlyBOT - Exclusive Limited Time Offer
          </h2>
          <div className="space-y-[30px]">
            {botsData && botsData.map((el, i) => (
              <div
                className="flex md:flex-row gap-y-[10px] flex-col items-center py-[20px] bg-[#fff] px-[20px] mb-[30px] shadow-a-13 rounded-[5px]"
                key={i}
              >
                <div className="basis-12/12 md:basis-7/12">
                  <div className="flex">
                    <div>
                      <img
                        className="max-w-[80px]"
                        src={exclusiveOffer.img}
                        alt=""
                      />
                    </div>
                    <div className="pl-[15px]">
                      <div className="text-[#26933f] bg-[#d2f9db] p-[4px] rounded-[0.25rem] text-[75%] font-semibold inline-block ">
                        Limited Time Offer
                      </div>
                      <p className="text-[#212528] leading-[1.5] my-[4px] text-[20px]">
                        {el.node.pack}
                      </p>
                      {el.node.advantage.map((advantage)=><p className="text-[#6c757d] text-[14px]">
                        {advantage}
                      </p>)}
                    </div>
                  </div>
                </div>
                <div className="basis-12/12 md:basis-3/12 flex w-full justify-end md:block md:w-auto">
                  <div>
                    <h3 className="text-[#212528] text-right text-[26px] font-medium">
                      {el.node.price} BNB
                    </h3>
                    <p className="text-[#6c757d] text-right text-[14px]">
                      {el.node.period==="TWOWEEK"?`2 WEEK`:el.node.period}
                    </p>
                  </div>
                </div>
                <div className="basis-12/12 md:basis-2/12 flex w-full justify-end md:block md:w-auto">
                  <div className="text-right">
                    <Link
                      className="bg-[#007bff] border inline-block border-[#007bff] py-[.375rem] px-[.75rem] rounded-[0.25rem] text-white text-[15px]"
                      to={`/offres/${el.node.id}`}
                    >
                      View Offer
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExclusiveTimeOffer;
