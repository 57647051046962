import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";

import img from "../assests/signup/signup-img.png";
import Header from "../components/common/Header";
import { getClient } from "../Utils/utils";
import { getMe, userLogin } from "../Api/api";
import { useDispatch, useSelector } from "react-redux";
function Login() {
  const appProps = useSelector(state => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect")
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    const {email,password} = data
    setLoading(true);
    try {
      if(email===""|| password===""){
        toast("All fiels are required")
        return
      }
      setLoading(true)
      const client = await getClient()
      let variables= {
          password,email
      }
      const response = await userLogin(client,variables)
      if(response.success){
          localStorage.setItem("token", response.data);
          const newClient = await getClient(response.data)
          const myData = await getMe(newClient)
          if(myData.success){
              dispatch({ type: "USER_CREDENTIAL", value: response.data })
              dispatch({ type: "USER_INFORMATION", value: myData.data })
              dispatch({ type: "SET_APOLLO", value: newClient })
              setLoading(false)
          } else {
              setLoading(false)
              toast(response.error.message)
          }
          
      } else {
          setLoading(false)
          toast(response.error.message)
      }
      
    } catch (e) {
      setLoading(false);
      toast(e?.response?.data?.error || "Something wrong!");
    }
  };
  useEffect(()=>{
    if(appProps.token && appProps.userInfo){
      if(redirect){
        navigate(redirect)
      } else {
        navigate("/all-apps");
      }
    }
  },[appProps])

  const errTextClass = "text-[11px] mt-[-13px] mb-[15px] text-[#D16969]";

  const emailPartten =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  return (
    <>
      {/* <HeaderAuthPage /> */}
      <Header />
      <section className="pb-[100px]">
        <div className="container mx-auto max-w-[1140px]">
          <div className="grid pt-[90px] md:grid-cols-2">
            <div className="hidden md:block">
              <h1 className="text-[#17212] mb-[10px] text-[33px] leading-[1.1] font-extrabold">
                Login to FastlyNode
              </h1>
              <p className="text-[#4d4d4d] text-[21px] mb-[40px]">
                Login in seconds. No credit card required.
              </p>
              <div>
                <img className="max-w-[420px] w-full" src={img} alt="" />
              </div>
            </div>

            <div>
              <div className="bg-white px-[30px] md:px-[50px] rounded-[8px] shadow-a-9 pb-[40px] ml-auto sm:max-w-[460px] mx-auto pt-[35px]">
                <h2 className="text-[#17212b] mb-[35px] text-[25px] text-center font-bold">
                  Login
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div>
                      <input
                        type="text"
                        placeholder="Email"
                        className="border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] shadow-a-8 mb-[20px]"
                        {...register("email", {
                          required: true,
                          pattern: emailPartten,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <p className={errTextClass}>Email is required</p>
                      )}
                      {errors.email?.type === "pattern" && (
                        <p className={errTextClass}>Invalid Email</p>
                      )}
                    </div>
                    <div>
                      <input
                        type="password"
                        placeholder="Password"
                        className="border-[#c8c8c8] border text-[#777] rounded-[3px] block h-[45px] w-full outline-none py-[.375rem] px-[.75rem] mb-[20px] shadow-a-8"
                        {...register("password", {
                          required: true,
                          minLength: 8,
                        })}
                      />

                      {errors.password?.type === "required" && (
                        <p className={errTextClass}>Password is required</p>
                      )}
                      {errors.password?.type === "minLength" && (
                        <p className={errTextClass}>
                          Password must be more the 7 digit
                        </p>
                      )}
                    </div>

                    <button
                      className="block text-center mb-[20px] w-full bg-[#fc4b6c] h-[55px] p-[8px] border text-white border-[#fc4b6c] leading-[1.5] rounded-[0.26rem]"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Log in"}
                    </button>

                    <p className="text-[#626262] text-center text-[15px]">
                      Unable to Login?{" "}
                      <Link to="/forgotpassword" className="text-[#007bff] ">
                        Forgot Password{" "}
                      </Link>
                    </p>
                    <p className="text-[#626262] text-center text-[15px]">
                    Don't have an account?
                      <Link to="/signup" className="text-[#007bff] ">
                      Create
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
