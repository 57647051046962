
const initialState = { token:null,userInfo:null,apolloClient:null,offresData:null,
  botsData:null
}
function reducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_APOLLO':
        nextState = {
          ...state,
          apolloClient: action.value
        }
    return nextState || state
    case 'USER_INFORMATION':
        nextState = {
          ...state,
          userInfo: action.value
        }
    return nextState || state
    case 'USER_CREDENTIAL':
        nextState = {
          ...state,
          token: action.value
        }
    return nextState || state
    case 'SET_NODE_OFFRE':
        nextState = {
          ...state,
          offresData: action.value
        }
    return nextState || state
    case 'SET_BOT_OFFRE':
        nextState = {
          ...state,
          botsData: action.value
        }
    return nextState || state
    default:
      return state
  }
}
export default reducer
