import settingIcon from "../../assests/setting.svg";
import correctIcon from "../../assests/correct.svg";
import LikeIcon from "../../assests/Like.svg";
import step1img from "../../assests/step.webp";
import step2img from "../../assests/step2.webp";
import step3img from "../../assests/step3-3.png";

function SniperPabblyConnectWork({scrollToPriceSection}) {
    const data = [
        {
          sr: 1,
          text: "Sign Up and Register",
          img: step1img,
        },
        {
          sr: 2,
          text: "Choose Your Plan",
          img: step2img,
        },
        {
          sr: 3,
          text: "Access the FastlyBot Platform",
          img: step3img,
        },
      ];
    
      const data1 = [
        "Trusted by 5,000+ Users",
        "Payments in BNB",
        "+10K successful launch",
      ];
  return (
    <>
       <section className="bg-[#F5F9FB] pb-[80px] pt-[110px]">
      <div className="container mx-auto max-w-[1220px]">
        <div className="">
          <h3 className="text-[#030025] mb-[20px] text-[22px] mt-[-70px] sm:mt-0 md:text-[36px] font-extrabold leading-[30px] md:leading-[1em] text-left md:text-center">
            <img
              className="w-[22px] md:w-[36px] inline"
              src={settingIcon}
              alt=""
            />{" "}
            How FastlyBot works ?
          </h3>
          <p className="text-[#1D1B21] text-[15px]  md:text-[18px] mt-1 sm:mt-0 leading-[1.5em] md:leading-[1.7em] text-center">
            Set up your first workflow in just 3 easy steps. No installation
            required
          </p>
        </div>
        <div>
          <div className="flex flex-col md:flex-row ">
            {data.map((el, i) => (
              <div key={i}>
                <div className="hover:translate-y-[-10px] duration-300">
                  <img src={el.img} alt="" />
                </div>
                <h3 className="text-[#030025] mb-[5px] md:mb-[20px] mt-[10px] text-[16px] md:text-[20px] text-left md:text-center font-extrabold leading-[1.5em]">
                  Step {el.sr}
                </h3>
                <p className="text-[#1D1B21] md:px-[18px] text-[14px]  md:text-[16px] font-semibold leading-[1.4em] md:leading-[1.7em] text-left md:text-center ">
                  {el.text}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center mt-[35px]">
          <div onClick={()=>scrollToPriceSection()} class="btn-1 cursor-pointer w-full md:w-auto justify-center">
            <img src={LikeIcon} alt="" />
            <span>START NOW!</span>
          </div>
        </div>
        <div className="mt-[15px]">
          <div className="max-w-[670px] mx-auto ">
            <div className="flex flex-row justify-center gap-x-5 gap-y-2 flex-wrap">
              {data1.map((el, i) => (
                <div key={i} className="flex gap-[6px] ">
                  <img src={correctIcon} className="w-[13px]" alt="" />{" "}
                  <span className="text-[#54595F] font-semibold text-[12px]">
                    {el}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SniperPabblyConnectWork